import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import colors from "../../../../../config/colors";
import links from "../../../../../config/links";
import { interfaceRowRequestOfPatient } from "./interfaceRowRequestOfPatient";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import { Person2Rounded } from "@mui/icons-material";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";

function RowRequestOfPatient(componentsParams: interfaceRowRequestOfPatient) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const deleteApiRequestFromTomorBoard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.deleteRequestFromTomorBoard;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${componentsParams.idTomorBoard}/requests/${componentsParams.serverData.id}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(ShowAlert({ text: "درخواست از جلسه حذف شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                componentsParams.callbackRefresh();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%", background: colors.blue.darkbackground, borderRadius: "32px", border: "1px solid #10107e1f",
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
            padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
        }}>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                        , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                    }}>
                        {
                            componentsParams.serverData.doctor.profile_pic ?
                                <ImageSquare width={"4rem"} borderRadius="8px"
                                    border="1px solid #ccc"
                                    img={`${baseUrl}/` + componentsParams.serverData.doctor.profile_pic}
                                    isScaleable={{}}
                                />
                                :
                                <ImageSquare iconSvg={Person2Rounded}
                                    width={"4rem"}
                                    color={colors.panel.doctor.patientsList.selector.color}
                                    fontsizeIcon="2rem"
                                    borderRadius="8px" border="1px solid white" />
                        }

                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "start",
                            justifyContent: "space-between",
                            color: "white",
                        }}>
                            <p>پزشک:{componentsParams.serverData.doctor.first_name},{componentsParams.serverData.doctor.last_name}</p>
                            <p style={{ color: colors.yellowHeader }}>شماره تماس:{componentsParams.serverData.doctor.phone}</p>

                        </div>

                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                        , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                    }}>
                        {
                            componentsParams.serverData.patient.profile_pic ?
                                <ImageSquare width={"4rem"} borderRadius="8px"
                                    border="1px solid #ccc"
                                    isScaleable={{}}
                                    img={`${baseUrl}/` + componentsParams.serverData.patient.profile_pic}
                                />
                                :
                                <ImageSquare iconSvg={Person2Rounded}
                                    width={"4rem"}
                                    color={colors.panel.doctor.patientsList.selector.color}
                                    fontsizeIcon="2rem"
                                    borderRadius="8px" border="1px solid white" />
                        }

                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "start",
                            justifyContent: "space-between",
                            color: "white",
                        }}>
                            <p>بیمار:{componentsParams.serverData.patient.first_name},{componentsParams.serverData.patient.last_name}</p>
                            <p style={{ color: colors.yellowHeader }}>شماره تماس:{componentsParams.serverData.patient.phone}</p>

                        </div>

                    </div>
                </Grid>

                {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}:
                        {componentsParams.description}
                    </p>
                </Grid> */}
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            onclick={() => {
                                navigate(links.secretary.tomorboard.meeting.listMeetingForAddPateint, {
                                    state: {
                                        "serverData": componentsParams.serverData,
                                        "idTomorBoard": componentsParams.idTomorBoard,
                                        "nameTomorBoard": componentsParams.nameTomorBoard,
                                    }
                                })
                            }}
                            text={"اضافه کردن به جلسه"}
                        />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            text={"مشاهده تاریخچه"}
                            onclick={() => {
                                navigate(links.secretary.patient.persentsHistory + `/${componentsParams.serverData.patient.id}/`)
                            }} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonOrangeText}
                            text={"رد درخواست"}
                            onclick={deleteApiRequestFromTomorBoard} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonOrangeText}
                            text={"مشاهده پرونده"}
                            onclick={() => {
                                navigate(links.secretary.patient.persentsFile + `/${componentsParams.serverData.patient.id}/`)
                            }} />
                    </div>
                </Grid>
            </GridFromMUI>

        </div >
    )
}
export default RowRequestOfPatient;