import colors from "../../config/colors";
import { interfaceEmptyList } from "./interfaceEmptyList";

function EmptyList(componentsParams: interfaceEmptyList) {
    return (


        <div style={{
            width: "100%",
            border: "1px solid red", borderRadius: "8px",
            boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center",
            paddingInline: "3vw", paddingBlock: "3vh",
            marginTop: "20vh",

        }}>
            <h5 style={{ color: colors.yellowHeader }}>{componentsParams.text}</h5>
        </div>
    )
}
export default EmptyList;