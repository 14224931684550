import { Button, FormControl, Grid, IconButton, SelectChangeEvent, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { InterfaceDoctorAccountStep1 } from "./interfaceDoctorAccountStep1";
import { ConfigComps } from "../../../config/ConfigCOMP";
import EditText, { DivListAllItem, PText } from "../../../components/editText/EditText";
import colors from "../../../config/colors";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ReactNode, useEffect, useState } from "react";
import { EnumGender } from "../../../utils/Gender";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, baseUrl, urlsList } from "../../../api/UrlsList";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceServerGetInformation } from "../../patient/account/interfacePatientAccountPage";
import { interfaceServerGetInformationDocotor } from "./interfaceDoctorAccount";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";
import { EnumYesOrNo } from "../../../utils/YesOrNo";
import FilesDragAndDrop from "../../../components/FileDragAndDrop/FileDragAndDrop";
import { styled as styledMUI } from "@mui/material/styles";
import { AddRounded, CheckRounded, FileUploadRounded, PlusOneRounded } from "@mui/icons-material";
import { ImageSquare } from "../../../components/imageSquare/ImageSquare";
export const IconButtonUpload = styledMUI(IconButton)(({ theme }) => ({
    backgroundColor: "rgba(25, 118, 210, 0.12)",
    color: "#1976D2",
    ":hover": {
        backgroundColor: "rgba(25, 118, 210, 0.12)",
    },
    "& .MuiIconButton": {
        backgroundColor: "rgba(25, 118, 210, 0.12)",
    }


}));


function DoctorAccountStep2(paramsPage: InterfaceDoctorAccountStep1) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAccountEmail, setAccountEmail] = useState<string | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.email);
    const [getAccountScore, setAccountScore] = useState<string | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.medical_score);
    const [getAccountDetails, setAccountDetails] = useState<string | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.details);
    const [getAccountOfficePostalCode, setAccountOfficePostalCode] = useState<string | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.office_postal_code);
    const [getAccountIsMilitaryMember, setAccountIsMilitaryMember] = useState<boolean | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.is_military_member);
    const [getAccountHasPermanentLicense, setAccountHasPermanentLicense] = useState<boolean | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.has_permanent_license);
    const [getAccountFacultyHistoryYears, setAccountFacultyHistoryYears] = useState<string | undefined>(String(paramsPage.ServerGetInfoAtFirstTime?.data.faculty_history_years));
    const [getAccountAddressOfOffice, setAccountAddressOfOffice] = useState<string | undefined>(paramsPage.ServerGetInfoAtFirstTime?.data.office_address);
    const [getAccountMedicalLicense, setAccountMedicalLicense] = useState<File>();
    const [getAccountMedicalLicenseURL, setAccountMedicalLicenseURL] = useState<string>();
    const [getAccountIDCardPic, setAccountIDCardPic] = useState<File>();
    const [getAccountIDCardPicURL, setAccountIDCardPicURL] = useState<string>();
    const [getAccountProfilePic, setAccountProfilePic] = useState<File>();
    const [getAccountProfilePicURL, setAccountProfilePicURL] = useState<string>();
    const [getAccountBirthCertificate, setAccountBirthCertificate] = useState<File>();
    const [getAccountBirthCertificateURL, setAccountBirthCertificateURL] = useState<string>();
    const handleUpdateInformationOfAccount = async () => {
        const formData = new FormData();
        // if (getAccountBirthCertificate == undefined || getAccountIDCard == undefined || getAccountMedicalLicense == undefined) {
        //     dispatch(ShowAlert({ text: "لطفا فایل‌های خود را آپلود کنید", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        //     return;
        // }
        dispatch(isVisibilityProgressBar(true));
        if (getAccountProfilePic != undefined && typeof (getAccountProfilePic) !== "string") {
            formData.append("profile_pic", getAccountProfilePic);
        }
        if (getAccountEmail ?? "".length > 0) {
            formData.append("email", String(getAccountEmail));
        }
        formData.append("postal_code", String(getAccountOfficePostalCode));
        if (getAccountAddressOfOffice) {
            formData.append("office_address", getAccountAddressOfOffice);
        }
        if (getAccountDetails) {
            formData.append("details", getAccountDetails);
        }
        if (getAccountScore != undefined) {
            formData.append("medical_score", String(getAccountScore));
        }
        else {

        }
        formData.append("is_military_member", getAccountIsMilitaryMember ? "True" : "False");
        if (getAccountFacultyHistoryYears != undefined) {
            formData.append("faculty_history_years", String(getAccountFacultyHistoryYears));
        }
        formData.append("has_permanent_license", getAccountHasPermanentLicense ? "True" : "False");
        if (getAccountBirthCertificate && typeof (getAccountBirthCertificate) !== "string") {
            formData.append("birth_certificate", getAccountBirthCertificate);
        }
        if (getAccountIDCardPic && typeof (getAccountIDCardPic) !== "string") {
            formData.append("id_card", getAccountIDCardPic);
        }
        if (getAccountMedicalLicense && typeof (getAccountMedicalLicense) !== "string") {
            formData.append("medical_license", getAccountMedicalLicense);
        }
        formData.append("status", "2");

        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.doctor.account.modifyAccount.url, mtheme),
            urlsList.panel.doctor.account.modifyAccount.method, urlsList.panel.doctor.account.modifyAccount.isTokenNecessary, formData, profileInStore, true)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                paramsPage.callbackGetInfoAgain();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    function handleChangeDropDownIsMilitaryMember(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        if (String(event.target.value).includes("0")) {
            setAccountIsMilitaryMember(false)
        }
        else {
            setAccountIsMilitaryMember(true)
        }
    }
    function handleChangeDropDownHasPermanentLicense(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        if (String(event.target.value).includes("0")) {
            setAccountHasPermanentLicense(false)
        }
        else {
            setAccountHasPermanentLicense(true)
        }
    }
    useEffect(() => {
        setAccountEmail(paramsPage.ServerGetInfoAtFirstTime?.data.email);
        setAccountScore(paramsPage.ServerGetInfoAtFirstTime?.data.medical_score);
        setAccountDetails(paramsPage.ServerGetInfoAtFirstTime?.data.details);
        setAccountOfficePostalCode(paramsPage.ServerGetInfoAtFirstTime?.data.office_postal_code);
        setAccountIsMilitaryMember(paramsPage.ServerGetInfoAtFirstTime?.data.is_military_member);
        setAccountHasPermanentLicense(paramsPage.ServerGetInfoAtFirstTime?.data.has_permanent_license);
        setAccountFacultyHistoryYears(String(paramsPage.ServerGetInfoAtFirstTime?.data.faculty_history_years));
        setAccountAddressOfOffice(paramsPage.ServerGetInfoAtFirstTime?.data.office_address);
        setAccountMedicalLicenseURL(paramsPage.ServerGetInfoAtFirstTime?.data.medical_license ? `${baseUrl}/` + paramsPage.ServerGetInfoAtFirstTime?.data.medical_license : undefined);
        setAccountIDCardPicURL(paramsPage.ServerGetInfoAtFirstTime?.data.id_card ? `${baseUrl}/` + paramsPage.ServerGetInfoAtFirstTime?.data.id_card : undefined);
        setAccountProfilePicURL(paramsPage.ServerGetInfoAtFirstTime?.data.profile_pic ? `${baseUrl}/` + paramsPage.ServerGetInfoAtFirstTime?.data.profile_pic : undefined);
        setAccountBirthCertificateURL(paramsPage.ServerGetInfoAtFirstTime?.data.birth_certificate ? `${baseUrl}/` + paramsPage.ServerGetInfoAtFirstTime?.data.birth_certificate : undefined);
    }, [paramsPage.ServerGetInfoAtFirstTime])
    useEffect(() => {
        if (getAccountIDCardPic) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setAccountIDCardPicURL(event.target.result as string);
            };

            reader.readAsDataURL(getAccountIDCardPic);
        }
    }, [getAccountIDCardPic]);
    useEffect(() => {
        if (getAccountProfilePic) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setAccountProfilePicURL(event.target.result as string);
            };

            reader.readAsDataURL(getAccountProfilePic);
        }
    }, [getAccountProfilePic]);
    useEffect(() => {
        if (getAccountBirthCertificate) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setAccountBirthCertificateURL(event.target.result as string);
            };

            reader.readAsDataURL(getAccountBirthCertificate);
        }
    }, [getAccountBirthCertificate]);
    useEffect(() => {
        if (getAccountMedicalLicense) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setAccountMedicalLicenseURL(event.target.result as string);
            };

            reader.readAsDataURL(getAccountMedicalLicense);
        }
    }, [getAccountMedicalLicense]);

    return (
        <div style={{
            display: paramsPage.isDisplay ? "flex" : "none",
            width: "100%", boxSizing: "border-box",
            marginTop: "calc(1vh + 12px)",
            maxHeight: "100%",
            overflowY: "auto",

        }}>


            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <h6 style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.doctor.account.step2.header}
                    </h6>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step2.doctorsScore}
                        onChangeValue={setAccountScore}
                        value={getAccountScore}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        type="number"
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step2.email}
                        onChangeValue={setAccountEmail}
                        value={getAccountEmail}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        type="email"
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step2.faculty_history_years}
                        onChangeValue={setAccountFacultyHistoryYears}
                        value={getAccountFacultyHistoryYears}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        type="number"
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step2.officePostalCode}
                        onChangeValue={setAccountOfficePostalCode}
                        value={getAccountOfficePostalCode}

                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start",
                        justifyContent: "space-between", width: "100%",
                    }}>
                        <DivListAllItem>
                            <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"آیا عضو نیروهای مسلح هستید"}</PText>
                        </DivListAllItem>


                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: "white",
                                width: "100%"
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.doctor.account.step2.is_military_member}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                                    borderRadius: "555px",
                                    boxSizing: "border-box",

                                }}
                                value={(getAccountIsMilitaryMember == true) ? "1" : (getAccountIsMilitaryMember == undefined) ? `${getAccountIsMilitaryMember}` : "0"}
                                label={confComps.panel.patient.doctorsList.category}
                                onChange={handleChangeDropDownIsMilitaryMember}
                            >
                                {["خیر", "بله"].map((value, index, array) => {
                                    return (
                                        <MenuItemFromMUI value={index}
                                            key={index}>{value}</MenuItemFromMUI>
                                    )
                                })}
                            </SelectFromMUI>
                        </FormControl>
                    </div>

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start",
                        justifyContent: "space-between", width: "100%",
                    }}>
                        <DivListAllItem>
                            <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"پرونده پزشکی دائم"}</PText>
                        </DivListAllItem>


                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: "white",
                                width: "100%"
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.doctor.account.step2.HasPermanentLicense}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    boxSizing: "border-box",
                                    color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                                    borderRadius: "555px",

                                }}
                                value={(getAccountHasPermanentLicense == true) ? "1" : (getAccountHasPermanentLicense == undefined) ? `${getAccountHasPermanentLicense}` : "0"}
                                label={confComps.panel.patient.doctorsList.category}
                                onChange={handleChangeDropDownHasPermanentLicense}
                            >
                                {["خیر", "بله"].map((value, index, array) => {
                                    return (
                                        <MenuItemFromMUI value={index}
                                            key={index}>{value}</MenuItemFromMUI>
                                    )
                                })}
                            </SelectFromMUI>
                        </FormControl>
                    </div>

                </Grid>


                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <EditText
                        title={confComps.panel.doctor.account.step2.addressOfOffice}
                        onChangeValue={setAccountAddressOfOffice}
                        value={getAccountAddressOfOffice}
                        styledTextField={{
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            border: "1px solid #fff",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: "calc(2vh + 12px)", width: "100%" }}>
                        <DivListAllItem>
                            <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"آپلود مدارک"}</PText>
                        </DivListAllItem>
                        <div style={{
                            display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "start", justifyContent: "start", gap: "calc(2vh + 12px)", width: "100%", border: "1px solid #fff",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                            borderRadius: "1vw", padding: "2vh 2vw",
                            boxSizing: "border-box",
                        }}>
                            {/* profile */}
                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            setAccountProfilePic(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={getAccountProfilePicURL ? undefined : AddRounded} color="white"

                                            img={getAccountProfilePicURL}
                                            background={getAccountProfilePicURL ? undefined : colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />
                                        <p>{confComps.panel.doctor.account.step2.files.profile_pic}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>
                            {/* birth certification */}
                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            setAccountBirthCertificate(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={getAccountBirthCertificateURL ? undefined : AddRounded} color="white"

                                            img={getAccountBirthCertificateURL}
                                            background={getAccountBirthCertificateURL ? undefined : colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />
                                        <p>{confComps.panel.doctor.account.step2.files.birth_certificate}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>

                            {/* idcard */}
                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            setAccountIDCardPic(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={getAccountIDCardPicURL ? undefined : AddRounded} color="white"
                                            img={getAccountIDCardPicURL}
                                            background={getAccountIDCardPicURL ? undefined : colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />
                                        <p>{confComps.panel.doctor.account.step2.files.id_card}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>
                            {/* medical license */}
                            <div>

                                <FilesDragAndDrop
                                    onUpload={(files) => {
                                        console.log(files)
                                        files.forEach((value) => {
                                            setAccountMedicalLicense(value);
                                        })
                                    }}
                                    count={1}
                                    formats={['jpg', 'png']}
                                    containerStyles={{
                                        borderRadius: "4px",
                                        boxSizing: "border-box",

                                    }}
                                    openDialogOnClick
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        gap: "calc(1vh + 12px)",
                                        alignItems: "center",
                                        padding: "8px"
                                    }}>
                                        <ImageSquare iconSvg={getAccountMedicalLicenseURL ? undefined : AddRounded} color="white"
                                            img={getAccountMedicalLicenseURL}
                                            background={getAccountMedicalLicenseURL ? undefined : colors.blue.blackBackground}
                                            width={"62px"}
                                            borderRadius="800px"
                                            border="1px solid white" />
                                        <p>{confComps.panel.doctor.account.step2.files.medical_license}</p>

                                    </div>
                                </FilesDragAndDrop>
                            </div>

                        </div>
                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                        <ButtonEffectShadow text={confComps.panel.doctor.account.step1.buttonNext} onclick={handleUpdateInformationOfAccount} />
                    </div>
                </Grid>
            </GridFromMUI>
        </div>
    )
}
export default DoctorAccountStep2;