import { Button, FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import links from "../../../config/links";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { ConfigComps } from "../../../config/ConfigCOMP";
import colors from "../../../config/colors";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";
import PatientRow from "../../doctor/patients/patientsList/PatientRow";
import { interfaceGetAllMyRequests, interfaceMyRequest, interfaceMyRequestPage } from "./interfaceMyRequestPage";
import MyRequestRow from "./MyRequestRow";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, ReactNode } from "react";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { EnumStatusRequest } from "../../../utils/StatusRequest";
import EditText from "../../../components/editText/EditText";
import EmptyList from "../../../components/emptyList/EmptyList";


function MyRequestPage(pageParams: interfaceMyRequestPage) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getDoctorList, setDoctorList] = useState<interfaceGetAllMyRequests>();
    const [getFilterByStatus, setFilterByStatus] = useState<EnumStatusRequest>(EnumStatusRequest.all);
    const [getSearchStr, setSearchStr] = useState<string>("");
    const getDoctorListFromServer = async (showNotification: boolean) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patient.doctorList.getMyRequest;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetAllMyRequests;

                setDoctorList(t)
                if (showNotification) {
                    dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getDoctorListFromServer(true);
    }, []);

    function handleChangeDropDownSelectedStatus(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        setFilterByStatus(event.target.value as EnumStatusRequest)
    }

    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: pageParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {confComps.panel.patient.myRequest.header}
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: pageParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="stretch"
                    columnSpacing={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 8 }}>
                    {getDoctorList?.data.data
                        // .filter(doctor => getFilterByStatus == EnumStatusRequest.all ? true : (doctor.status == getFilterByStatus))
                        .filter(doctor => `${doctor.doctor.first_name}${doctor.doctor.last_name}${doctor.status}${doctor.doctor.landline}`.includes(getSearchStr))
                        .map((Doctor, index, array) => {
                            return (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <MyRequestRow
                                        img={Doctor.doctor.profile_pic ? (baseUrl + `/` + Doctor.doctor.profile_pic) : undefined}
                                        name={Doctor.doctor.first_name}
                                        family={Doctor.doctor.last_name}
                                        typeOfDisplay={pageParams.typeOfDisplay}
                                        idDoctor={Doctor.doctor.id}
                                        idRequest={Doctor.id}
                                        department={Doctor.doctor.field}
                                        phone={Doctor.doctor.landline}
                                        address={Doctor.doctor.address}
                                        status={Doctor.status}
                                        callbackForReload={() => {
                                            getDoctorListFromServer(false)
                                        }} />
                                </Grid>
                            )
                        })
                    }

                </GridFromMUI>
                {
                    getDoctorList?.data.data
                        .filter(doctor => `${doctor.doctor.first_name}${doctor.doctor.last_name}${doctor.status}${doctor.doctor.landline}`.includes(getSearchStr))
                        .length == 0 ?
                        <EmptyList text={"هیچ درخواستی یافت نشد."} /> : undefined

                }
            </div>
        </div>
    )
}
export default MyRequestPage;