import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import links from "../config/links";
import colors from "../config/colors";
import ButtonEffectShadow from "../components/buttonEffectShadow/ButtonEffectShadow";
import { useState } from "react";
import { CardFromMUI } from "../components/MUIConfig/CardFromMUI";

function HomePage() {
    const [getStep, setStep] = useState<number>(0);
    const maxStep = 1;
    return (

        <div style={{
            width: "100vw", height: "100vh"
            , background: colors.blue.light, display: "flex",
            flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "4vh"
        }}>
            <CardFromMUI style={{
                width: "100%", maxWidth: "400px",
                background: colors.blue.darkbackground,
                padding: "24px",
                boxSizing: "border-box",
            }}>

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "space-between",
                    width: "100%"
                }}>
                    <h5 style={{ color: "white", marginBlock: "24px" }}>{"توضیحات سامانه"}</h5>
                    {getStep == 0 ?
                        <div style={{ color: "white", }}>
                            این پروژه برای افزایش دقت پزشکان و بهره گیری سامانه هوش مصنوعی بیمارستان می‌باشد
                            لذا صمیمانه خواهش می‌کنم اطلاعات خود را به درستی وارد نمایید تا شما نیز در این تحقیقات و
                            نجات جان انسان‌ها کمک یار ما باشید.
                        </div> : undefined
                    }

                    {getStep == 1 ?
                        <div style={{ color: "white", }}>
                            بسیار ممنون می‌شوم در صورت بروز هر گونه خطا در سامانه تیم پشتیبانی را در جریان اتفاقات قرار بدهید.
                            <p>                            شماره تماس تیم پشتیبانی:۰۹۱۹۷۶۱۶۹۲۳ </p>
                        </div> : undefined
                    }


                    <div style={{ height: "calc(3vh + 16px)" }} />
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                        {getStep < maxStep ?
                            <ButtonEffectShadow text={"بعدی"}
                                onclick={() => { setStep(getStep + 1) }}
                                colors={colors.panel.buttonYellowBackground}
                            /> : undefined
                        }
                        <Link to={links.loginAndRegister.login}>
                            <ButtonEffectShadow text={"ورود"}
                                colors={colors.panel.buttonYellowText}
                            />
                        </Link>

                    </div>
                </div>
            </CardFromMUI>
        </div>
    )
}
export default HomePage;