import colors from "../../../../config/colors";
import { styled as styledMUI } from "@mui/material/styles";
import { InterfaceGetMyPatientsListServer, InterfacePatientsListPage, interfaceEntityMyRequestToTomorboard, interfaceGetApiForAllMyRequestToTomorboard } from "./interfacePatientsListPage";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectFromMUI } from "../../../../components/MUIConfig/SelectFromMUI";
import { FormControl, Grid, useTheme } from "@mui/material";
import { LabelInputFromMUI } from "../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../components/MUIConfig/MenuItemFromMUI";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import PatientRow from "./PatientRow";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceServerGetInformationDocotor } from "../../account/interfaceDoctorAccount";
import EmptyList from "../../../../components/emptyList/EmptyList";

function PatientsListPage(paramsPage: InterfacePatientsListPage) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getListMyPaitient, setListMyPaitient] = useState<InterfaceGetMyPatientsListServer>();
    const [getListMyRequestToTomorboard, setListMyRequestToTomorboard] = useState<interfaceEntityMyRequestToTomorboard[]>([]);
    const [getStepDisplay, setStepDisplay] = useState<number>(-1);
    const getApiListOfMyPatient = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.patient.getListAllPatient;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetMyPatientsListServer;
                setListMyPaitient(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiListOfMyRequestToTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.patient.getListMyRequestToTomorboard;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetApiForAllMyRequestToTomorboard;
                setListMyRequestToTomorboard(t.data.data)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safajooon")
        getApiListOfMyPatient().then(
            getApiListOfMyRequestToTomorboard
        )
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            // overflowY: "scroll",
            // overflowX: "hidden",
            // paddingBlock: "calc(1vh + 16px)"

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%", flexDirection: "row", alignItems: "center",
                justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {confComps.panel.doctor.patientsList.header}
                </h5>
                <div style={{
                    flexGrow: "1", display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {getListMyPaitient?.data.data.map((Patient, index, array) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <PatientRow
                                    id={Patient.id}
                                    img={Patient.profile_pic ? `${baseUrl}/` + Patient.profile_pic : undefined}
                                    name={Patient.first_name}
                                    family={Patient.last_name}

                                    national_id={Patient.national_id}
                                    landline={Patient.landline}
                                    gender={Patient.gender}
                                    city={Patient.city}
                                    phone={Patient.phone}
                                    address={Patient.address}
                                    typeOfDisplay={paramsPage.typeOfDisplay}
                                    listRequestOfThisPatient={getListMyRequestToTomorboard.filter(myRequest => myRequest.patient.id == Patient.id)}
                                    callbackRefreshPage={() => {
                                        getApiListOfMyPatient().then(
                                            getApiListOfMyRequestToTomorboard
                                        )
                                    }} />
                            </Grid>
                        )
                    })
                    }
                </GridFromMUI>
                {
                    getListMyPaitient?.data.data.length == 0 ?
                        <EmptyList text={"هیچ بیماری ندارید"} /> : undefined
                }
            </div>
        </div>
    );
}
export default PatientsListPage;