import { useTheme } from "@mui/material";

export const ConvertNumberToPersian = (number: number|string): string => {
    const regex = /[\d]/g;
    console.log("in");
    const mTheme = useTheme();
    if (mTheme.direction == "ltr") {
        return `${number}`;
    }
    const convertedNumber = number.toString().replace(regex, (match, index) => {
        console.log("regex", match, index)
        switch (match) {
            case "0":
                return "۰"; // Persian equivalent of "0"
            case "1":
                return "۱"; // Persian equivalent of "1"
            case "2":
                return "۲"; // Persian equivalent of "2"
            case "3":
                return "۳"; // Persian equivalent of "3"
            case "4":
                return "۴"; // Persian equivalent of "4"
            case "5":
                return "۵"; // Persian equivalent of "5"
            case "6":
                return "۶"; // Persian equivalent of "6"
            case "7":
                return "۷"; // Persian equivalent of "7"
            case "8":
                return "۸"; // Persian equivalent of "8"
            case "9":
                return "۹"; // Persian equivalent of "9"
            default:
                return match;
        }
    });
    return convertedNumber;
};