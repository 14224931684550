import { CSSProperties } from "react";

export enum enumArrow {
    left = "left",
    right = "right",
    none ="none"
};
export interface interfaceButtonArrow {
    width:string;
    direction:enumArrow;
    text?:string;
    colors?:{
        background:string,
        hoverBackground:string,
        text:string,
        hoverText:string
    },
    styleMainDiv?: CSSProperties | undefined;
    onClick?:()=>void;
    
}