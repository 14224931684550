import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import EditText from "../../../../../components/editText/EditText";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import colors from "../../../../../config/colors";
import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceGetTomorboardsListFromServer } from "../interfaceTomorboardsList";
import { interfaceApiRequestOfPatient, interfaceRequestOfPatient } from "./interfaceRequestOfPatient";
import RowRequestOfPatient from "./RowRequestOfPatient";
import { interfaceGetOneTomorboardFromServer } from "../../settingTomorboard/interfaceSettingTomorboard";
import { AddRounded } from "@mui/icons-material";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import links from "../../../../../config/links";

function RequestOfPatient(pagesParams: interfaceRequestOfPatient) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getRequestOfPatient, setRequestOfPatient] = useState<interfaceApiRequestOfPatient>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const { idTomorboard } = useParams();
    const [getTomor, setTomorboard] = useState<interfaceGetOneTomorboardFromServer>();

    const getApiDataOfOneTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.getDataOfOneTomorboard;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetOneTomorboardFromServer;

                setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getDoctorListFromServer = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.getListAllRequestOfPatient;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/` + "requests/", mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiRequestOfPatient;

                setRequestOfPatient(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getDoctorListFromServer();
        getApiDataOfOneTomorboard();
    }, []);
    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {" لیست درخواست‌های توموربورد "}"{getTomor?.data.name}"
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>

            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>

                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {getRequestOfPatient?.data.data.filter((Tm, index, arra) => {
                        if (getSearchStr.length > 0 && Tm.doctor.first_name.indexOf(getSearchStr) && Tm.doctor.last_name.indexOf(getSearchStr) && Tm.patient.first_name.indexOf(getSearchStr) && Tm.patient.last_name.indexOf(getSearchStr)) {
                            return undefined
                        }
                        return Tm;
                    }).map((tomorboard, index, array) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                {idTomorboard ?
                                    <RowRequestOfPatient
                                        serverData={tomorboard} idTomorBoard={idTomorboard}
                                        nameTomorBoard={getTomor?.data.name}
                                        callbackRefresh={getDoctorListFromServer} /> : undefined
                                }
                            </Grid>
                        )
                    })
                    }
                    {getRequestOfPatient?.data.data.length == 0 ?
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                            <h4 style={{
                                color: colors.yellowHeader, width: "100%", textAlign: "center"
                                , marginTop: "20vh", borderRadius: "10px",
                                border: "1px solid red", paddingBlock: "3vh"
                            }}>
                                {"هیچ درخواست جدیدی وجود ندارد!"}
                            </h4>
                        </Grid> : undefined
                    }
                </GridFromMUI>
            </div>
        </div>
    );

}
export default RequestOfPatient;