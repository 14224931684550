import { Grid, useTheme } from "@mui/material";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


import { interfaceListMeetingsOfDoctorsTomorBoard } from "./interfaceListMeetingsOfDoctorsTomorBoard";
import colors from "../../../../config/colors";
import { AddRounded } from "@mui/icons-material";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import links from "../../../../config/links";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceRowListMeetingsOfDoctorsTomorBoard } from "./interfaceRowListMeetingsOfDoctorsTomorBoard";
import RowListMeetingsOfDoctorsTomorBoard from "./RowListMeetingsOfDoctorsTomorBoard";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";


function ListMeetingsOfDoctorsTomorBoard(pagesParams: interfaceListMeetingsOfDoctorsTomorBoard) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getRequestOfListMeeting, setRequestOfListMeeting] = useState<interfaceRowListMeetingsOfDoctorsTomorBoard>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const getApiListMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.tomorboard.meeting.getlistAllMeetingOfMyDoctor;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceRowListMeetingsOfDoctorsTomorBoard;
                setRequestOfListMeeting(t)

                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    useEffect(() => {
        getApiListMeeting();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            paddingBlock: "calc(1vh + 16px)",

        }}>
            {/* <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}> */}
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {"لیست جلسات:"}
                </h5>

            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
                paddingBlock: "calc(1vh + 16px)"
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {getRequestOfListMeeting?.data.data

                        .map((meeting, index, array) => {
                            return (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>


                                    <RowListMeetingsOfDoctorsTomorBoard
                                        id={meeting.id}
                                        title={meeting.title}
                                        description={meeting.description}
                                        start_datetime={meeting.start_datetime}
                                        end_datetime={meeting.end_datetime}
                                        tumorboard={meeting.tumorboard}
                                        requests={meeting.requests}
                                        members={meeting.members}
                                    />


                                </Grid>
                            )
                        })
                    }

                </GridFromMUI>
            </div>

        </div>
    )
}
export default ListMeetingsOfDoctorsTomorBoard;
