import Card from "@mui/material/Card/Card";
import { styled as styledMUI } from "@mui/material/styles";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Box, Button, FormControl, LinearProgress, SelectChangeEvent } from "@mui/material";
import EditText from "../../../components/editText/EditText";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import links from "../../../config/links";
import { useNavigate } from "react-router-dom";
import colors from "../../../config/colors";
import { InterfaceRegisterGetPhoneOrEmail } from "./interfaceRegisterGroup";
import { ReactNode, useState } from "react";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { useTheme } from "@emotion/react";
import { AlertPopup } from "../../../components/MUIConfig/alert_popup/AlertPopup";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { useDispatch } from "react-redux";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { EnumSupportRoles } from "../../../utils/Role";


export const DivAllTextContainer = styledMUI(Card)(({ theme }) => ({
    color: "white",
    background: "rgba(217,214,214,0.15)",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "start",
    zIndex: 100,
    position: "absolute",
    top: "50%",


    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        maxWidth: "80%",
        minWidth: "50%",
        transform: "translate(0,-50%)",
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "center",
        maxWidth: "40%",
        minWidth: "30%",
        right: theme.direction == "ltr" ? undefined : 0,
        left: theme.direction == "ltr" ? 0 : undefined,
        transform: theme.direction == "ltr" ? "translate(12.5%,-50%)" : "translate(-12.5%,-50%)",
    }

}));
const ImgLogo = styledMUI("img")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('md')]: {
        width: "50vw",
    },
    [theme.breakpoints.up('md')]: {
        width: "300px",
    }
}));


const RegisterGetPhoneOrEmail = (paramsPage: InterfaceRegisterGetPhoneOrEmail) => {

    const [getValueEditText, setValueEditText] = useState<string>("");
    const [getValueRole, setValueRole] = useState<EnumSupportRoles>(EnumSupportRoles.null);
    const mtheme = useTheme();
    const dispatch = useDispatch();
    var confComps = ConfigComps();

    const onClickSubmitHandler = async () => {
        if (getValueRole == EnumSupportRoles.null) {
            dispatch(ShowAlert({ text: `لطفا نقش خود را انتخاب کنید!`, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return
        }
        dispatch(isVisibilityProgressBar(true));
        const dataGenerateOTP = {
            phone: getValueEditText,
        };

        await RequestHandler(dispatch, HandlerLangUrl(urlsList.auth.register.generateOTP.url, mtheme),
            urlsList.auth.register.generateOTP.method, urlsList.auth.register.generateOTP.isTokenNecessary, dataGenerateOTP)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                dispatch(ShowAlert({ text: "پیامک ارسال شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                paramsPage.callbackForUpdateState(getValueEditText, getValueRole);
            })
            .catch((e: any) => {
                console.log("response:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        if (Number(event.target.value) == 0) {
            setValueRole(EnumSupportRoles.patient)
        }
        else if (Number(event.target.value) == 1) {
            setValueRole(EnumSupportRoles.doctor)
        }
    }

    return (

        <div style={{
            marginLeft: "6vw",
            marginRight: "6vw",
            boxSizing: "border-box",
            gap: "calc(1vh + 12px)",
            display: paramsPage.display ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: "calc(48px + 2vh)",
        }}>

            <EditText title={confComps.panel.loginAndRegister.register.txtEditText.phone.title}
                styledTextField={{
                    border: "1px solid #fff",
                    borderRadius: "555px",
                    width: "100%",
                    color: colors.panel.doctor.account.step.edittext.color,
                    background: colors.panel.doctor.account.step.edittext.background,
                }}
                isDataMustLtr={true}
                hintText={confComps.panel.loginAndRegister.register.txtEditText.phone.hintText}
                sizeWidth="small"
                onChangeValue={setValueEditText}
                isNecessary={false}
                isMultiLine={false}
                isSmallTitle={true}
                isColorDark={false}
                isSmallHintShow={false} />
            <div style={{
                flexGrow: "1", display: "flex", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", width: "100%",
            }}>
                <FormControl
                    style={{
                        margin: "8px 0px 0px 0px",
                        color: colors.yellowHeader
                    }}
                    sx={{ m: 1, minWidth: "150px" }} >
                    <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.loginAndRegister.register.chooseRole.header}</LabelInputFromMUI>
                    <SelectFromMUI
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        style={{
                            color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                            borderRadius: "555px",

                        }}
                        // value={getSelectedItem}
                        label={confComps.panel.patient.doctorsList.category}
                        onChange={handleChangeDropDown}
                    >
                        {confComps.panel.loginAndRegister.register.chooseRole.roles.map((value, index, array) => {
                            return (
                                <MenuItemFromMUI value={value.id}
                                    key={index}>{value.text}</MenuItemFromMUI>
                            )
                        })}
                    </SelectFromMUI>
                </FormControl>
                <ButtonEffectShadow text={confComps.panel.loginAndRegister.register.acceptPhone} onclick={onClickSubmitHandler} />
            </div>
            <TextWithLink list={confComps.panel.loginAndRegister.register.textRedirect} />
        </div>
        // </DivAllTextContainer>



    )
}

export default RegisterGetPhoneOrEmail;