import Card from "@mui/material/Card/Card";
import { styled as styledMUI } from "@mui/material/styles";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Box, Button, LinearProgress, useTheme } from "@mui/material";
import EditText from "../../../components/editText/EditText";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import { useNavigate } from "react-router-dom";
import colors from "../../../config/colors";
import { InterfaceRegisterSetPassword, InterfaceRegisterValidateOTP } from "./interfaceRegisterGroup";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { DivAllTextContainer } from "./RegisterGetPhoneOrEmail";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import links from "../../../config/links";
import { EnumSupportRoles } from "../../../utils/Role";


const ImgLogo = styledMUI("img")(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down('md')]: {
        width: "50vw",
    },
    [theme.breakpoints.up('md')]: {
        width: "300px",
    }
}));


const RegisterSetPassword = (paramsPage: InterfaceRegisterSetPassword) => {
    const navigate = useNavigate();
    const mtheme = useTheme();
    const dispatch = useDispatch();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getValueEditText1, setValueEditText1] = useState<string>("");
    const [getValueEditText2, setValueEditText2] = useState<string>("");

    const onClickSubmitHandler = async () => {
        // dispatch(isVisibilityProgressBar(true));
        if (getValueEditText1 != getValueEditText2) {
            // dispatch(isVisibilityProgressBar(false));
            dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.register.notification.error.confirmPasswordNotMatch, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        else if (getValueEditText1.length < 5) {
            dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.register.notification.warning.passwordIsSimple, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }

        const dataSetPassword = {
            password: getValueEditText1,
        };

        await RequestHandler(dispatch, HandlerLangUrl(urlsList.auth.setPassword.url, mtheme),
            urlsList.auth.setPassword.method, urlsList.auth.setPassword.isTokenNecessary, dataSetPassword, profileInStore)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "پسورد شما ثبت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                paramsPage.callbackForUpdateState(getValueEditText1);
                if (String(paramsPage.roleSelected) == String(EnumSupportRoles.doctor)) {
                    navigate(links.doctor.listPatient)
                }
                else {
                    navigate(links.patient.MyRequest)
                }
            })
            .catch((e: any) => {
                console.log("response:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    return (

        <div style={{
            marginTop: "4vh",
            marginBottom: "4vh",
            marginLeft: "6vw",
            marginRight: "6vw",
            boxSizing: "border-box",
            gap: "calc(1vh + 12px)",
            display: paramsPage.display ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: "calc(48px + 2vh)",
        }}>

            <EditText title={confComps.panel.loginAndRegister.register.txtEditText.password.title}
                styledTextField={{
                    border: "1px solid #fff",
                    borderRadius: "555px",
                    width: "100%",
                    color: colors.panel.doctor.account.step.edittext.color,
                    background: colors.panel.doctor.account.step.edittext.background,
                }}
                isDataMustLtr={true}
                hintText={confComps.panel.loginAndRegister.register.txtEditText.password.hintText}
                sizeWidth="small"
                onChangeValue={setValueEditText1}
                isNecessary={false}
                isMultiLine={false}
                isSmallTitle={true}
                isColorDark={false}
                type="password"
                isSmallHintShow={false} />

            <EditText title={confComps.panel.loginAndRegister.register.txtEditText.rePassword.title}
                styledTextField={{
                    border: "1px solid #fff",
                    borderRadius: "555px",
                    width: "100%",
                    color: colors.panel.doctor.account.step.edittext.color,
                    background: colors.panel.doctor.account.step.edittext.background,
                }}
                isDataMustLtr={true}
                hintText={confComps.panel.loginAndRegister.register.txtEditText.rePassword.hintText}
                sizeWidth="small"
                onChangeValue={setValueEditText2}
                isNecessary={false}
                isMultiLine={false}
                type="password"
                isSmallTitle={true}
                isColorDark={false}
                isSmallHintShow={false} />

            <ButtonEffectShadow text={confComps.panel.loginAndRegister.register.acceptPassword}
                onclick={onClickSubmitHandler} styleMainDiv={{ width: "100%" }} />
        </div>
    )
}

export default RegisterSetPassword;