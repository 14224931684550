import { useNavigate } from "react-router-dom";
import ButtonArrow from "../../../../../components/buttonArrow/ButtonArrow";
import { enumArrow } from "../../../../../components/buttonArrow/interfaceButtonArrow";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import colors from "../../../../../config/colors";
import links from "../../../../../config/links";
import { interfaceCardTreatmentProcesses } from "./interfaceBigCardTreatmentProcesses";
import { Person2Rounded } from "@mui/icons-material";
import { baseUrl } from "../../../../../api/UrlsList";
import { Grid } from "@mui/material";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import moment from "moment-jalaali";
import { ConvertNumberToPersian } from "../../../../../helper/ConvertNumberToPersian";

function BigCardTreatmentProcesses(componenetParams: interfaceCardTreatmentProcesses) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    return (
        <div style={{
            background: colors.blue.darkbackground, borderRadius: "24px",
            display: "flex", flexDirection: "row",
            justifyContent: "space-between", alignItems: "center",
            width: "100%",
            padding: "calc(1vh + 4px) calc(1vw + 4px)",
            boxSizing: "border-box"
        }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", gap: "calc(2vw + 8px)" }}>
                {
                    componenetParams.treatmentProcessRow.doctor?.profile_pic ?
                        <ImageSquare
                            img={`${baseUrl}/` + componenetParams.treatmentProcessRow.doctor.profile_pic}
                            isScaleable={{}}
                            width={"calc(2vw + 64px)"}
                            borderRadius="8px" border="1px solid white"
                        /> :
                        <ImageSquare iconSvg={Person2Rounded}
                            width={"calc(2vw + 64px)"}
                            color={colors.panel.doctor.patientsList.selector.color}
                            fontsizeIcon="calc(2vw + 64px)"
                            borderRadius="8px" border="1px solid white" />
                }
                {/* <ImageSquare
                    img={componenetParams.imgUrl}
                    width={componenetParams.isWidthFull ? "calc(2vw + 64px)" : "calc(20% - 4px)"}
                    borderRadius="8px" border="1px solid white"
                /> */}
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                        <p style={{ color: "white", textAlign: "start", whiteSpace: "nowrap", hyphens: "auto" }}>
                            {`عنوان: `}
                            {componenetParams.treatmentProcessRow.title}
                        </p>
                    </Grid>
                    <Grid item xs={4} sm={4} md={1} lg={1} xl={1}>
                        <p style={{ color: "white", textAlign: "start", whiteSpace: "nowrap", hyphens: "auto" }}>
                            {`نام دکتر: `}
                            {componenetParams.treatmentProcessRow.doctor?.first_name}
                            {' '}
                            {componenetParams.treatmentProcessRow.doctor?.last_name}
                        </p>
                    </Grid>
                    <Grid item xs={4} sm={2} md={1} lg={1} xl={1}>
                        <p style={{ color: "white", textAlign: "start", whiteSpace: "nowrap", hyphens: "auto" }}>
                            {"تاریخ ثبت: "}{ConvertNumberToPersian(`${moment.unix(componenetParams.treatmentProcessRow.created_at).format("jYYYY/jM/jD")}`)}
                        </p>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <p style={{ color: "white", textAlign: "start", whiteSpace: "pre-wrap", hyphens: "auto" }}>
                            {`توضیحات: `}{`${componenetParams.treatmentProcessRow.description}`}
                        </p>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <p style={{ color: colors.yellowHeader, textAlign: "start", whiteSpace: "nowrap", hyphens: "auto", }}>
                            {`تگ‌ها: `}
                            {
                                componenetParams.treatmentProcessRow.tags.map((tag, index, ar) => {
                                    if (index == ar.length - 1) {
                                        return (
                                            `${`#`}${`${tag}`}`
                                        )
                                    }
                                    return (
                                        `${`#`}${`${tag}`}${` , `}`
                                    )
                                })
                            }
                        </p>

                    </Grid>
                </GridFromMUI>

            </div>
        </div >
    )
}
export default BigCardTreatmentProcesses;