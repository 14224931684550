import { styled as styledMUI } from "@mui/material/styles";
import links from "../../../config/links";
import { useNavigate } from "react-router-dom";
import RegisterGetPhoneOrEmail, { DivAllTextContainer } from "./RegisterGetPhoneOrEmail";
import Box from "@mui/system/Box/Box";
import { useState } from "react";
import RegisterValidateOTP from "./RegisterValidateOTP";
import RegisterSetPassword from "./RegisterSetPassword";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { AlertPopup } from "../../../components/MUIConfig/alert_popup/AlertPopup";
import { useDispatch } from "react-redux";
import { SetTokenAndRefreshToken } from "../../../store/profileStateSlice";
import { EnumSupportRoles } from "../../../utils/Role";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { interfaceLoginPage } from "../login/interfaceLoginPage";
import { LinearProgress } from "@mui/material";



const ImageBackGround = styledMUI("img")(({ theme }) => ({
    objectFit: "cover",
    position: "absolute",
    top: 0,
    right: theme.direction == "rtl" ? undefined : 0,
    left: theme.direction == "rtl" ? 0 : undefined,
    zIndex: 0,
    maxHeight: "100vh",
    minWidth: "50%",
    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        width: "100%",
        height: "100%",
    }
}));

const BackgroundGradientLeft = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    right: 0,
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "rgba(37, 50, 91, 100)",
        width: "100%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "rtl" ?
            "rgba(37, 50, 91, 1)" :
            "linear-gradient(90deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "51%",
    }


}));


const BackgroundGradientRight = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    right: 0,
    background: "#FF0000",
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "linear-gradient(270deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "70%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "ltr" ?
            "rgba(37, 50, 91, 1)" :
            "linear-gradient(270deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "51%",
    }
}));


function RegisterPage(pageParams: interfaceLoginPage) {
    const [getLevelOfRegisteration, setLevelOfRegisteration] = useState<number>(0);
    const [getPhoneOrEmail, setPhoneOrEmail] = useState<string>("");
    const [getOTP, setOTP] = useState<string>("");
    const [getSelectedRole, setSelectedRole] = useState<EnumSupportRoles>(EnumSupportRoles.null);
    const [getToken, setToken] = useState<string>("");
    const [getRefreshToken, setRefreshToken] = useState<string>("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const callbackForUpdateStateFromPhoneOrEmail = (phoneOrEmail: string, role: EnumSupportRoles) => {
        setPhoneOrEmail(phoneOrEmail);
        setLevelOfRegisteration(1);
        console.log("role role role role");
        console.log(role);
        setSelectedRole(role)
    }
    const callbackForUpdateStateFromValidationOTP = (otp: string, token: string, refreshToken: string) => {
        setOTP(otp);
        setLevelOfRegisteration(2);
        setToken(token);
        setRefreshToken(refreshToken);
        dispatch(SetTokenAndRefreshToken({ token: token, refreshToken: refreshToken, lastUpdatePosixTime: Date.now() }));
    }
    const callbackForUpdateStateFromSetPassword = (password: string) => {
        setPhoneOrEmail(password);
        setLevelOfRegisteration(3);
        // navigate(links.panel.processes_me.listProcess);
    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "start",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundImage: "green",
            backgroundRepeat: "no-repeat",
            position: "relative"
        }}>

            <ImageBackGround
                src="/img/login_background.png"
                srcSet="/img/login_background.png"
                alt="not loaded"
                loading="lazy"
            />
            <BackgroundGradientLeft />
            {/* <BackgroundGradientRight /> */}

            {pageParams.typeOfDisplay < enumListSizeDevices.desktop || true ?
                <div style={{
                    display: "flex", flexDirection: "row",
                    position: "absolute",
                    top: "3vh"
                    , alignSelf: "start", marginBottom: "24px", alignItems: "center", justifyContent: "start"
                    , zIndex: "10"
                }}>
                    <img style={{}} src="/img/logo_hospital_w.png" />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                        <h5 style={{ color: "white" }}>{"بیمارستان امام خمینی"}</h5>
                    </div>
                </div>
                : undefined
            }
            <DivAllTextContainer>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={getLevelOfRegisteration >= 2 ? 100 : (getLevelOfRegisteration + 1) * 33} />
                </Box>
                <RegisterGetPhoneOrEmail display={getLevelOfRegisteration == 0 ? true : false} callbackForUpdateState={callbackForUpdateStateFromPhoneOrEmail} />
                <RegisterValidateOTP
                    display={getLevelOfRegisteration == 1 ? true : false}
                    phoneNumber={getPhoneOrEmail}
                    callbackForUpdateState={callbackForUpdateStateFromValidationOTP}
                    roleSelected={getSelectedRole} />
                <RegisterSetPassword display={getLevelOfRegisteration >= 2 ? true : false} callbackForUpdateState={callbackForUpdateStateFromSetPassword} roleSelected={getSelectedRole} />
            </DivAllTextContainer>



        </div >
    )
}

export default RegisterPage;