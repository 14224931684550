import { FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { InterfaceDoctorAccountStep1 } from "./interfaceDoctorAccountStep1";
import { ConfigComps } from "../../../config/ConfigCOMP";
import EditText, { DivListAllItem, PText } from "../../../components/editText/EditText";
import colors from "../../../config/colors";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ReactNode, useEffect, useState } from "react";
import { EnumGender } from "../../../utils/Gender";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceServerGetInformation } from "../../patient/account/interfacePatientAccountPage";
import { interfaceServerGetInformationDocotor } from "./interfaceDoctorAccount";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";

function DoctorAccountStep1(paramsPage: InterfaceDoctorAccountStep1) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAccountFirstName, setAccountFirstName] = useState<string | undefined>("");
    const [getAccountLastName, setAccountLastName] = useState<string | undefined>("");
    const [getAccountFatherName, setAccountFatherName] = useState<string | undefined>("");
    const [getAccountnationalId, setAccountnationalId] = useState<string | undefined>("");
    const [getAccountCity, setAccountCity] = useState<string | undefined>("");
    const [getAccountLandline, setAccountLandline] = useState<string | undefined>("");
    const [getAccountGender, setAccountGender] = useState<string>();
    const [getAccountPhone, setAccountPhone] = useState<string | undefined>("");
    const [getAccountAddress, setAccountAddress] = useState<string | undefined>("");
    const handleUpdateInformationOfAccount = async () => {
        if (getAccountFirstName?.length == 0 || getAccountLastName?.length == 0 || getAccountnationalId?.length == 0
            || getAccountLandline?.length == 0 || getAccountFatherName?.length == 0 || getAccountAddress?.length == 0
            || getAccountCity?.length == 0) {
            dispatch(ShowAlert({ text: "لطفا فیلد‌ها را تکمیل کنید", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        const dataObj = {
            "first_name": getAccountFirstName,
            "last_name": getAccountLastName,
            "national_id": getAccountnationalId,
            "landline": getAccountLandline,
            "gender": getAccountGender?.toString().toUpperCase().includes("F") ? "F" : getAccountGender?.toString().toUpperCase().includes("M") ? "M" : "O",
            "father_name": getAccountFatherName,
            // "province": "",
            "city": getAccountCity,
            "address": getAccountAddress,
            "status": 1
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.doctor.account.modifyAccount.url, mtheme),
            urlsList.panel.doctor.account.modifyAccount.method, urlsList.panel.doctor.account.modifyAccount.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                paramsPage.callbackGetInfoAgain();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setAccountFirstName(paramsPage.ServerGetInfoAtFirstTime?.data.first_name)
        setAccountLastName(paramsPage.ServerGetInfoAtFirstTime?.data.last_name)
        setAccountFatherName(paramsPage.ServerGetInfoAtFirstTime?.data.father_name)
        setAccountnationalId(paramsPage.ServerGetInfoAtFirstTime?.data.national_id)
        setAccountCity(paramsPage.ServerGetInfoAtFirstTime?.data.city)
        setAccountLandline(paramsPage.ServerGetInfoAtFirstTime?.data.landline)
        if (paramsPage.ServerGetInfoAtFirstTime?.data.gender ?? "".toLowerCase().includes("f")) {
            setAccountGender(EnumGender.female)
        }
        else if (paramsPage.ServerGetInfoAtFirstTime?.data.gender ?? "".toLowerCase().includes("m")) {
            setAccountGender(EnumGender.male)
        }
        else if (paramsPage.ServerGetInfoAtFirstTime?.data.gender ?? "".toLowerCase().includes("o")) {
            setAccountGender(EnumGender.other)
        }
        else {
            setAccountGender(undefined)
        }
        setAccountPhone(paramsPage.ServerGetInfoAtFirstTime?.data.phone)
        setAccountAddress(paramsPage.ServerGetInfoAtFirstTime?.data.address)
    }, [paramsPage.ServerGetInfoAtFirstTime])
    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        setAccountGender(event.target.value as EnumGender)
    }
    return (
        <div style={{
            display: paramsPage.isDisplay ? "flex" : "none",
            width: "100%", boxSizing: "border-box",
            marginTop: "calc(1vh + 12px)",
            maxHeight: "100%",
            overflowY: "auto",

        }}>

            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <h6 style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.doctor.account.step1.header}
                    </h6>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.name}
                        onChangeValue={setAccountFirstName}
                        value={getAccountFirstName}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.family}
                        onChangeValue={setAccountLastName}
                        value={getAccountLastName}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.fatherName}
                        onChangeValue={setAccountFatherName}
                        value={getAccountFatherName}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.nationalCode}
                        onChangeValue={setAccountnationalId}
                        value={getAccountnationalId}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.phone}
                        onChangeValue={() => { }}
                        // onChangeValue={setAccountPhone}
                        disable={true}
                        value={getAccountPhone}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.landline}
                        onChangeValue={setAccountLandline}
                        value={getAccountLandline}

                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start",
                        justifyContent: "space-between", width: "100%",
                    }}>
                        <DivListAllItem>
                            <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"جنسیت خود را انتخاب کنید"}</PText>
                        </DivListAllItem>


                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: "white",
                                width: "100%"
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.doctor.account.step1.gender}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: "inherit", 
                                    background: colors.panel.doctor.patientsList.selector.background,
                                    borderRadius: "555px",
                                    boxSizing: "border-box",

                                }}
                                value={`${getAccountGender}`}
                                label={confComps.panel.patient.doctorsList.category}
                                onChange={handleChangeDropDown}
                            >
                                {confComps.unit.enum.gender.map((value, index, array) => {
                                    return (
                                        <MenuItemFromMUI value={value.key}
                                            key={index}>{value.value}</MenuItemFromMUI>
                                    )
                                })}
                            </SelectFromMUI>
                        </FormControl>
                    </div>

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.city}
                        onChangeValue={setAccountCity}
                        value={getAccountCity}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <EditText
                        title={confComps.panel.doctor.account.step1.address}
                        onChangeValue={setAccountAddress}
                        value={getAccountAddress}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "14px",
                            width: "100%",
                            boxSizing: "border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}

                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={true}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                        <ButtonEffectShadow text={confComps.panel.doctor.account.step1.buttonNext} onclick={handleUpdateInformationOfAccount} />
                    </div>
                </Grid>
            </GridFromMUI>
        </div>
    )
}
export default DoctorAccountStep1;