import { styled as styledMUI } from "@mui/material/styles";
import colors from "../../../../config/colors";
import { InterfacePatientRow } from "./interfacePatientRow";
import links from "../../../../config/links";
import { AddRounded, ArrowBackRounded, Person2Rounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { Grid } from "@mui/material";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";

function PatientRow(paramsComponent: InterfacePatientRow) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const [getShowDialogChooseTomorBoard, setShowDialogChooseTomorBoard] = useState<number>(-1);
    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "20px calc(0.5vw + 12px)",
            alignItems: paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
            justifyContent: "start",
            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
            borderRadius: "24px",

            background: colors.panel.doctor.account.step.background
        }}>
            {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ?

                <div style={{
                    width: "calc(10vw + 32px)",
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        width: "100%", height: "100%", position: "absolute",
                        boxSizing: "border-box",
                        borderRadius: "12px",
                        overflow: "hidden",
                        border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                    }}>
                        {/* <div style={{
                            width: "fit-content", height: "fit-content",
                            borderRadius: "12px",
                            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                        }}> */}
                        {paramsComponent.img ?
                            <ImageSquare img={`${paramsComponent.img}`}
                                isScaleable={{}}
                                width={"100%"}
                                borderRadius="8px" border="1px solid white"
                            /> :
                            <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                color={colors.panel.doctor.patientsList.selector.color}
                                fontsizeIcon="27px"
                                borderRadius="8px" border="1px solid white"
                            />
                        }
                        {/* </div> */}
                    </div>
                    <div style={{ paddingBottom: "100%" }} />
                </div>
                : undefined}
            <h5 style={{
                color: "white", fontSize: "1.2rem", fontWeight: "200",
                marginInline: "4px",
                marginBlockEnd: "calc(0.5vh + 8px)"
            }}>
                {`${paramsComponent.name} ${paramsComponent.family}`}
            </h5>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "start", gap: "calc(1vw + 8px)"
            }}>
                {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? undefined :
                    <div style={{
                        width: "min(calc(6.5vw + 32px) , 130px)",
                        position: "relative",
                        overflow: "hidden",
                        flexGrow: "0",
                        boxSizing: "border-box",
                        // paddingBottom: "calc(12px + 3vw)",
                    }}>
                        <div style={{
                            width: "100%", height: "100%", position: "absolute",
                            overflow: "hidden"

                        }}>
                            <div style={{
                                height: "fit-content",
                            }}>
                                {paramsComponent.img ?
                                    <ImageSquare img={`${paramsComponent.img}`} width={"100%"}
                                        borderRadius="8px" border="1px solid white"
                                        isScaleable={{}}
                                    /> :
                                    <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                        color={colors.panel.doctor.patientsList.selector.color}
                                        fontsizeIcon="270px"
                                        borderRadius="8px" border="1px solid white" />
                                }
                            </div>
                        </div>
                        <div style={{ paddingBottom: "100%" }} />
                    </div>
                }
                <div style={{
                    flexGrow: 1,
                    flexBasis: "0",
                    alignSelf: "stretch", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.phone}{": "}
                                {paramsComponent.phone}
                            </p>
                        </Grid>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"شماره تلفن"}{": "}
                                {paramsComponent.landline}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.email}{": "}
                                {paramsComponent.email}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"کد ملی"}{": "}
                                {paramsComponent.national_id}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.address}{": "}
                                {paramsComponent.address}
                            </p>
                        </Grid>
                    </GridFromMUI>
                </div>

            </div>
        </div>
    );
}
export default PatientRow;