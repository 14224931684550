import { Button, useTheme } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import links from "../../../config/links";
import { interfaceMyHistoryPage } from "./interfaceMyHistoryPage";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { EnumSupportRoles } from "../../../utils/Role";
import { enumCategoryFilter, interfaceGetAllMediaFromServer } from "../myFile/interfaceMyFilePage";
import CommitOfMyHistory from "./CommitOfMyHistory";

function MyHistoryPage(pageParams: interfaceMyHistoryPage) {
    var confComps = ConfigComps();
    const mtheme = useTheme();
    const navigate = useNavigate();
    const { idMyPatient } = useParams();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getListOfMedicalRecordMedia, setListOfMedicalRecordMedia] = useState<interfaceGetAllMediaFromServer>();
    const getListOfAllFileOfMedicalRecord = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patient.myFile.getMedicalRecord;
        let url = urlObj.url
        if (pageParams.role == EnumSupportRoles.doctor) {
            urlObj = urlsList.panel.doctor.patient.getMedicalRecordOfMyPatient
            url = urlObj.url + idMyPatient + "/medical-records/?page=1&page_size=1000"
            console.log("you are doctor:)")
        }
        else if (pageParams.role == EnumSupportRoles.secretary) {
            urlObj = urlsList.panel.secretary.patient.getPatientsMedicalRecord
            url = urlObj.url + idMyPatient + "/medical-records/?page=1&page_size=1000"
            console.log("you are secretary:)")
        }
        await RequestHandler(dispatch, HandlerLangUrl(url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetAllMediaFromServer;
                setListOfMedicalRecordMedia(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safajooon")
        getListOfAllFileOfMedicalRecord();
    }, []);




    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            overflowY: "auto",
            overflowX: "hidden",
            paddingBlock:"calc(1vh + 16px)"

        }}>
            {
                getListOfMedicalRecordMedia?.data.data.map((commit, index, array) => {
                    return (
                        <CommitOfMyHistory
                            files={commit.files}
                            title={commit.title}
                            description={commit.description}
                            index={getListOfMedicalRecordMedia?.data.data.length - (index)}
                            key={`s_${index}`}
                            time={commit.created_at} />
                    )
                })
            }


        </div>
    )
}
export default MyHistoryPage;