import { Alert, Snackbar } from "@mui/material";
import { enumTypeOfAlert, interfaceAlertPopup } from "./interfaceAlertPopup";
import { useEffect, useState } from "react";
import { createTheme, styled as styledMUI, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ShowAlert } from "../../../store/AlertStateModeSlice";

const SnackbarCustom = styledMUI(Snackbar)(({ theme }) => ({
    left: theme.direction == "rtl" ? "auto !important" : "25px !important",
    right: theme.direction == "ltr" ? "auto !important" : "25px !important",

}));

export const AlertPopup = (params: interfaceAlertPopup) => {
    const [getIsAlertShow, setIsAlertShow] = useState<boolean>(false);
    const [getIsFirstTime, setIsFirstTime] = useState<boolean>(false);//by this variable i try to ignore first time
    useEffect(() => {
        console.log("getIsFirstTime")
        console.log(getIsFirstTime)
        if (getIsFirstTime) {
            setIsAlertShow(true);
        }
        setIsFirstTime(true);
    }, [params.lastTimeecondsUpdate]);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            setIsAlertShow(false);
            return;
        }

        setIsAlertShow(false);
    };
    function checkIsPersian(text: string) {

        const persianRange = '\u0600-\u06FF\uFB8A-\uFDFF-\u0600-\u06FF'; // Includes Persian-specific characters
        const regex = new RegExp(`[${persianRange}]`);

        return regex.test(text);
    }

    var isRTLText = checkIsPersian(params.text)
    return (
        <SnackbarCustom open={getIsAlertShow} autoHideDuration={4000} onClose={handleClose}>
            {params.typeOfWarning ?
                <Alert style={{ direction: checkIsPersian(params.text) ? "rtl" : "ltr" }} severity={params.typeOfWarning ? params.typeOfWarning : "error"} sx={{ width: '100%', alignItems: "center" }} onClose={handleClose}>
                    {params.text}
                </Alert> : undefined}
        </SnackbarCustom>
    )

}