import { FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { InterfaceDoctorAccountStep1 } from "./interfaceDoctorAccountStep1";
import { ConfigComps } from "../../../config/ConfigCOMP";
import EditText, { DivListAllItem, PText } from "../../../components/editText/EditText";
import colors from "../../../config/colors";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ReactNode, useEffect, useState } from "react";
import { EnumGender } from "../../../utils/Gender";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceServerGetInformation } from "../../patient/account/interfacePatientAccountPage";
import { interfaceServerGetInformationDocotor } from "./interfaceDoctorAccount";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";

function DoctorAccountStep4(paramsPage: InterfaceDoctorAccountStep1) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAccountFirstName, setAccountFirstName] = useState<string | undefined>("");
    const [getAccountLastName, setAccountLastName] = useState<string | undefined>("");
    const [getAccountFatherName, setAccountFatherName] = useState<string | undefined>("");
    const [getAccountnationalId, setAccountnationalId] = useState<string | undefined>("");
    const [getAccountCity, setAccountCity] = useState<string | undefined>("");
    const [getAccountLandline, setAccountLandline] = useState<string | undefined>("");
    const [getAccountGender, setAccountGender] = useState<EnumGender>();
    const [getAccountPhone, setAccountPhone] = useState<string | undefined>("");
    const [getAccountAddress, setAccountAddress] = useState<string | undefined>("");
    const handleUpdateInformationOfAccount = async () => {
        dispatch(isVisibilityProgressBar(true));
        const dataObj = {
            "first_name": getAccountFirstName,
            "last_name": getAccountLastName,
            "national_id": getAccountnationalId,
            "profile_pic": undefined,
            "landline": getAccountLandline,
            "gender": getAccountGender?.toString().includes("f") ? "F" : getAccountGender?.toString().includes("m") ? "M" : "O",
            "father_name": getAccountFatherName,
            "province": "",
            "city": getAccountCity,
            "address": getAccountAddress
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.patient.account.modifyAccount.url, mtheme),
            urlsList.panel.patient.account.modifyAccount.method, urlsList.panel.patient.account.modifyAccount.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        setAccountGender(EnumGender[event.target.value as keyof typeof EnumGender])
    }
    return (
        <div style={{
            width: "100%", display: paramsPage.isDisplay ? "flex" : "none",
            minHeight: "80vh",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "calc(1vw + 12px)",
            alignItems: "center", justifyContent: "center", marginBottom: "20px",
            background: colors.panel.doctor.account.step.background
        }}>
            <h5 style={{ color: colors.green.active }}>{"درخواست شما با موفقیت تایید شد"}</h5>

        </div>
    )
}
export default DoctorAccountStep4;