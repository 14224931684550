import colors from "../../../config/colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Button, Card, FormControl, SelectChangeEvent, TextField, Theme, useTheme } from "@mui/material";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import EditText from "../../../components/editText/EditText";
import { styled as styledMUI } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import links from "../../../config/links";
import { ReactNode, useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { useDispatch } from "react-redux";
import { SetTokenAndRefreshToken } from "../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { interfaceLoginPage } from "./interfaceLoginPage";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { EnumSupportRoles } from "../../../utils/Role";

const DivAllTextContainer = styledMUI("div")(({ theme }) => ({
    display: "flex",
    color: "white",
    // marginLeft:"5%",
    paddingLeft: "5%",
    // marginRight:"5%",
    paddingRight: "5%",
    paddingTop: "calc(48px + 2vh)",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "start",
    zIndex: 100,
    gap: "calc(1vh + 8px)",
    position: "absolute",
    top: "50%",


    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        maxWidth: "80%",
        minWidth: "50%",
        transform: "translate(0,-50%)",
    },
    [theme.breakpoints.up('md')]: {
        alignItems: "center",
        maxWidth: "40%",
        minWidth: "30%",
        right: theme.direction == "ltr" ? undefined : 0,
        left: theme.direction == "ltr" ? 0 : undefined,
        transform: theme.direction == "ltr" ? "translate(12.5%,-50%)" : "translate(-12.5%,-50%)",
    }

}));
const ImageBackGround = styledMUI("img")(({ theme }) => ({
    objectFit: "cover",
    position: "absolute",
    top: 0,
    right: theme.direction == "rtl" ? undefined : 0,
    left: theme.direction == "rtl" ? 0 : undefined,
    zIndex: 0,
    maxHeight: "100vh",
    minWidth: "50%",
    [theme.breakpoints.down('md')]: {
        alignItems: "center",
        width: "100%",
        height: "100%",
    }
}));

const BackgroundGradientLeft = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    right: 0,
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "rgba(37, 50, 91, 100)",
        width: "100%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "rtl" ?
            "rgba(37, 50, 91, 1)" :
            "linear-gradient(90deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "51%",
    }


}));
const BackgroundGradientRight = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    left: 0,
    background: "#FF0000",
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "linear-gradient(270deg, rgba(37, 50, 91, 0) 2.14%, rgba(37, 50, 91, 0.25) 44.41%, rgba(37, 50, 91, 0.65) 73.44%, rgba(37, 50, 91, 1) 99.92%)",
        width: "70%",
    },
    [theme.breakpoints.up('md')]: {
        // background:"#25315B",
        background: theme.direction == "ltr" ?
            "rgba(37, 50, 91, 1)" :
            "linear-gradient(90deg, rgba(37, 49, 91, 0) 2.14%, rgba(37, 49, 91, 0.25) 44.41%, rgba(37, 49, 91, 0.65) 73.44%, rgba(37, 49, 91, 1) 99.92%)",
        width: "51%",
    }
}));


function LoginPage(pageParams: interfaceLoginPage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const [getEdittextUser, setEdittextUser] = useState<string>("");
    const [getEdittextPassword, setEdittextPassword] = useState<string>("");
    const [getRoleSelected, setRoleSelected] = useState<EnumSupportRoles>(EnumSupportRoles.null);


    const onClickSubmitHandler = async () => {
        if (getRoleSelected.toString() == EnumSupportRoles.null.toString()) {
            dispatch(ShowAlert({ text: "لطفا نقش خود را انتخاب کنید!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));

        const dataLogin = {
            phone: getEdittextUser,
            password: getEdittextPassword,
        };
        var urlSelected = urlsList.panel.doctor.login;
        if (getRoleSelected.toString() == EnumSupportRoles.patient.toString()) {
            urlSelected = urlsList.panel.patient.login
        }
        else if (getRoleSelected.toString() == EnumSupportRoles.secretary.toString()) {
            urlSelected = urlsList.panel.secretary.login
        }
        else if (getRoleSelected.toString() == EnumSupportRoles.admin.toString()) {
            urlSelected = urlsList.panel.admin.login
        }

        await RequestHandler(dispatch, HandlerLangUrl(urlSelected.url, mtheme),
            urlSelected.method, urlSelected.isTokenNecessary, dataLogin)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                dispatch(SetTokenAndRefreshToken({ token: response.data.access, refreshToken: response.data.refresh, lastUpdatePosixTime: Date.now() }))
                if (getRoleSelected.toString() == EnumSupportRoles.patient) {
                    navigate(links.patient.MyRequest);
                }
                else if (getRoleSelected.toString() == EnumSupportRoles.doctor) {
                    navigate(links.doctor.listPatient);
                }
                else if (getRoleSelected.toString() == EnumSupportRoles.secretary) {
                    navigate(links.secretary.tomorboard.tomorboardsList);
                }
                else if (getRoleSelected.toString() == EnumSupportRoles.admin) {
                    // navigate(links.doctor.listPatient);
                }
            })
            .catch((e: any) => {
                console.log("response:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        if (Number(event.target.value) == 0) {
            setRoleSelected(EnumSupportRoles.patient)
        }
        else if (Number(event.target.value) == 1) {
            setRoleSelected(EnumSupportRoles.doctor)
        }
        else if (Number(event.target.value) == 2) {
            setRoleSelected(EnumSupportRoles.secretary)
        }
        else if (Number(event.target.value) == 3) {
            setRoleSelected(EnumSupportRoles.admin)
        }
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "start",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundImage: "green",
            backgroundRepeat: "no-repeat",
            position: "relative"
        }}>
            <ImageBackGround
                src="/img/login_background.png"
                srcSet="/img/login_background.png"
                alt="not loaded"
                loading="lazy"
            />
            <BackgroundGradientLeft />



            <DivAllTextContainer>
                {pageParams.typeOfDisplay < enumListSizeDevices.desktop ?
                    <div style={{
                        display: "flex", flexDirection: "row"
                        , alignSelf: "start", marginBottom: "24px", alignItems: "center", justifyContent: "start"
                    }}>
                        <img style={{}} src="/img/logo_hospital_w.png" />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
                            <h5>{"بیمارستان امام خمینی"}</h5>
                        </div>
                    </div>
                    : undefined
                }
                <h5 style={{ width: "100%" }}>
                    {confComps.panel.loginAndRegister.login.header.text}
                </h5>
                <EditText title={confComps.panel.loginAndRegister.login.txtEditText.userName.title}
                    styledTextField={{
                        border: "1px solid #fff",
                        borderRadius: "555px",
                        width: "100%",
                        color: colors.panel.doctor.account.step.edittext.color,
                        background: colors.panel.doctor.account.step.edittext.background,

                    }}
                    hintText={confComps.panel.loginAndRegister.login.txtEditText.userName.hintText}
                    sizeWidth="small"
                    onChangeValue={setEdittextUser}
                    isNecessary={false}
                    isMultiLine={false}
                    isSmallTitle={true}
                    isDataMustLtr={true}
                    isColorDark={false}
                    isSmallHintShow={false} />

                <EditText title={confComps.panel.loginAndRegister.login.txtEditText.password.title}
                    styledTextField={{
                        border: "1px solid #fff",
                        borderRadius: "555px",
                        width: "100%",
                        color: colors.panel.doctor.account.step.edittext.color,
                        background: colors.panel.doctor.account.step.edittext.background,

                    }}
                    hintText={confComps.panel.loginAndRegister.login.txtEditText.password.hintText}
                    sizeWidth="small"
                    onChangeValue={setEdittextPassword}
                    type="password"
                    isNecessary={false}
                    isMultiLine={false}
                    isSmallTitle={true}
                    isColorDark={false}
                    isDataMustLtr={true}
                    isSmallHintShow={false} />
                <div style={{
                    flexGrow: "1", display: "flex", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: "space-between", width: "100%",
                }}>
                    <FormControl
                        style={{
                            margin: "8px 0px 0px 0px",
                            color: colors.yellowHeader
                        }}
                        sx={{ m: 1, minWidth: "150px" }} >
                        <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.loginAndRegister.login.chooseRole.header}</LabelInputFromMUI>
                        <SelectFromMUI
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            style={{
                                color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                                borderRadius: "555px",

                            }}
                            // value={getSelectedItem}
                            label={confComps.panel.patient.doctorsList.category}
                            onChange={handleChangeDropDown}
                        >
                            {confComps.panel.loginAndRegister.login.chooseRole.roles.map((value, index, array) => {
                                return (
                                    <MenuItemFromMUI value={value.id}
                                        key={index}>{value.text}</MenuItemFromMUI>
                                )
                            })}
                        </SelectFromMUI>
                    </FormControl>
                    <ButtonEffectShadow text={confComps.panel.loginAndRegister.login.accept} onclick={onClickSubmitHandler} />
                    {/* <Button variant="contained" fullWidth={true}
                        onClick={onClickSubmitHandler}
                        style={{
                            // background: colors.screens.contactus.backgroundButtonSendMessage,
                            paddingTop: "3.5%",
                            paddingBottom: "3.5%",
                            marginTop: "5%",
                            borderRadius: "5px"
                        }}>
                        {confComps.panel.loginAndRegister.login.accept}
                    </Button> */}
                </div>


                <TextWithLink list={confComps.panel.loginAndRegister.login.textRedirect} />

            </DivAllTextContainer>


        </div>
    )
}

export default LoginPage;