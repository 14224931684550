import { Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import RowPatientsOfDoctorsMeeting from "./RowPatientsOfDoctorsMeeting";
import { interfacePatientsOfDoctorsMeeting } from "./interfacePatientsOfDoctorsMeeting";
import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import colors from "../../../../../config/colors";
import EditText from "../../../../../components/editText/EditText";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import { interfaceApiEntityDoctorsMeeting, interfaceRowListMeetingsOfDoctorsTomorBoard } from "../interfaceRowListMeetingsOfDoctorsTomorBoard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { useDispatch, useSelector } from "react-redux";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../api/API";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";

function PatientsOfDoctorsMeeting(componentsParams: interfacePatientsOfDoctorsMeeting) {
    const location = useLocation().state as interfaceApiEntityDoctorsMeeting;
    const { idMeeting } = useParams();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [getResponseThisMeeting, setResponseThisMeeting] = useState<interfaceApiEntityDoctorsMeeting>();
    const [getSearchStr, setSearchStr] = useState<(string)>("");
    const getApiThisMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.tomorboard.meeting.getlistAllMeetingOfMyDoctor;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceRowListMeetingsOfDoctorsTomorBoard;

                setResponseThisMeeting(t.data.data.filter(tempMeet => `${tempMeet.id}` == idMeeting)[0])
                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiThisMeeting();
    }, []);
    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            paddingBlock: "calc(1vh + 16px)"
        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap', rowGap: "calc(8px + 1vh)", width: "100%", flexDirection: "row", alignItems: "center",
                justifyContent: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between"
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", lineHeight: "3rem", fontWeight: "300" }}>
                    {" لیست پرونده‌های جلسه "}"{location.title}"
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
                paddingBlockEnd: "calc(1vh + 16px)",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {getResponseThisMeeting?.requests.filter((Tm, index, arra) => {
                        if (getSearchStr.length > 0 && Tm.doctor.first_name.indexOf(getSearchStr) && Tm.doctor.last_name.indexOf(getSearchStr) && Tm.patient.first_name.indexOf(getSearchStr) && Tm.patient.last_name.indexOf(getSearchStr)) {
                            return undefined
                        }
                        return Tm;
                    }).map((requestOfPatient, index, array) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                                <RowPatientsOfDoctorsMeeting
                                    serverDataOfRequest={requestOfPatient}
                                    tumorboard={getResponseThisMeeting.tumorboard}
                                    idMeeting={`${getResponseThisMeeting.id}`}
                                    callbackRefresh={getApiThisMeeting} />

                            </Grid>
                        )
                    })
                    }
                    
                    {(!getResponseThisMeeting || ((getResponseThisMeeting ?? { "requests": [] }).requests.length == 0)) ?
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                            <h4 style={{
                                color: colors.yellowHeader, width: "100%", textAlign: "center"
                                , marginTop: "20vh", borderRadius: "10px",
                                border: "1px solid red", paddingBlock: "3vh"
                            }}>
                                {"هیچ پرونده‌ای عضو این جلسه نیست!!"}
                            </h4>
                        </Grid> : undefined
                    }
                </GridFromMUI>
            </div>
        </div>
    )
}
export default PatientsOfDoctorsMeeting;

