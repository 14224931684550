import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, useTheme, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { styled as styledMUI } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AddRounded, CancelRounded, CheckRounded, CompressRounded, DownloadRounded, Padding, PlusOneRounded } from "@mui/icons-material";
import { margin } from "@mui/system";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { ButtonFromMUI } from "../../../../../components/MUIConfig/ButtonFromMUI";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceDialogSendRequestToTomorBoard, interfaceGetActiveTomorBoard } from "./interfaceDialogSendRequestToTomorBoard";
import colors from "../../../../../config/colors";
import EditText from "../../../../../components/editText/EditText";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { DialogFromMUI } from "../../../../../components/MUIConfig/DialogFromMUI";
import FilesDragAndDrop from "../../../../../components/FileDragAndDrop/FileDragAndDrop";
import {ImageSquare} from "../../../../../components/imageSquare/ImageSquare";
import RowOfTomor from "./rowOfTomor/RowOfTomor";





export const DialogSendRequestToTomorBoard = (paramsDialog: interfaceDialogSendRequestToTomorBoard) => {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getIdPatientOfSelected, setIdPatientOfSelected] = useState<number>(paramsDialog.idPatientOfSelected);
    const [getSearchStr, setSearchStr] = useState<(string)>("");
    const [getListOfTomorBoardFromServer, setListOfTomorBoardFromServer] = useState<interfaceGetActiveTomorBoard>();

    const handleClose = () => {
        setIdPatientOfSelected(-1);
        paramsDialog.callbackOnCloseDialog();
    };
    useEffect(() => {
        if (paramsDialog.idPatientOfSelected != -1) {
            getApiListOfTomorBoardFromServer()
        }
        setIdPatientOfSelected(paramsDialog.idPatientOfSelected)
    }, [paramsDialog.idPatientOfSelected])

    const getApiListOfTomorBoardFromServer = async () => {

        dispatch(isVisibilityProgressBar(true));


        const urlTarget = urlsList.panel.doctor.tomorboard.getActiveTomorboard
        await RequestHandler(dispatch, HandlerLangUrl(urlTarget.url, mTheme),
            urlTarget.method,
            urlTarget.isTokenNecessary, undefined, profileInStore, urlTarget.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                setListOfTomorBoardFromServer(response as interfaceGetActiveTomorBoard)

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "لیست توموربورد دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: Error) => {
                console.log("response:(");

                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr ?? (e as interfaceResponseErrorFromServer).data.errors.list[0].code} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }

    const handleOnclickCancelDialog = () => {
        paramsDialog.callbackOnCloseDialog();
    }
    return (
        <DialogFromMUI onClose={handleClose} open={getIdPatientOfSelected != -1} dir={mTheme.direction} background="#3E497A">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <h3 style={{ margin: "10px calc(16px + 2vw) 10px calc(16px + 2vw)", color: "white", padding: "0 0px" }}>
                    {"توموربورد"}
                </h3>
                <IconButton
                    onClick={handleOnclickCancelDialog}>
                    <CancelRounded />
                </IconButton>
            </div>
            <div style={{
                padding: "0 calc(16px + 2vw) 24px calc(16px + 2vw)", display: "flex", flexDirection: "column",
                gap: "calc(2vh + 12px)", justifyContent: "space-around", margin: "8px 12px", borderRadius: "12px", background: "#182546"
            }}>

                <div style={{
                    display: "flex", flexDirection: "column",
                    width: "100%", alignItems: "center", justifyContent: "space-between",
                    gap: "calc(1vw + 8px)"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "14px",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}

                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={true}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                    <div style={{
                        maxHeight: "70vh", overflowY: "auto",
                        width: "fit-content",
                        display: "flex", flexDirection: "column",
                        alignItems: "center", justifyContent: "space-between",
                        boxSizing: "border-box",
                        gap: "calc(1vh + 8px)"
                    }}>




                        {getListOfTomorBoardFromServer?.data.data.filter((t, index, arr) => {
                            if (getSearchStr.length > 0) {
                                if ((t.field.includes(getSearchStr) || t.name.includes(getSearchStr))) {
                                    return t
                                }
                                else {
                                    return undefined
                                }

                            }
                            return t;
                        }).map((tomor, index, array) => {
                            return (
                                <RowOfTomor
                                    id={tomor.id}
                                    name={tomor.name}
                                    field={tomor.field}
                                    description={tomor.description}
                                    status={tomor.status}
                                    secretary={tomor.secretary}
                                    hospital={tomor.hospital} meeting_place={tomor.meeting_place}
                                    idPatient={paramsDialog.idPatientOfSelected}
                                    callbackPostToTomorboardAccepted={paramsDialog.callbackOnCloseDialog} />
                            )
                        })}

                    </div>
                </div>
            </div>
        </DialogFromMUI>
    );
}
