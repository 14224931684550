import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { EnumGender } from "../../../utils/Gender";
import { EnumSupportRoles } from "../../../utils/Role";


export interface interfaceMyFilePage {
    typeOfDisplay: enumListSizeDevices;
    role: EnumSupportRoles;
}
export enum enumCategoryFilter {
    all = "all",
    picture = "picture",
    treatmentProcess = "treatmentProcess",
    comment = "comment",
    conversation = "conversation",
}
export interface interfaceGetAllTreatmentProcessesFromServer {
    success: boolean,
    data: {
        total_count: number,
        data: interfaceGetEntityAllTreatmentProcessesFromServer[]
    }
}
export interface interfaceGetEntityAllTreatmentProcessesFromServer {

    id: number;
    title: string;
    doctor?: {
        field: String;
        id: number;
        phone: String;
        first_name: String;
        last_name: String;
        profile_pic?: string;
        gender?: EnumGender;
        landline: String;
        province: String;
        city: String;
        address: String;
    },
    description: string;
    tags: string[];
    created_at: number;
    updated_at: number;

}
export interface interfaceGetAllCommentFromServer {
    success: boolean,
    data: {
        total_count: number,
        data: interfaceEntityAllCommentsFromServer[]
    }
}
export interface interfaceEntityAllCommentsFromServer {
    id: number;
    doctor?: {
        field: string;
        id: number;
        phone: string;
        first_name: string;
        last_name: string;
        profile_pic?: string;
        gender: EnumGender;
        landline: string;
        province: string;
        city: string;
        address: string;
    },
    meeting: {
        id: number;
        title: string;
        description: string;
        start_datetime: number;
        end_datetime: number;
        tumorboard: {
            id: number;
            name: string;
            field: string;
            description: string;
            status: string;
            hospital: string;
            meeting_place: string;
        }
    },
    comment: string;
    created_at: number;
    // updated_at": "2024-01-02T15:24:51.371480+03:30"
}
export interface interfaceGetAllMediaFromServer {
    success: boolean,
    data: {
        total_count: number,
        data: interfaceEntityOfMedicalRecordInServer[]
    }
}
export interface interfaceEntityOfMedicalRecordInServer {

    id: number;
    title: string;
    description: string;
    created_at: number;
    files: interfaceEntityOfFileInServer[];

}
export interface interfaceEntityOfFileInServer {
    id: number;
    file: string;
    created_at: number;
}