import {InterfaceTextsWithLinks} from "./interfaceTextsWithLinks";
import {TextField, Theme} from "@mui/material";
import colors from "../../config/colors";
import {Link} from "react-router-dom";
import {styled as styledMUI} from "@mui/material/styles";

const DivListAllItem = styledMUI("div")(({theme}) => ({
    marginBottom: "30px",
    display: "flex",
    flexDirection: "row",
    gap:"calc(0.2vw + 4px)"
}));
const PText = styledMUI("p")(({theme}) => ({
    whiteSpace: "nowrap",
}));
const PTextLinks = styledMUI("p")(({theme}) => ({
    whiteSpace: "nowrap",
}));
export const TextWithLink = (params: InterfaceTextsWithLinks) => {

    return (
        <DivListAllItem>
            {params.list.map((valueItem, index) => {
            return (
                <DivListAllItem key={index}>
                    <PText> {valueItem.text}</PText>
                    <Link to={valueItem.redirectOnclick} style={{marginLeft:"1%",marginRight:"1%",color:colors.yellowHeader}}>
                        <PTextLinks> {valueItem.textLink}</PTextLinks>
                    </Link>
                </DivListAllItem>
            )
        })}

        </DivListAllItem>
    );
}