import { useState } from "react";
import { baseUrl } from "../../../api/UrlsList";
import { ImageSquare } from "../../../components/imageSquare/ImageSquare";
import { DialogShowImage } from "../../../components/imageSquare/dialogShowImage/DialogShowImage";
import colors from "../../../config/colors";
import { interfaceCommitOfMyHistory } from "./interfaceCommitOfMyHistory";
import { ConvertNumberToPersian } from "../../../helper/ConvertNumberToPersian";

function CommitOfMyHistory(componentsParams: interfaceCommitOfMyHistory) {
    return (
        <div style={{
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
            width: "100%",
            boxSizing: "border-box"
        }}>
            <div
                key={`ff_${componentsParams.index}`}
                style={{
                    display: "flex", flexDirection: "row", alignItems: "center",
                    justifyContent: "start", gap: "calc(1vw + 12px)",
                    width: "100%",
                    boxSizing: "border-box",
                }}>
                <ImageSquare width={"60px"} border="1px solid white"
                    borderRadius="600px"
                    key={`ll_${componentsParams.index}`}
                    element={ConvertNumberToPersian(componentsParams.index)} background={colors.yellowHeader} />
                <h6 style={{ color: "white" }}>{componentsParams.title}</h6>
            </div>
            <div style={{
                display: "flex", flexDirection: "row", alignItems: "stretch", justifyContent: "stretch",
                gap: "calc(1vw + 12px)",
                width: "100%",
                boxSizing: "border-box",
            }}>
                <div style={{
                    flexBasis: "60px",
                    flexGrow: "0",
                    height: "auto",
                    display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"
                }}>
                    <div style={{ width: "1px", background: "white", height: "100%" }} />
                </div>
                <div style={{
                    display: "flex", flexDirection: "column",
                    flexBasis: "0",
                    flexGrow: "1",
                    flexWrap: "wrap", alignItems: "start", justifyContent: "start", gap: "calc(1vw + 12px)"
                }}>
                    <p style={{ color: "white", wordBreak: "break-word", textAlign: "start" }}>
                        {"توضیحات: "}{componentsParams.description}
                    </p>
                    <div style={{
                        display: "flex", flexDirection: "row",
                        flexWrap: "wrap", alignItems: "start", justifyContent: "start", gap: "calc(1vw + 12px)",
                        marginBottom: "calc(1vh + 48px)",
                    }}>
                        {
                            componentsParams.files.map((f, index, array) => {
                                return (
                                    <div>
                                        {/* <img
                                        src={`${baseUrl + "/" + f.file}`}
                                    /> */}
                                        <ImageSquare width={"calc(4vw + 60px)"} border="1px solid white"
                                            borderRadius="30px"
                                            key={`c23_${index}`}
                                            img={`${baseUrl + "/" + f.file}`}
                                            isScaleable={{}}

                                        />
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
export default CommitOfMyHistory;