import { AddRounded, EmailRounded, MeetingRoomRounded, MessageRounded, NoteRounded, Person2Rounded, SettingsRounded } from "@mui/icons-material";
import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import colors from "../../../../../config/colors";
import links from "../../../../../config/links";
import moment from "moment-jalaali";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceRowListMeetingsOfTomorBoard } from "./interfaceRowListMeetingsOfTomorBoard";

function RowListMeetingsOfTomorBoard(componentsParams: interfaceRowListMeetingsOfTomorBoard) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    // const [getRequestOfPatient, setRequestOfPatient] = useState<interfaceApiRequestOfPatient>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const { idTomorboard } = useParams();

    return (
        <div style={{
            width: "100%", background: colors.blue.darkbackground, borderRadius: "8px",
            border: `1px solid ${componentsParams.isactive ? "#FFFFFFeF" : "gray"}`,
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
            padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
        }}>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <h5 style={{ color: componentsParams.isactive ? "white" : colors.white.lightDark }}>{componentsParams.serverData.title}
                            {componentsParams.isactive ? undefined : ` (جلسه تمام شده است)`}
                        </h5>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>تعداد اعضا:{componentsParams.serverData.members.length}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"تعداد پرونده:"}{componentsParams.serverData.requests.length}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"شروع:"}{`${moment.unix(componentsParams.serverData.start_datetime).format("jYYYY/jM/jD HH:mm")}`}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"پایان:"}{`${moment.unix(componentsParams.serverData.end_datetime).format("jYYYY/jM/jD HH:mm")} `}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"توضیحات:"}{componentsParams.serverData.description}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            iconEnded={<SettingsRounded />}
                            colors={colors.panel.buttonYellowText}
                            text={"تنظیمات"}
                            onclick={() => {
                                navigate(links.secretary.tomorboard.meeting.configMeeting, { state: componentsParams })
                            }} />
                    </div>
                </Grid>
                {/* <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            iconEnded={<NoteRounded />}
                            colors={colors.panel.buttonTextGreen}
                            text={"صورت جلسه"}
                            onclick={() => {

                            }} />
                    </div>
                </Grid> */}
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            iconEnded={<EmailRounded />}
                            colors={colors.panel.buttonTextGreen}
                            text={"دعوت پزشک"}
                            onclick={() => {
                                navigate(links.secretary.tomorboard.meeting.inviteDoctor, { state: componentsParams })
                            }} />
                    </div>
                </Grid>


            </GridFromMUI>

        </div >
    )
}
export default RowListMeetingsOfTomorBoard;