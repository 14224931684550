import { useTheme } from "@emotion/react";
import { Person2Rounded, SaveRounded } from "@mui/icons-material";
import { Grid, FormControl, SelectChangeEvent } from "@mui/material";
import { useState, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../../components/MUIConfig/GridFromMUI";
import { LabelInputFromMUI } from "../../../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../../../../components/MUIConfig/SelectFromMUI";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import ButtonEffectShadow from "../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import EditText from "../../../../../../components/editText/EditText";
import { ImageSquare } from "../../../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import colors from "../../../../../../config/colors";
import links from "../../../../../../config/links";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { interfaceAppendCommentForPatient, locationDataOfAppendCommentForPatientFromPrevPage } from "./interfaceAppendCommentForPatient";
import { enumListSizeDevices } from "../../../../../../config/responsive/configResponsive";

function AppendCommentForPatient(pagesParams: interfaceAppendCommentForPatient) {
    const location = useLocation().state as locationDataOfAppendCommentForPatientFromPrevPage;
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getCommentForPatient, setCommentForPatient] = useState<string | undefined>();

    const deleteApiRequestFromMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.deleteRequestFromMeeting;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorBoard}/meetings/${location.idMeeting}/requests/${location.serverDataOfRequest.id}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(ShowAlert({ text: "درخواست از جلسه حذف شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                navigate(-1);
                // location.callbackRefresh();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const postApiAppendCommentForPatient = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.postCommentForPatient;
        const sendObj = {
            "patient_id": location.serverDataOfRequest.patient.id,
            "comment": getCommentForPatient,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorBoard}/meetings/${location.idMeeting}/comments/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, sendObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(ShowAlert({ text: "روند درمان اضافه شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                navigate(-1);
                // location.callbackRefresh();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApilastCommentForPatient = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getTheCommentForPatient;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorBoard}/meetings/${location.idMeeting}/patients/${location.serverDataOfRequest.patient.id}/comments/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                setCommentForPatient(response.data.comment)
                dispatch(ShowAlert({ text: "نظر توموربورد دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));



            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApilastCommentForPatient();
    }, [])
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            border: "1px solid #ffffffff",
            background: colors.blue.darkbackground,
            borderRadius: "32px",
            padding: "24px",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {"ثبت نظر توموربورد"}
                </h5>

            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>


                <GridFromMUI container
                    justifyContent="space-between" alignItems="stretch"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                            , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                        }}>
                            {
                                location.serverDataOfRequest.doctor.profile_pic ?
                                    <ImageSquare width={"4rem"} borderRadius="8px"
                                        border="1px solid #ccc"
                                        img={`${baseUrl}/` + location.serverDataOfRequest.doctor.profile_pic}
                                        isScaleable={{}}
                                    />
                                    :
                                    <ImageSquare iconSvg={Person2Rounded}
                                        width={"4rem"}
                                        color={colors.panel.doctor.patientsList.selector.color}
                                        fontsizeIcon="2rem"
                                        borderRadius="8px" border="1px solid white" />
                            }

                            <div style={{
                                display: "flex", flexDirection: "column", alignItems: "start",
                                justifyContent: "space-between",
                                color: "white",
                            }}>
                                <p>پزشک:{location.serverDataOfRequest.doctor.first_name},{location.serverDataOfRequest.doctor.last_name}</p>
                                <p style={{ color: colors.yellowHeader }}>شماره تماس:{location.serverDataOfRequest.doctor.phone}</p>

                            </div>

                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                            , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                        }}>
                            {
                                location.serverDataOfRequest.patient.profile_pic ?
                                    <ImageSquare width={"4rem"} borderRadius="8px"
                                        border="1px solid #ccc"
                                        isScaleable={{}}
                                        img={`${baseUrl}/` + location.serverDataOfRequest.patient.profile_pic}
                                    />
                                    :
                                    <ImageSquare iconSvg={Person2Rounded}
                                        width={"4rem"}
                                        color={colors.panel.doctor.patientsList.selector.color}
                                        fontsizeIcon="2rem"
                                        borderRadius="8px" border="1px solid white" />
                            }

                            <div style={{
                                display: "flex", flexDirection: "column", alignItems: "start",
                                justifyContent: "space-between",
                                color: "white",
                            }}>
                                <p>بیمار:{location.serverDataOfRequest.patient.first_name},{location.serverDataOfRequest.patient.last_name}</p>
                                <p style={{ color: colors.yellowHeader }}>شماره تماس:{location.serverDataOfRequest.patient.phone}</p>

                            </div>

                        </div>
                    </Grid>

                    {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}:
                        {location.description}
                    </p>
                </Grid> */}
                    {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid> */}
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <ButtonEffectShadow
                                styleMainDiv={{ width: "100%" }}
                                size="small"
                                colors={colors.panel.buttonYellowText}
                                onclick={() => {
                                    navigate(links.secretary.tomorboard.meeting.appendProcessForPatient, { state: location })
                                }}
                                text={"ثبت روند درمان"}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <ButtonEffectShadow
                                styleMainDiv={{ width: "100%" }}
                                size="small"
                                colors={colors.panel.buttonYellowText}
                                text={"ثبت نظر توموربورد"}
                                onclick={() => {
                                    navigate(links.secretary.tomorboard.meeting.appendCommentForPatient, { state: location })
                                }} />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <ButtonEffectShadow
                                styleMainDiv={{ width: "100%" }}
                                size="small"
                                colors={colors.panel.buttonOrangeText}
                                text={"حذف از جلسه"}
                                onclick={deleteApiRequestFromMeeting}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <ButtonEffectShadow
                                styleMainDiv={{ width: "100%" }}
                                size="small"
                                colors={colors.panel.buttonOrangeText}
                                text={"مشاهده پرونده"}
                                onclick={() => {
                                    navigate(links.secretary.patient.persentsFile + `/${location.serverDataOfRequest.patient.id}/`)
                                }} />
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", height: "2px", boxSizing: "border-box",
                            paddingInline: "calc(1vw + 8px)"
                        }} >
                            <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <EditText
                            title={"نظر توموربورد"}
                            onChangeValue={setCommentForPatient}
                            value={getCommentForPatient}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            type="text"
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>



                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
                        <div style={{ display: "flex", flexDirection: "row", marginBlock: "1vh", justifyContent: "end", alignItems: "center" }}>
                            <ButtonEffectShadow text={"ثبت"} iconEnded={<SaveRounded />}
                                onclick={postApiAppendCommentForPatient} />
                        </div>
                    </Grid>

                </GridFromMUI>

            </div >
        </div>
    )
}
export default AppendCommentForPatient;