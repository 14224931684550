import { Grid } from "@mui/material";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { interfaceAllImages } from "./interfaceAllImages";
import { baseUrl } from "../../../../api/UrlsList";
import EmptyList from "../../../../components/emptyList/EmptyList";
import colors from "../../../../config/colors";
import moment from "moment-jalaali";
import { ConvertNumberToPersian } from "../../../../helper/ConvertNumberToPersian";

function AllImages(componenetParams: interfaceAllImages) {
    var confComps = ConfigComps();
    return (
        <div style={{
            width: "100%", maxHeight: "80vh", flexGrow: "1", marginBottom: "12px", boxSizing: "border-box",
            overflowY: "auto",
            overflowX: "hidden",
        }}>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                columns={{ xs: 1, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {componenetParams.allImageFromServer?.data.data.map((Doctor, index, array) => {
                    return (

                        Doctor.files.map((F, index, array) => {
                            return (
                                <Grid item xs={1} sm={6} md={6} lg={4} xl={3} style={{}} key={index}>
                                    <div style={{
                                        width: "100%", display: "flex", flexDirection: "column",
                                        alignItems: "center", justifyContent: "start",
                                        background: colors.blue.darkbackground,
                                        borderRadius: "12px",
                                        padding: "1px",
                                        boxSizing: "border-box",
                                    }}>
                                        <ImageSquare
                                            isScaleable={{ title: Doctor.title, description: Doctor.description }}
                                            key={index}
                                            width={"100%"} border="1px solid #bbbbbb" borderRadius="12px" img={baseUrl + "/" + F.file} />
                                        <p style={{ width: "100%", color: "white", textAlign: "center" }}>
                                            {Doctor.title}
                                        </p>
                                        <p style={{ width: "100%", color: "white", textAlign: "center" }}>
                                            {ConvertNumberToPersian(`${moment.unix(Doctor.created_at).format("jYYYY/jM/jD")}`)}
                                        </p>
                                    </div>

                                </Grid>
                            )
                        })


                    )
                })
                }
            </GridFromMUI>
            {componenetParams.allImageFromServer?.data.data.map((Doctor, index, array) => {
                return (

                    Doctor.files.map((F, index, array) => {
                        return (
                            F
                        )
                    })


                )
            }).length == 0 ?

                <EmptyList text={"هیچ تصویری یافت نشد."} /> : undefined
            }
        </div>
    )
}
export default AllImages;