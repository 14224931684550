import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import EditText from "../../../../../../components/editText/EditText";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { interfaceServerGetDocotorsList } from "../../../settingTomorboard/interfaceSettingTomorboard";
import RowOfTomorboardsMember from "../../../settingTomorboard/rowOfTomorboardsMember/RowOfTomorboardsMember";
import { interfaceInviteDoctorToMeeting } from "./interfaceInviteDoctorToMeeting";
import colors from "../../../../../../config/colors";
import { interfaceRowListMeetingsOfTomorBoard } from "../interfaceRowListMeetingsOfTomorBoard";
import RowInviteDoctorToMeeting from "./RowInviteDoctorToMeeting";
import { interfaceApiGetListOfMeetingsTheTomorboardForAddPatient } from "../../meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";
import { interfaceGetApiTheMeeting } from "./interfaceRowInviteDoctorToMeeting";
import { enumListSizeDevices } from "../../../../../../config/responsive/configResponsive";

function InviteDoctorToMeeting(pagesParams: interfaceInviteDoctorToMeeting) {
    const location = useLocation().state as interfaceRowListMeetingsOfTomorBoard;
    const [getAllDoctorsList, setAllDoctorsList] = useState<interfaceServerGetDocotorsList>();
    const [getResTheMeeting, setResTheMeeting] = useState<interfaceGetApiTheMeeting>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const getApiTheMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getOneMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorboard}/meetings/${location.serverData.id}`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetApiTheMeeting;

                setResTheMeeting(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getDoctorListFromServer = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.doctor.getListAllDoctor;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceServerGetDocotorsList;

                setAllDoctorsList(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    function reCallAllApiForRefreshPage(): void {
        getDoctorListFromServer().then(() => {
            getApiTheMeeting()
        })
    }
    useEffect(() => {
        getApiTheMeeting();
        getDoctorListFromServer();
    }, []);
    useEffect(() => {
        setResTheMeeting({
            "success": true,
            "data": location.serverData,
        })
    }, [location.serverData]);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {"ارسال دعوت نامه"}
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start",
                    gap: "calc(1vh + 16px)"
                }}>

                    {
                        getResTheMeeting?.data.members.filter(doc =>
                            getSearchStr.length == 0 || doc.doctor.last_name.includes(getSearchStr) || doc.doctor.first_name.includes(getSearchStr) || (doc.doctor.phone ?? "").includes(getSearchStr)
                        ).map((doc, index, arr) => {
                            return (
                                <RowInviteDoctorToMeeting
                                    typeOfDisplay={pagesParams.typeOfDisplay}
                                    national_id={doc.doctor.national_id}
                                    phone={doc.doctor.phone}
                                    address={doc.doctor.address}
                                    email={doc.doctor.email}
                                    landline={doc.doctor.landline}
                                    province={doc.doctor.province}
                                    city={doc.doctor.city}
                                    id={doc.doctor.id}
                                    first_name={doc.doctor.first_name}
                                    last_name={doc.doctor.last_name}
                                    gender={doc.doctor.gender}

                                    idTomorBoard={Number(location.idTomorboard)}
                                    callbackRefresh={reCallAllApiForRefreshPage}
                                    isDoctorMember={true}
                                    idMeeting={`${location.serverData.id}`} />
                            )
                        })
                    }
                    {
                        getAllDoctorsList?.data.data.filter(doc =>
                            getSearchStr.length == 0 || doc.last_name.includes(getSearchStr) || doc.first_name.includes(getSearchStr) || (doc.phone ?? "").includes(getSearchStr)
                        )
                            .filter((emptyDoc, index, arr) => {
                                if (getResTheMeeting) {
                                    if (getResTheMeeting.data.members.filter((memberGroup) => memberGroup.doctor.id == emptyDoc.id).length >= 1) {
                                        return undefined;
                                    }
                                }
                                return emptyDoc;
                            })
                            .map((doc, index, arr) => {
                                return (
                                    <RowInviteDoctorToMeeting
                                        typeOfDisplay={pagesParams.typeOfDisplay}
                                        national_id={doc.national_id}
                                        phone={doc.phone}
                                        address={doc.address}
                                        email={doc.email}
                                        landline={doc.landline}
                                        province={doc.province}
                                        city={doc.city}
                                        id={doc.id}
                                        first_name={doc.first_name}
                                        last_name={doc.last_name}
                                        gender={doc.gender}
                                        idMeeting={`${location.serverData.id}`}

                                        idTomorBoard={Number(location.idTomorboard)}
                                        callbackRefresh={reCallAllApiForRefreshPage}
                                        isDoctorMember={false} />
                                )
                            })
                    }
                </div>
            </div>
        </div>

    )

}
export default InviteDoctorToMeeting;