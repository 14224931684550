import { useState } from "react";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import CardText from "./cardText/CardText";
import { interfaceSuggestionOneDoctor } from "./interfacSuggestionOneDoctor";
import colors from "../../../../../config/colors";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import EditText from "../../../../../components/editText/EditText";
import { SearchRounded } from "@mui/icons-material";

function SuggestionOneDoctor(paramsPage: interfaceSuggestionOneDoctor) {
    const [getTextSearchBar, setTextSearchBar] = useState<string>("")
    var confComps = ConfigComps();
    return (
        <div style={{
            width: "100%", overflowY: "auto",
            display: "flex", flexDirection: "column",
            maxHeight: "80vh",
            boxSizing: "border-box",
            borderRadius: "24px",
            background: colors.blue.blackBackground,
            border: "1px solid #fff",
            alignItems: "center", justifyContent: "space-between", gap: "calc(3vh + 12px)"
        }}>
            <div style={{
                background: colors.blue.dark,
                boxSizing: "border-box",
                padding: "calc(1vh + 8px) calc(1vw + 8px)",
                width: "100%",
                position: "sticky",
                top: 0,
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
            }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "calc(8px + 1vw)", alignItems: "center", justifyContent: "center" }}>
                    {/* <ImageSquare width={"calc(4vw + 50px)"} borderRadius="8px" 
                    img={confComps.panel.patient.myFilePage.tempSuggestions[0].squareImagesLinks} /> */}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start" }}>
                        <h5 style={{ color: "white" }}>{"lllllllllllllllllll"}</h5>
                        <p style={{ color: colors.yellowHeader }}>{`کل پیام ها ${confComps.panel.patient.myFilePage.tempOneDoctorSuggestion.length}`}</p>
                    </div>
                </div>
                <div>
                    <EditText title={""}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        InputProps={{
                            startAdornment: <SearchRounded style={{ color: colors.yellowHeader }} />,
                        }}
                        sizeWidth="small"
                        onChangeValue={function (value: string) {
                            setTextSearchBar(value)
                        }}
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />
                </div>
            </div>
            {confComps.panel.patient.myFilePage.tempOneDoctorSuggestion.map((sug, index, array) => {
                return (
                    <CardText text={sug} findText={getTextSearchBar} />
                )
            })}
        </div>
    )
}
export default SuggestionOneDoctor;