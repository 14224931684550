import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import colors from "../../../../config/colors";

import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import links from "../../../../config/links";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceRowDoctorTomorboard } from "./interfaceRowDoctorTomorboardsList";

function RowDoctorTomorboardsList(componentsParams: interfaceRowDoctorTomorboard) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    return (
        <div style={{
            width: "100%", background: colors.blue.darkbackground, borderRadius: "32px", border: "1px solid #10107e1f",
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
            padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
        }}>
            <h5 style={{ color: "white" }}>{componentsParams.name}</h5>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.field}{": "}
                        {componentsParams.field}
                    </p>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.hospital}{": "}
                        {componentsParams.hospital}
                    </p>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.meeting_place}{": "}
                        {componentsParams.meeting_place}
                    </p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}{": "}
                        {componentsParams.description}
                    </p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ height: "24px" }}></div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%" }}>
                        <h6 style={{ color: "white" }}>{"اطلاعات دبیر"}</h6>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {"نام دبیر"}{": "}
                        {componentsParams.secretary.first_name}{" "}{componentsParams.secretary.last_name}
                    </p>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {"شماره موبایل"}{": "}
                        {componentsParams.secretary.phone}
                    </p>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {"شماره تماس"}{": "}
                        {componentsParams.secretary.landline}
                    </p>
                </Grid>

            </GridFromMUI>

        </div >
    )
}
export default RowDoctorTomorboardsList;