import { CheckRounded } from "@mui/icons-material";
import { ImageSquare } from "../../../../../../components/imageSquare/ImageSquare";
import colors from "../../../../../../config/colors";
import { interfaceRowOfTomor } from "./interfaceRowOfTomor";
import { GridFromMUI } from "../../../../../../components/MUIConfig/GridFromMUI";
import { Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import ButtonEffectShadow from "../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { interfaceGetActiveTomorBoard } from "../interfaceDialogSendRequestToTomorBoard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";

function RowOfTomor(componentsParams: interfaceRowOfTomor) {
    const confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const postApiListOfTomorBoardFromServer = async () => {
        const obj = {
            "tumorboard": componentsParams.id,
            "patient": componentsParams.idPatient,
            "title": "",
            "description": ""
        }
        dispatch(isVisibilityProgressBar(true));


        const urlTarget = urlsList.panel.doctor.tomorboard.postMakeRequestToTomorboard
        await RequestHandler(dispatch, HandlerLangUrl(urlTarget.url, mTheme),
            urlTarget.method,
            urlTarget.isTokenNecessary, obj, profileInStore, urlTarget.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "با موفقیت ثبت شد.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                componentsParams.callbackPostToTomorboardAccepted()

            })
            .catch((e: Error) => {
                console.log("response:(");

                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr ?? (e as interfaceResponseErrorFromServer).data.errors.list[0].code} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    return (
        <div style={{
            border: "1px solid white",
            paddingInline: "calc(0.5vw + 8px)",
            paddingBlockEnd: "12px",
            borderRadius: "8px",
            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
            boxSizing: "border-box",
            gap: "calc(2vh + 12px)",
            background: "#3E497A"
        }}>
            <div style={{
                display: "flex", flexDirection: "column",
                alignItems: "start", justifyContent: "start", gap: "calc(1cw + 12px)"
            }}>
                <h6 style={{
                    color: "white",
                    display: "flex", flexDirection: "row", flexWrap: 'nowrap', alignItems: "center", justifyContent: "start",
                    marginBlock: "calc(2vh + 8px)",
                }}>
                    {componentsParams.name}
                    {componentsParams.field.length > 0 ? <p style={{marginInlineStart:"4px"}}>({componentsParams.field})</p> : undefined}
                </h6>
                <div style={{
                    display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "space-between",
                    marginInline: "8px"
                }}>
                    <GridFromMUI container

                        justifyContent="space-between" alignItems="flex-start"

                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {confComps.panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.location}
                                {": "}
                                {componentsParams.hospital}
                                {"، "}
                                {componentsParams.meeting_place}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {confComps.panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.secretaryName}
                                {": "}
                                {componentsParams.secretary.first_name}
                                {" "}
                                {componentsParams.secretary.last_name}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <p style={{ color: "#cccccc" }}>{componentsParams.description}</p>

                        </Grid>
                    </GridFromMUI>
                    <ButtonEffectShadow text={confComps.panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.buttonAccept}
                        onclick={postApiListOfTomorBoardFromServer} />
                </div>



            </div>


        </div>
    )
}
export default RowOfTomor;