import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../config/ConfigCOMP";
import colors from "../../../config/colors";
import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { EnumGender } from "../../../utils/Gender";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { Grid } from "@mui/material";
import EditText from "../../../components/editText/EditText";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { interfaceSecretaryAccount } from "./interfaceSecretaryAccount";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";

const DivLevel = styledMUI("div", { shouldForwardProp: (prop) => prop !== 'active', })<{ active?: boolean; }>(({
    theme,
    active
}) => ({
    cursor: active ? "pointer" : undefined,
    width: "33.34%",
    textAlign: "center",
    borderBottom: `3px solid ${active ? colors.panel.doctor.account.title.active : colors.panel.doctor.account.title.deactive}`,
    color: `${active ? colors.panel.doctor.account.title.active : colors.panel.doctor.account.title.deactive}`
}));
function SecretaryChangePassword(paramsPage: interfaceSecretaryAccount) {

    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getOldPassword, setOldPassword] = useState<string>();
    const [getPassword, setPassword] = useState<string>();
    const [getRePassword, setRePassword] = useState<string>();
    const handleUpdatePassword = async () => {
        if ((String(getPassword) != String(getRePassword)) || ((getPassword ?? "").length == 0)) {
            dispatch(ShowAlert({ text: "ساختار جدید درست نیست!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        const objData = {
            "old_password": getOldPassword,
            "new_password": getPassword
        }
        let urlObj = urlsList.panel.doctor.account.ChangePassword;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, objData, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);


                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            paddingBlock: "calc(1vh + 16px)"
        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
                marginBlockEnd: "calc(1vh + 16px)"
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", lineHeight: "3rem", fontWeight: "300" }}>
                    {"تغییر رمزعبور"}
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>

                </div>
            </div>

            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.passwordUpdate.oldPassword}
                        onChangeValue={setOldPassword}
                        value={getOldPassword}
                        type="password"
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            boxSizing:"border-box",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.passwordUpdate.password}
                        onChangeValue={setPassword}
                        value={getPassword}
                        type="password"
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.doctor.account.passwordUpdate.rePassword}
                        onChangeValue={setRePassword}
                        value={getRePassword}
                        type="password"
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />



                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                        <ButtonEffectShadow text={"به روزرسانی"} onclick={handleUpdatePassword} />
                    </div>
                </Grid>
            </GridFromMUI>
        </div>


    )
}
export default SecretaryChangePassword;
