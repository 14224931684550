import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import EditText from "../../../../../../../components/editText/EditText";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import colors from "../../../../../../../config/colors";

import EmptyList from "../../../../../../../components/emptyList/EmptyList";
import { interfaceAddPatientsInRequestList } from "./interfaceAddPatientsInRequestList";
import { interfaceGetOneTomorboardFromServer } from "../../../../settingTomorboard/interfaceSettingTomorboard";
import { InterfaceGetMyPatientsListServer } from "../../../../../patients/patientsList/interfacePatientsList";
import RowAddPatient from "./RowAddPatient";
import ButtonEffectShadow from "../../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import links from "../../../../../../../config/links";
import { ArrowBackRounded, ListRounded, SettingsRounded } from "@mui/icons-material";
import { interfaceRowListMeetingsOfTomorBoard } from "../../interfaceRowListMeetingsOfTomorBoard";
import { interfaceApiEntityMeeting } from "../../../meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";
import moment from "moment-jalaali";
import { ConvertNumberToPersian } from "../../../../../../../helper/ConvertNumberToPersian";
function AddPatientsInRequestList(pagesParams: interfaceAddPatientsInRequestList) {
    const location = useLocation().state as interfaceRowListMeetingsOfTomorBoard;
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getTomor, setTomorboard] = useState<interfaceGetOneTomorboardFromServer>();
    const [getPatientsList, setPatientsList] = useState<InterfaceGetMyPatientsListServer>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const [getResInformationOfTheMeeting, setResInformationOfTheMeeting] = useState<interfaceApiEntityMeeting>();
    const getApiThisTomorBoard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.getDataOfOneTomorboard;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetOneTomorboardFromServer;

                setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiInformationOfTheMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getOneMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorboard}/meetings/${location.serverData.id}`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.data as interfaceApiEntityMeeting;

                setResInformationOfTheMeeting(t);
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }


    const getPatientsListFromServer = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.patient.getListAllPatients;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetMyPatientsListServer;
                setPatientsList(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getPatientsListFromServer().then(
            () => { getApiThisTomorBoard().then(getApiInformationOfTheMeeting) });
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {"اضافه کردن بیمار به جلسه"}{": "}{getResInformationOfTheMeeting?.title}
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <div style={{
                    width: "100%", background: colors.blue.darkbackground, borderRadius: "32px", border: "1px solid #10107e1f",
                    display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
                    padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
                }}>
                    <h5 style={{ color: "white" }}>{getTomor?.data.name}</h5>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.field}{": "}
                                {getTomor?.data.field}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.hospital}{": "}
                                {getTomor?.data.hospital}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.meeting_place}{": "}
                                {getTomor?.data.meeting_place}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}{": "}
                                {getTomor?.data.description}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", height: "2px", boxSizing: "border-box",
                                paddingInline: "calc(1vw + 8px)"
                            }} >
                                <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {"زمان شروع جلسه"}{": "}
                                {getResInformationOfTheMeeting?.start_datetime ? ConvertNumberToPersian(`${moment.unix(getResInformationOfTheMeeting.start_datetime).format("jYYYY/jM/jD HH:mm")}`) : undefined}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {"زمان پایان جلسه"}{": "}
                                {getResInformationOfTheMeeting?.end_datetime ? ConvertNumberToPersian(`${moment.unix(getResInformationOfTheMeeting.end_datetime).format("jYYYY/jM/jD HH:mm")}`) : undefined}

                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {"تعداد درخواست‌ها"}{": "}
                                {ConvertNumberToPersian(`${getResInformationOfTheMeeting?.requests.length}`)}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <p style={{ color: colors.panel.doctor.account.step.header }}>
                                {"تعداد اعضا"}{": "}
                                {ConvertNumberToPersian(`${getResInformationOfTheMeeting?.members.length}`)}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", height: "2px", boxSizing: "border-box",
                                paddingInline: "calc(1vw + 8px)"
                            }} >
                                <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                <ButtonEffectShadow
                                    styleMainDiv={{ width: "100%" }}
                                    size="small"
                                    colors={colors.panel.buttonYellowText}
                                    onclick={() => {
                                        navigate(links.secretary.tomorboard.configOneTomorboard + `/${getTomor?.data.id}/`)
                                    }}
                                    iconEnded={<SettingsRounded />}
                                    text={"ویرایش اطلاعات توموربورد"} />
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                <ButtonEffectShadow
                                    styleMainDiv={{ width: "100%" }}
                                    size="small"
                                    colors={colors.panel.buttonYellowText}
                                    onclick={() => {
                                        navigate(links.secretary.tomorboard.meeting.listMeetingOfTomorboard + `/${getTomor?.data.id}/`)
                                    }}
                                    text={"لیست جلسات توموربورد"}
                                    iconEnded={<ListRounded />} />
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <GridFromMUI container
                                justifyContent="space-between" alignItems="flex-start"
                                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                                rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                                {getPatientsList?.data.data.filter((Tm, index, arra) => {
                                    var txtSearch = getSearchStr.trim().replace(" ", "");
                                    if (txtSearch.length <= 0 || (`${Tm.first_name}${Tm.last_name}`.includes(txtSearch) || Tm.phone.includes(txtSearch) || (Tm.national_id ?? "").includes(txtSearch))) {
                                        return Tm
                                    };
                                    return undefined;
                                }).map((patient, index, array) => {
                                    return (
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <RowAddPatient
                                                callbackReload={
                                                    () => {
                                                        getPatientsListFromServer().then(
                                                            () => { getApiThisTomorBoard().then(getApiInformationOfTheMeeting) });
                                                    }
                                                }
                                                idPatient={patient.id}
                                                name={patient.first_name}
                                                family={patient.last_name}
                                                phone={patient.phone}
                                                landline={patient.landline}
                                                address={patient.address}
                                                typeOfDisplay={pagesParams.typeOfDisplay}
                                                img={patient.profile_pic}
                                                national_id={patient.national_id}
                                                idMeeting={`${location.serverData.id}`}
                                                idTumorBoard={location.idTomorboard} />
                                        </Grid>
                                    )
                                })
                                }
                            </GridFromMUI>
                            {
                                getPatientsList?.data.data.filter((Tm, index, arra) => {
                                    var txtSearch = getSearchStr.trim().replace(" ", "");
                                    if (txtSearch.length <= 0 || (`${Tm.first_name}${Tm.last_name}`.includes(txtSearch) || Tm.phone.includes(txtSearch) || (Tm.national_id ?? "").includes(txtSearch))) {
                                        return Tm
                                    };
                                    return undefined;
                                }).length == 0 ?
                                    <EmptyList text={"هیچ توموربوردی یافت نشد"} /> : undefined
                            }

                        </Grid>

                    </GridFromMUI>

                </div>

            </div>
        </div>
    )
}
export default AddPatientsInRequestList;