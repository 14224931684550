import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import colors from "../../../../config/colors";
import { interfaceRowTomorboard } from "./interfaceRowTomorboardsList";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import links from "../../../../config/links";
import { ArrowBack, ArrowBackRounded } from "@mui/icons-material";
import { interfaceRowListMeetingsOfTomorBoard } from "./listMeetings/interfaceRowListMeetingsOfTomorBoard";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceApiGetListOfMeetingsTheTomorboardForAddPatient } from "./meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";

function RowTomorboardsList(componentsParams: interfaceRowTomorboard) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const getApiListMeetingOfTheTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getlistOfMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${componentsParams.id}/` + "meetings/?page=1&page_size=1000", mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiGetListOfMeetingsTheTomorboardForAddPatient;
                if (t.data.data.length == 0) {
                    dispatch(ShowAlert({ text: "هیچ جلسه‌ای ایجاد نشده است!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                let lastMeet = t.data.data.sort((a, b) => {
                    return Math.abs(a.start_datetime - (Date.now()/1000)) - Math.abs(b.start_datetime - (Date.now()/1000))
                })[0]
                navigate(links.secretary.tomorboard.meeting.configMeeting, {
                    state:
                        ({
                            "serverData": lastMeet,
                            "idTomorboard": `${componentsParams.id}`,
                        } as interfaceRowListMeetingsOfTomorBoard)
                })
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%", background: colors.blue.darkbackground, borderRadius: "32px", border: "1px solid #10107e1f",
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
            padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
        }}>
            <h5 style={{ color: "white" }}>{componentsParams.name}</h5>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.field}{": "}
                        {componentsParams.field}
                    </p>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.hospital}{": "}
                        {componentsParams.hospital}
                    </p>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.meeting_place}{": "}
                        {componentsParams.meeting_place}
                    </p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}{": "}
                        {componentsParams.description}
                    </p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            onclick={() => {
                                navigate(links.secretary.tomorboard.configOneTomorboard + `/${componentsParams.id}/`)
                            }}
                            text={confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.edit} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            onclick={() => {
                                navigate(links.secretary.tomorboard.meeting.listMeetingOfTomorboard + `/${componentsParams.id}/`)
                            }}
                            text={confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.section} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonOrangeText}
                            text={confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.manageRequests}
                            onclick={() => {
                                navigate(links.secretary.tomorboard.tomorboardsList + `/${componentsParams.id}` + links.secretary.tomorboard.requestPatientList2th)
                            }} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonOrangeText}
                            iconEnded={<ArrowBackRounded />}
                            onclick={getApiListMeetingOfTheTomorboard}
                            text={"رفتن به نزدیک‌ترین جلسه"} />
                    </div>
                </Grid>
            </GridFromMUI>

        </div>
    )
}
export default RowTomorboardsList;