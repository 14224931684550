import { TextField, Theme } from "@mui/material";
import colors from "../../config/colors";
import { useTheme } from "@mui/material/styles";
import { InterfaceDateTimePickMe } from "./interfaceDateTimePickMe";
import { styled as styledMUI } from "@mui/material/styles";
import { TextFieldFromMUI } from "../MUIConfig/TextFieldFromMUI";
import { useEffect, useState } from "react";
import { SearchRounded } from "@mui/icons-material";
import { DateTimePickerFromMUI } from "../MUIConfig/DateTimePickerFromMUI";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { randomInt } from "crypto";


const DivEditTextWithTitle = styledMUI("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    width: "100%",
}));

export const PText = styledMUI("p", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            fontSize: "1.3rem",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});
export const H6Text = styledMUI("h6", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            marginBlockStart: "5%",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});

export const DivListAllItem = styledMUI("div")(({ theme }) => ({
    display: "flex",
    boxSizing: "border-box",
    paddingInlineStart: "20px",
    flexDirection: "row",
}));
function DateTimePickMe(paramsComponent: InterfaceDateTimePickMe) {
    const [getDefultTime, setDefultTime] = useState<number | undefined>(paramsComponent.valueSecound);
    useEffect(() => {

        setDefultTime(paramsComponent.valueSecound ? (paramsComponent.valueSecound) : undefined)
    }, [paramsComponent.valueSecound])
    useEffect(() => {
        console.log("safaaaajonnnn", getDefultTime)
    }, [getDefultTime])
    const mainTheme = useTheme();
    return (
        <DivEditTextWithTitle>
            <DivListAllItem>
                {paramsComponent.isSmallTitle ? <PText isColorDark={paramsComponent.isColorDark ?? true}>{paramsComponent.title}</PText> : <H6Text isColorDark={paramsComponent.isColorDark ?? true}>{paramsComponent.title}</H6Text>

                }
                {paramsComponent.isNecessary ? paramsComponent.isSmallTitle ? <PText isColorDark={paramsComponent.isColorDark ?? true}>*</PText> : <H6Text isColorDark={paramsComponent.isColorDark ?? true}>*</H6Text>
                    : undefined}

            </DivListAllItem>
            <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                <DateTimePickerFromMUI
                    isHintTextCenter={false}
                    isValueMustLtr={paramsComponent.isDataMustLtr ?? false}
                    label={paramsComponent.hintText}
                    onChange={(e: any) => paramsComponent.onChange(e)}
                    value={getDefultTime}
                    disabled={paramsComponent.disable}
                    backgroundColor={paramsComponent.backGroundColor}
                    isSmallHintShow={paramsComponent.isSmallHintShow}
                    styleMain={paramsComponent.styleMain} />
            </LocalizationProvider>
        </DivEditTextWithTitle>
    )
}

export default DateTimePickMe;
