import { useEffect, useState } from "react";
import EmptyList from "../../../../components/emptyList/EmptyList";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { interfaceAllConversation, interfaceGetAllConversationFromServer } from "./interfaceAllConversation";
import BigCardConversation from "./cardConversation/BigCardConversation";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceGetTomorboardsListFromServer } from "../../../secretary/tomorboard/tomorboardsList/interfaceTomorboardsList";


function AllConversation(componentParams: interfaceAllConversation) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getGetAllConversationFromServer, setGetAllConversationFromServer] = useState<interfaceGetAllConversationFromServer>();
    const getAllConversationRecordFromServer = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.patient.getAllConversationRecordOfMyPatient;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${componentParams.idPatient}/medical-discussions/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetAllConversationFromServer;

                setGetAllConversationFromServer(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getAllConversationRecordFromServer();
    }, [componentParams.lastPosixTimeForUpdate]);
    return (
        <div style={{
            width: "100%", maxHeight: "80vh",
            overflowY: "auto",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: "calc(3vh + 8px)"
        }}>
            {getGetAllConversationFromServer?.data.data.map((conversation, index, array) => {
                return (
                    <BigCardConversation
                        conversationRow={conversation}
                    />
                )
            })
            }
            {getGetAllConversationFromServer?.data.data.length == 0 ?
                <EmptyList text={"هیچ نظر دکتری برای این بیمار وجود ندارد."} /> : undefined

            }
        </div>
    )
}
export default AllConversation;