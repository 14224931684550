import links from "./links";
import {
    AddCircleOutlineRounded,
    AutoGraphRounded,
    BarChartOutlined, CallRounded, DateRangeOutlined, DesignServicesRounded,
    DiamondRounded,
    DiamondSharp,
    Diversity1Rounded,
    Diversity3Outlined, Diversity3Rounded, EmojiEventsOutlined,
    ExtensionRounded,
    FlagRounded,
    FlagSharp,
    FolderCopyOutlined,
    FolderSharedRounded,
    FolderSharp, GroupRounded,
    GroupSharp, HomeRounded,
    KeyRounded,
    KeySharp,
    LibraryBooksRounded,
    PasswordRounded,
    PersonRounded,
    RequestPageRounded,
    RollerShadesRounded,
    RollerShadesSharp,
    SettingsRounded,
    SettingsSharp,
    SettingsVoiceOutlined,
    SpeedOutlined,
    SupervisorAccountRounded,
    SvgIconComponent,
    TextIncreaseRounded,
    ThermostatRounded,
    TimerOutlined,
    TimerSharp,
    TuneOutlined, Warning, WebRounded, WorkHistoryRounded
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const ConfigComps = () => {
    const { t, i18n } = useTranslation();

    return (
        {
            pageNotFound: t('pageNotFound'),
            unit: {
                enum: {
                    gender: [
                        {
                            key: "M",
                            value: t('unit.enum.gender.0.value')
                        },
                        {
                            key: "F",
                            value: t('unit.enum.gender.1.value')
                        },
                        {
                            key: "O",
                            value: t('unit.enum.gender.2.value')
                        },
                    ],
                    week: [
                        {
                            key: "Saturday",
                            value: "شنبه"
                        },
                        {
                            key: "Sunday",
                            value: "یکشنبه"
                        },
                        {
                            key: "Monday",
                            value: "دوشنبه"
                        },
                        {
                            key: "Tuesday",
                            value: "سه‌شنبه"
                        },
                        {
                            key: "Wednesday",
                            value: "چهارشنبه"
                        },
                        {
                            key: "Thursday",
                            value: "پنج‌شنبه"
                        },
                        {
                            key: "Friday",
                            value: "جمعه"
                        },

                    ],
                },
                categoryRequestDoctorToTomorboard: [
                    { id: 0, text: "همه", value: "all" },
                    { id: 1, text: "تایید شده", value: "AP" },
                    { id: 2, text: "در انتظار", value: "PE" },
                    { id: 3, text: "رد شده", value: "RJ" }
                ],
            },


            panel: {
                authentication: {
                    login: {
                        header: t(''),
                        edittextUser: t(''),
                        edittextPassword: t(''),
                        dropDown: {}

                    }
                },
                appBar: {
                    sideBar: {
                        exit: t('panel.appBar.sideBar.exit'),
                    },
                },
                loginAndRegister: {
                    login: {
                        chooseRole: {
                            header: t('panel.loginAndRegister.login.chooseRole.header'),
                            roles: [
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.0.text'),
                                    id: 0
                                },
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.1.text'),
                                    id: 1
                                },
                                {
                                    text: t('panel.loginAndRegister.login.chooseRole.roles.2.text'),
                                    id: 2
                                },

                            ]
                        },
                        header: {
                            text: t('panel.loginAndRegister.login.header.text')
                        },
                        textRedirect: [
                            {
                                text: t('panel.loginAndRegister.login.textRedirect.0.text'),
                                textLink: t('panel.loginAndRegister.login.textRedirect.0.textLink'),
                                redirectOnclick: links.loginAndRegister.register,
                            },
                            {
                                text: t('panel.loginAndRegister.login.textRedirect.1.text'),
                                textLink: t('panel.loginAndRegister.login.textRedirect.1.textLink'),
                                redirectOnclick: links.loginAndRegister.register,
                            }
                        ],
                        txtEditText: {
                            userName: {
                                title: t('panel.loginAndRegister.login.txtEditText.userName.title'),
                                isNecessary: true,
                                hintText: t('panel.loginAndRegister.login.txtEditText.userName.hintText'),
                                isMultiLine: false,
                            },
                            password: {
                                title: t('panel.loginAndRegister.login.txtEditText.password.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                        },
                        accept: t('panel.loginAndRegister.login.accept'),
                        notification: {
                            success: {
                                userAndPassTrue: "با موفقیت دریافت شد.",
                            },
                            error: {
                                userAndPassError: t('panel.loginAndRegister.login.notification.error.userAndPassError'),
                            }
                        }
                    },
                    register: {
                        chooseRole: {
                            header: t('panel.loginAndRegister.register.chooseRole.header'),
                            roles: [
                                {
                                    text: t('panel.loginAndRegister.register.chooseRole.roles.0.text'),
                                    id: 0
                                },
                                {
                                    text: t('panel.loginAndRegister.register.chooseRole.roles.1.text'),
                                    id: 1
                                },

                            ]
                        },
                        header: {
                            text: t('panel.loginAndRegister.register.header.text')
                        },
                        textRedirect: [
                            {
                                text: t('panel.loginAndRegister.register.textRedirect.0.text'),
                                textLink: t('panel.loginAndRegister.register.textRedirect.0.textLink'),
                                redirectOnclick: links.loginAndRegister.login,
                            }
                        ],
                        txtEditText: {
                            phone: {
                                title: t('panel.loginAndRegister.register.txtEditText.phone.title'),
                                isNecessary: true,
                                hintText: t('panel.loginAndRegister.register.txtEditText.phone.hintText'),
                                isMultiLine: false,
                            },
                            otp: {
                                title: t('panel.loginAndRegister.register.txtEditText.otp.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                            password: {
                                title: t('panel.loginAndRegister.register.txtEditText.password.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                            rePassword: {
                                title: t('panel.loginAndRegister.register.txtEditText.rePassword.title'),
                                isNecessary: true,
                                hintText: "*******",
                                isMultiLine: false,
                            },
                        },
                        acceptPhone: t('panel.loginAndRegister.register.acceptPhone'),
                        acceptOTP: t('panel.loginAndRegister.register.acceptOTP'),
                        acceptPassword: t('panel.loginAndRegister.register.acceptPassword'),
                        notification: {
                            success: {
                                phoneNumber: t('panel.loginAndRegister.register.notification.success.phoneNumber'),
                                OTP: t('panel.loginAndRegister.register.notification.success.OTP'),
                                passwordSaved: t('panel.loginAndRegister.register.notification.success.passwordSaved'),
                            },
                            error: {
                                phoneNumber: t('panel.loginAndRegister.register.notification.error.phoneNumber'),
                                OTP: t('panel.loginAndRegister.register.notification.error.OTP'),
                                confirmPasswordNotMatch: t('panel.loginAndRegister.register.notification.error.confirmPasswordNotMatch'),
                            },
                            warning: {
                                passwordIsSimple: t('panel.loginAndRegister.register.notification.warning.passwordIsSimple'),
                            }
                        }
                    }
                },
                manager: {

                },
                secretary: {
                    sideBar: {
                        sections: [
                            {
                                title: t('panel.secretary.sideBar.sections.0.title'),
                                subtitles: [
                                    {
                                        link: links.secretary.doctor.doctorsList,
                                        text: t('panel.secretary.sideBar.sections.0.subtitles.0.text'),
                                        icon: Diversity1Rounded
                                    }
                                ]

                            },
                            {
                                title: t('panel.secretary.sideBar.sections.1.title'),
                                subtitles: [
                                    {
                                        link: links.secretary.patient.patientList,
                                        text: t('panel.secretary.sideBar.sections.1.subtitles.0.text'),
                                        icon: ThermostatRounded
                                    },
                                    {
                                        link: links.secretary.patient.create,
                                        text: t('panel.secretary.sideBar.sections.1.subtitles.1.text'),
                                        icon: AddCircleOutlineRounded
                                    }
                                ]

                            },
                            {
                                title: t('panel.secretary.sideBar.sections.2.title'),
                                subtitles: [
                                    {
                                        link: links.secretary.tomorboard.tomorboardsList,
                                        text: t('panel.secretary.sideBar.sections.2.subtitles.0.text'),
                                        icon: Diversity3Rounded
                                    },
                                    // {
                                    //     link: links.secretary.tomorboard.calendar,
                                    //     text: t('panel.secretary.sideBar.sections.2.subtitles.1.text'),
                                    //     icon: GroupRounded
                                    // }

                                ]

                            },
                            {
                                title: t('panel.secretary.sideBar.sections.3.title'),
                                subtitles: [
                                    {
                                        link: links.secretary.account.changePassword,
                                        text: t('panel.secretary.sideBar.sections.3.subtitles.1.text'),
                                        icon: KeyRounded
                                    }

                                ]

                            }

                        ]
                    },
                    doctors: {
                        doctorsList: {
                            header: t('panel.secretary.doctors.doctorsList.header'),
                            search: t('panel.secretary.doctors.doctorsList.search'),
                            doctorRow: {
                                buttomShowProfile: {
                                    text: t('panel.secretary.doctors.doctorsList.doctorRow.buttomShowProfile.text'),
                                    fill: true,
                                },
                                national_id: t('panel.secretary.doctors.doctorsList.doctorRow.national_id'),
                                department: t('panel.secretary.doctors.doctorsList.doctorRow.department'),
                                phone: t('panel.secretary.doctors.doctorsList.doctorRow.phone'),
                                address: t('panel.secretary.doctors.doctorsList.doctorRow.address'),
                                email: t('panel.secretary.doctors.doctorsList.doctorRow.email'),
                                landline: t('panel.secretary.doctors.doctorsList.doctorRow.landline'),
                                province: t('panel.secretary.doctors.doctorsList.doctorRow.province'),
                                city: t('panel.secretary.doctors.doctorsList.doctorRow.city')

                            },

                        },
                    },
                    tomorboard: {
                        alltomorboard: {
                            header: t('panel.secretary.tomorboard.alltomorboard.header'),
                            search: t('panel.secretary.tomorboard.alltomorboard.search'),
                            rowTomorboard: {
                                name: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.name'),
                                field: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.field'),
                                description: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.description'),
                                status: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.status'),
                                hospital: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.hospital'),
                                meeting_place: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.meeting_place'),
                                button: {
                                    edit: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.edit'),
                                    section: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.section'),
                                    Proceedings: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.Proceedings'),
                                    manageRequests: t('panel.secretary.tomorboard.alltomorboard.rowTomorboard.button.manageRequests'),
                                }
                            }
                        },
                        oneTomorboard: {
                            category: {
                                list: [
                                    "اطلاعات",
                                    "تنظیمات زمان",
                                    "انتخاب اعضا",
                                ]
                            },
                            information: {
                                title: t('panel.secretary.tomorboard.oneTomorboard.information.title'),
                                description: t('panel.secretary.tomorboard.oneTomorboard.information.description'),
                                field: t('panel.secretary.tomorboard.oneTomorboard.information.field'),
                                status: t('panel.secretary.tomorboard.oneTomorboard.information.status'),
                                hospital: t('panel.secretary.tomorboard.oneTomorboard.information.hospital'),
                                meeting_place: t('panel.secretary.tomorboard.oneTomorboard.information.meeting_place'),
                            },
                            date: {
                                title: t('panel.secretary.tomorboard.oneTomorboard.date.title'),
                            },
                            meeting: {
                                setting: {
                                    category: {
                                        list: [
                                            "تنظیمات",
                                            "پرونده بیماران",
                                        ]
                                    },
                                }
                            },
                            member: {
                                title: t('panel.secretary.tomorboard.oneTomorboard.member.title'),
                                search: t('panel.secretary.tomorboard.oneTomorboard.member.search'),
                                doctorRow: {
                                    buttomShowProfile: {
                                        text: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.buttomShowProfile.text'),
                                        fill: true,
                                    },
                                    national_id: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.national_id'),
                                    department: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.department'),
                                    phone: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.phone'),
                                    address: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.address'),
                                    email: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.email'),
                                    landline: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.landline'),
                                    province: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.province'),
                                    city: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.city'),
                                    buttonRemove: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.buttonRemove'),
                                },
                                dialog: {
                                    header: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.dialog.header'),
                                    search: t('panel.secretary.tomorboard.oneTomorboard.member.doctorRow.dialog.search'),
                                }
                            }
                        }
                    }
                },
                doctor: {
                    account: {
                        title: {
                            step1: t('panel.doctor.account.title.step1'),
                            step2: t('panel.doctor.account.title.step2'),
                            step3: t('panel.doctor.account.title.step3'),
                        },
                        step1: {
                            header: t('panel.doctor.account.step1.header'),
                            name: t('panel.doctor.account.step1.name'),
                            family: t('panel.doctor.account.step1.family'),
                            fatherName: t('panel.doctor.account.step1.fatherName'),
                            nationalCode: t('panel.doctor.account.step1.nationalCode'),
                            phone: t('panel.doctor.account.step1.phone'),
                            postCode: t('panel.doctor.account.step1.postCode'),
                            city: t('panel.doctor.account.step1.city'),
                            landline: t('panel.doctor.account.step1.landline'),
                            address: t('panel.doctor.account.step1.address'),
                            buttonNext: t('panel.doctor.account.step1.buttonNext'),
                            gender: t('panel.doctor.account.step1.gender'),

                        },
                        step2: {
                            header: t('panel.doctor.account.step2.header'),
                            doctorsScore: t('panel.doctor.account.step2.doctorsScore'),
                            officePostalCode: t('panel.doctor.account.step2.officePostalCode'),
                            is_military_member: t('panel.doctor.account.step2.is_military_member'),
                            faculty_history_years: t('panel.doctor.account.step2.faculty_history_years'),
                            HasPermanentLicense: t('panel.doctor.account.step2.HasPermanentLicense'),
                            addressOfOffice: t('panel.doctor.account.step2.addressOfOffice'),
                            email: t('panel.doctor.account.step2.email'),
                            files: {
                                header: t('panel.doctor.account.step2.files.header'),
                                medical_license: t('panel.doctor.account.step2.files.medical_license'),
                                id_card: t('panel.doctor.account.step2.files.id_card'),
                                profile_pic: t('panel.doctor.account.step2.files.profile_pic'),
                                birth_certificate: t('panel.doctor.account.step2.files.birth_certificate'),
                            }
                        },
                        passwordUpdate: {
                            header: t('panel.doctor.account.passwordUpdate.header'),
                            oldPassword: t('panel.doctor.account.passwordUpdate.oldPassword'),
                            password: t('panel.doctor.account.passwordUpdate.password'),
                            rePassword: t('panel.doctor.account.passwordUpdate.rePassword')
                        }
                    },
                    sideBar: {
                        sections: [
                            {
                                title: t('panel.doctor.sideBar.sections.0.title'),
                                subtitles: [
                                    {
                                        link: links.doctor.listPatient,
                                        text: t('panel.doctor.sideBar.sections.0.subtitles.0.text'),
                                        icon: Diversity1Rounded
                                    },
                                    {
                                        link: links.doctor.listPatientsRequest,
                                        text: t('panel.doctor.sideBar.sections.0.subtitles.1.text'),
                                        icon: LibraryBooksRounded
                                    },
                                    {
                                        link: links.doctor.tomorboard.tomorboardsList,
                                        text: t('panel.doctor.sideBar.sections.0.subtitles.2.text'),
                                        icon: GroupRounded
                                    },
                                    {
                                        link: links.doctor.tomorboard.meeting.meetingsList,
                                        text: "لیست جلسات",
                                        icon: GroupRounded
                                    },
                                    {
                                        link: links.doctor.accountStep1,
                                        text: t('panel.doctor.sideBar.sections.0.subtitles.3.text'),
                                        icon: PersonRounded
                                    },
                                    {
                                        link: links.doctor.changePassword,
                                        text: t('panel.doctor.sideBar.sections.0.subtitles.4.text'),
                                        icon: KeySharp
                                    }
                                ]

                            }

                        ]
                    },
                    patientsList: {
                        header: t('panel.doctor.patientsList.header'),
                        sorting: t('panel.doctor.patientsList.sorting'),
                        sortingList: [
                            { id: 1, text: "زمان" },
                            { id: 2, text: "نام" },
                            { id: 3, text: "بیشترین انتظار" },
                        ],
                        category: t('panel.doctor.patientsList.category'),
                        categoryList: [
                            { id: 1, text: "سرطان سر" },
                            { id: 2, text: "سرطان 2" },
                            { id: 3, text: "سرطان 4" },
                            { id: 4, text: "سرطان 5" }
                        ],
                        patientRow: {
                            buttomRequest: {
                                text: t('panel.doctor.patientsList.patientRow.buttomRequest.text'),
                                fill: true,
                            },
                            buttomMedicalRecord: {
                                text: t('panel.doctor.patientsList.patientRow.buttomMedicalRecord.text'),
                                fill: false,
                            },
                            phone: t('panel.doctor.patientsList.patientRow.phone'),
                            address: t('panel.doctor.patientsList.patientRow.address'),
                            describtion: t('panel.doctor.patientsList.patientRow.describtion'),
                            kindDisease: t('panel.doctor.patientsList.patientRow.kindDisease'),
                            timeAccepted: t('panel.doctor.patientsList.patientRow.timeAccepted'),
                            email: t('panel.doctor.patientsList.patientRow.email'),
                            dialogChooseTomorBoard: {
                                header: t('panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.header'),
                                location: t('panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.location'),
                                search: t('panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.search'),
                                secretaryName: t('panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.secretaryName'),
                                buttonAccept: t('panel.doctor.patientsList.patientRow.dialogChooseTomorBoard.buttonAccept'),
                            }

                        },
                        patientRequestRow: {
                            acceptRequest: {
                                text: t('panel.doctor.patientsList.patientRequestRow.acceptRequest.text'),
                                fill: true,
                            },
                            rejectRequest: {
                                text: t('panel.doctor.patientsList.patientRequestRow.rejectRequest.text'),
                                fill: false,
                            },
                            location: t('panel.doctor.patientsList.patientRequestRow.location'),
                            describtion: t('panel.doctor.patientsList.patientRequestRow.describtion'),
                            kindDisease: t('panel.doctor.patientsList.patientRequestRow.kindDisease'),
                            timeAccepted: t('panel.doctor.patientsList.patientRequestRow.timeAccepted'),

                        },
                        tempPatientsList: [
                            {
                                name: "عالی",
                                family: "پورزاده",
                                kindDisease: "سرطان مغز",
                                isAccepted: false,
                                location: "بیمارستان امام کرمانشاه",
                                secretary: "علی علیزاده",
                                timeAccepted: "۱۴۰۰/۱۲/۱۲",
                                img: links.logo_hospital
                            },
                            {
                                name: "عالی",
                                family: "2پورزاده",
                                kindDisease: "2سرطان مغز",
                                isAccepted: false,
                                location: "بیمارستان امام کرمانشاه",
                                secretary: "علی علیزاده",
                                timeAccepted: "۱۴۰۰/۱۲/۱۲",
                                img: undefined
                            }
                            ,
                            {
                                name: "عالی",
                                family: "2پورزاده",
                                kindDisease: "2سرطان مغز",
                                isAccepted: true,
                                location: "بیمارستان امام کرمانشاه",
                                secretary: "علی علیزاده",
                                timeAccepted: "۱۴۰۰/۱۲/۱۲",
                                img: links.logo_hospital
                            }
                        ]

                    }
                },
                patient: {
                    sideBar: {
                        sections: [
                            {
                                title: t('panel.patient.sideBar.sections.0.title'),
                                subtitles: [
                                    {
                                        link: links.patient.listDoctor,
                                        text: t('panel.patient.sideBar.sections.0.subtitles.0.text'),
                                        icon: Diversity1Rounded
                                    },
                                    {
                                        link: links.patient.MyRequest,
                                        text: t('panel.patient.sideBar.sections.0.subtitles.1.text'),
                                        icon: LibraryBooksRounded
                                    },
                                ]

                            },
                            {
                                title: t('panel.patient.sideBar.sections.1.title'),
                                subtitles: [
                                    {
                                        link: links.patient.myFile,
                                        text: t('panel.patient.sideBar.sections.1.subtitles.0.text'),
                                        icon: FolderSharedRounded
                                    },
                                    {
                                        link: links.patient.myHistory,
                                        text: t('panel.patient.sideBar.sections.1.subtitles.1.text'),
                                        icon: WorkHistoryRounded
                                    },
                                    {
                                        link: links.patient.account,
                                        text: "حساب کاربری",
                                        icon: SettingsRounded
                                    },
                                ]

                            }


                        ]
                    },
                    doctorsList: {
                        header: t('panel.patient.doctorsList.header'),
                        category: t('panel.patient.doctorsList.category'),
                        categoryList: [
                            { id: 0, text: "سرطان سر" },
                            { id: 1, text: "سرطان 2" },
                            { id: 2, text: "سرطان 4" },
                            { id: 3, text: "سرطان 5" }
                        ],
                        doctorRow: {
                            buttomShowProfile: {
                                text: t('panel.patient.doctorsList.doctorRow.buttomShowProfile.text'),
                                fill: true,
                            },
                            location: t('panel.patient.doctorsList.doctorRow.location'),
                            department: t('panel.patient.doctorsList.doctorRow.department'),
                            phone: t('panel.patient.doctorsList.doctorRow.phone'),
                            address: t('panel.patient.doctorsList.doctorRow.address'),

                        },

                    },
                    myRequest: {
                        header: t('panel.patient.myRequest.header'),
                        category: t('panel.patient.myRequest.category'),
                        categoryList: [
                            { id: 0, text: "همه", value: "all" },
                            { id: 1, text: "تایید شده", value: "AP" },
                            { id: 2, text: "در انتظار", value: "PE" },
                            { id: 3, text: "رد شده", value: "RJ" }
                        ],
                        myRequestRow: {
                            buttomShowProfile: {
                                text: t('panel.patient.myRequest.myRequestRow.buttomShowProfile.text'),
                                fill: true,
                            },
                            status: t('panel.patient.myRequest.myRequestRow.status'),
                            department: t('panel.patient.myRequest.myRequestRow.department'),
                            phone: t('panel.patient.myRequest.myRequestRow.phone'),
                            address: t('panel.patient.myRequest.myRequestRow.address'),

                        },
                    },
                    account: {
                        tempImage: links.logo_hospital,
                        header: t('panel.patient.account.header'),
                        tempName: t('panel.patient.account.tempName'),
                        name: t('panel.patient.account.name'),
                        family: t('panel.patient.account.family'),
                        nationalCode: t('panel.patient.account.nationalCode'),
                        fatherName: t('panel.patient.account.fatherName'),
                        phone: t('panel.patient.account.phone'),
                        gender: t('panel.patient.account.gender'),
                        city: t('panel.patient.account.city'),
                        address: t('panel.patient.account.address'),
                        landline: t('panel.patient.account.landline'),
                        buttonSave: t('panel.patient.account.buttonSave'),
                        password: {
                            oldPassword: t('panel.patient.account.password.oldPassword'),
                            newPassword: t('panel.patient.account.password.newPassword'),
                            confirmPassword: t('panel.patient.account.password.confirmPassword'),
                            buttonSave: t('panel.patient.account.password.buttonSave'),
                        }


                    },
                    myFilePage: {
                        category: {
                            list: [
                                "همه",
                                "تصاویر پزشکی",
                                "روندهای درمان",
                                "نظر توموربورد",
                                "نظرات پزشکان",
                            ]
                        },
                        pictures: {
                            header: t('panel.patient.myFilePage.pictures.header'),
                            subHeader: t('panel.patient.myFilePage.pictures.subHeader'),
                        },
                        suggestions: {
                            header: t('panel.patient.myFilePage.suggestions.header'),
                            subHeader: t('panel.patient.myFilePage.suggestions.subHeader'),
                            buttonShowCard: t('panel.patient.myFilePage.suggestions.buttonShowCard'),
                        },
                        dialogAddFiles: {
                            header: t('panel.patient.myFilePage.dialogAddFiles.header'),
                            title: t('panel.patient.myFilePage.dialogAddFiles.title'),
                            hintTitle: t('panel.patient.myFilePage.dialogAddFiles.hintTitle'),
                            description: t('panel.patient.myFilePage.dialogAddFiles.description'),
                            hintDescription: t('panel.patient.myFilePage.dialogAddFiles.hintDescription'),
                            file: {
                                title: t('panel.patient.myFilePage.dialogAddFiles.file.title'),
                            }
                        },

                        tempOneDoctorSuggestion: [
                            "سلام خیلی اوضاع خوبی وجود ندارد",
                            "سلام خیلی اوضاع خوبی وجود ندارد البته سلامتی شما و شفا پیدا کردن شما دست خداوند حکیم هستش و ما صرفا وسیله ای برای اجرای این قدر شما هستیم امیدوارم که حالتون بهتر بشه تا جایی که می‌توانید از مصرف غذاهای چرب بپرهیزید و غذاهای گیاهی  را مد نظر خود داشته باشید باشد که بهبودی شما کسب شود",
                            "سلام خیلی اوضاع خوبی وجود ندارد",
                            "سلام خیلی اوضاع خوبی وجود ندارد البته سلامتی شما و شفا پیدا کردن شما دست خداوند حکیم هستش و ما صرفا وسیله ای برای اجرای این قدر شما هستیم امیدوارم که حالتون بهتر بشه تا جایی که می‌توانید از مصرف غذاهای چرب بپرهیزید و غذاهای گیاهی  را مد نظر خود داشته باشید باشد که بهبودی شما کسب شود",
                            "سلام خیلی اوضاع خوبی وجود ندارد",
                            "سلام خیلی اوضاع خوبی وجود ندارد البته سلامتی شما و شفا پیدا کردن شما دست خداوند حکیم هستش و ما صرفا وسیله ای برای اجرای این قدر شما هستیم امیدوارم که حالتون بهتر بشه تا جایی که می‌توانید از مصرف غذاهای چرب بپرهیزید و غذاهای گیاهی  را مد نظر خود داشته باشید باشد که بهبودی شما کسب شود",
                            "سلام خیلی اوضاع خوبی وجود ندارد",
                            "سلام خیلی اوضاع خوبی وجود ندارد البته سلامتی شما و شفا پیدا کردن شما دست خداوند حکیم هستش و ما صرفا وسیله ای برای اجرای این قدر شما هستیم امیدوارم که حالتون بهتر بشه تا جایی که می‌توانید از مصرف غذاهای چرب بپرهیزید و غذاهای گیاهی  را مد نظر خود داشته باشید باشد که بهبودی شما کسب شود",
                        ],

                    },

                }



            }



        }
    )
}
