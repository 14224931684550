import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AddRounded } from "@mui/icons-material";
import { interfaceSettingMeetings } from "./interfaceSettingMeetings";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import ButtonEffectShadow from "../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";

import colors from "../../../../../../config/colors";
import { interfaceRowListMeetingsOfTomorBoard } from "../interfaceRowListMeetingsOfTomorBoard";
import { interfaceApiEntityMeeting } from "../../meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";
import ConfigParamsMeetings from "./ConfigParamsMeetings";
import MembersMeeting from "../membersMeeting/MembersMeeting";
import PatientsFileMeeting from "../patientsFileMeeting/PatientsFileMeeting";
import { ImageSquare } from "../../../../../../components/imageSquare/ImageSquare";
import links from "../../../../../../config/links";

enum EnumSelected {
    config = "config",
    // member = "mem",
    patients = "pat",

}
function SettingMeetings(pagesParams: interfaceSettingMeetings) {
    const location = useLocation().state as interfaceRowListMeetingsOfTomorBoard;
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    // const { idTomorboard } = useParams();

    const [getWitchPartShow, setWitchPartShow] = useState<EnumSelected>(EnumSelected.config);
    const [getResInformationOfTheMeeting, setResInformationOfTheMeeting] = useState<interfaceApiEntityMeeting>();

    const getApiInformationOfTheMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getOneMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorboard}/meetings/${location.serverData.id}`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.data as interfaceApiEntityMeeting;

                setResInformationOfTheMeeting(t);
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safaaaaaaaaaaa");
        getApiInformationOfTheMeeting();
    }, []);
    return (
        <div style={{
            display: "flex", width: "100%",
            height: "100%",
            flexDirection: "column", alignItems: "start", justifyContent: "space-between"
        }}>
            <div style={{
                display: "flex", width: "100%",
                flexDirection: "row",
                marginBottom: "calc(3vh + 12px)",
                gap: "calc(1vw + 8px)",
                justifyContent: "start", alignItems: "center", flexWrap: "wrap"

            }}>
                {confComps.panel.secretary.tomorboard.oneTomorboard.meeting.setting.category.list.map((cat, index, array) => {
                    return (
                        <ButtonEffectShadow
                            text={cat}
                            size="small"
                            colors={
                                (Object.values(EnumSelected).indexOf(getWitchPartShow) == index) ?
                                    colors.panel.buttonYellowBackground :
                                    colors.panel.buttonDarkBackground
                            }
                            onclick={() => {
                                setWitchPartShow(Object.values(EnumSelected)[index])
                                // setCategoryFilter(Object.keys(enumCategoryFilter)[0])
                            }}
                        />
                    )
                })}
                {getWitchPartShow == EnumSelected.patients ? 
                <ImageSquare iconSvg={AddRounded} width={"calc(1vh + 32px)"} borderRadius="100px"
                    callbackOnclick={() => {
                        navigate(links.secretary.tomorboard.appendRequestInTheTomorBoardMeeting ,
                            {
                                state:
                                    (location as interfaceRowListMeetingsOfTomorBoard)
                            });
                    }}
                    background={colors.yellowHeader}
                    color={colors.blue.darkbackground} />
                    : undefined
                }
            </div>
            {/* <div style={{
                display: "flex", width: "100%", flexDirection: "column",
                alignItems: "start", justifyContent: "space-between",
                background: colors.panel.doctor.account.step.background,
                borderRadius: "30px"
            }}> */}
            <div style={{
                display: "flex", width: "100%", flexDirection: "column",
                alignItems: "start", justifyContent: "space-between",
                borderRadius: "30px",
                height: "100%",
                maxHeight: "100%",
                overflow: "hidden",
                paddingBlockEnd: "32px",
                background: colors.panel.doctor.account.step.background,
                paddingInline: "8px",
                boxSizing: "border-box",
            }}>
                {(getWitchPartShow == EnumSelected.config && getResInformationOfTheMeeting) ?
                    <ConfigParamsMeetings
                        serverData={getResInformationOfTheMeeting}
                        idTomorBoard={location.idTomorboard}
                        callbackRefresh={getApiInformationOfTheMeeting} />
                    : undefined}
                {/* {(getWitchPartShow == EnumSelected.member && getResInformationOfTheMeeting) ?
                    <MembersMeeting
                        serverData={getResInformationOfTheMeeting}
                        idTomorBoard={location.idTomorboard}
                        callbackRefresh={getApiInformationOfTheMeeting} />
                    : undefined} */}
                {(getWitchPartShow == EnumSelected.patients && getResInformationOfTheMeeting) ?
                    <PatientsFileMeeting
                        serverData={getResInformationOfTheMeeting}
                        idTomorBoard={location.idTomorboard}
                        callbackRefresh={getApiInformationOfTheMeeting}
                        typeOfDisplay={pagesParams.typeOfDisplay} />
                    : undefined}
            </div>
        </div>
    )
}
export default SettingMeetings;