import { useState, useRef, useEffect } from "react";
import ButtonArrow from "../../../../components/buttonArrow/ButtonArrow";
import { enumArrow } from "../../../../components/buttonArrow/interfaceButtonArrow";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import CardImage from "./cardImage/CardImage";
import CardSuggestion from "./cardSuggestion/CardSuggestion";
import { enumCategoryFilter, interfaceEntityAllCommentsFromServer } from "../interfaceMyFilePage";
import colors from "../../../../config/colors";
import { interfaceAllCategoryOfMyFilePage } from "./interfaceAllCategoryOfMyFile";
import BigCardComments from "../allComments/cardComments/BigCardComments";
import links from "../../../../config/links";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { Grid } from "@mui/material";
import { getCommentRange } from "typescript";
export function groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return { ...prev, [groupKey]: group };
    }, {});
}
function AllCategoryOfMyFile(componenetParams: interfaceAllCategoryOfMyFilePage) {
    var confComps = ConfigComps();
    const [getCategoryFilter, setCategoryFilter] = useState<enumCategoryFilter>(enumCategoryFilter.all);
    const [getPointerFirstImageCard, setPointerFirstImageCard] = useState<number>(0);
    const [getCountImageCardsInRow, setCountImageCardsInRow] = useState<number>(0);
    const refDivForCard = useRef<HTMLInputElement>(null);
    ///
    const [getPointerFirstComments, setPointerFirstComments] = useState<number>(0);
    const [getCountCommentsInRow, setCountCommentsInRow] = useState<number>(0);
    const [getGroupByIdTomorBoardComment, setGroupByIdTomorBoardComment] = useState<interfaceEntityAllCommentsFromServer[]>([]);
    useEffect(() => {
        setCountImageCardsInRow(Number(refDivForCard.current ? refDivForCard.current.offsetWidth : 0) / 310);
        setCountCommentsInRow(Number(refDivForCard.current ? refDivForCard.current.offsetWidth : 0) / 460);
    }, [refDivForCard.current]);
    useEffect(() => {
        if (componenetParams.allCommentFromServer) {

            var arrIdTomorBoard = Array.from(new Set(componenetParams.allCommentFromServer.data.data.map((com, index) => com.meeting.tumorboard.id)))
            var arrayOfLastTomorboardCommentGroup = [] as interfaceEntityAllCommentsFromServer[]
            for (let pointer = 0; pointer < arrIdTomorBoard.length; pointer++) {
                arrayOfLastTomorboardCommentGroup.push(componenetParams.allCommentFromServer.data.data.filter(com => com.meeting.tumorboard.id == arrIdTomorBoard[pointer]).sort((a, b) => { return a.created_at - b.created_at })[0])
            }
            setGroupByIdTomorBoardComment([...arrayOfLastTomorboardCommentGroup])
        }
    }, [componenetParams.allCommentFromServer])
    const onClickScrollImageCard = (isNextClicked: boolean) => {
        console.log("safajonnn")
        if (isNextClicked) {
            setPointerFirstImageCard(getPointerFirstImageCard + 1)
        }
        else {
            setPointerFirstImageCard(getPointerFirstImageCard - 1)
        }
    }
    const onClickScrollComment = (isNextClicked: boolean) => {
        console.log("safajonnn")
        if (isNextClicked) {
            setPointerFirstComments(getPointerFirstComments + 1)
        }
        else {
            setPointerFirstComments(getPointerFirstComments - 1)
        }
    }
    return (
        <div style={{ width: "100%" }}>
            <div style={{
                display: "flex", width: "100%", background: colors.blue.darkbackground, borderRadius: "calc(1vw + 24px)",
                flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                paddingBlock: "calc(2vh + 24px)",
            }}
            >
                <h5 style={{ color: "white" }}>{confComps.panel.patient.myFilePage.pictures.header}</h5>
                <p style={{ color: colors.white.lightDark }}>{`${confComps.panel.patient.myFilePage.pictures.subHeader} (${(componenetParams.allImageFromServer ? componenetParams.allImageFromServer.data.data.length : 0)})`}</p>
                <div ref={refDivForCard} style={{
                    width: "100%", display: "flex",
                    boxSizing: "border-box",
                    paddingInline: "60px",
                    marginTop: "calc(2vh + 40px)", flexDirection: "row", alignItems: "center", gap: "calc(4px + 2vw)"
                    , justifyContent: "space-around",
                    position: "relative"
                }}>
                    {
                        componenetParams.allImageFromServer?.data.data.filter((x, index) => index >= getPointerFirstImageCard && index < (getPointerFirstImageCard + getCountImageCardsInRow))
                            .map((card, index, array) => {
                                return (
                                    <CardImage squareImagesLinks={card.files}
                                        title={card.title} key={card.id}
                                        description={card.description} date={card.created_at} />
                                )
                            })

                    }
                    <div style={{
                        display: (getPointerFirstImageCard + getCountImageCardsInRow + 1 < (componenetParams.allImageFromServer ? componenetParams.allImageFromServer.data.data.length : 0)) ? "block" : "none"
                        , position: "absolute", left: "0%", top: "50%", transform: "translate(50px,-20px)", background: "red"
                    }}>
                        <ButtonArrow width={"46px"} direction={enumArrow.left}
                            onClick={() => {
                                onClickScrollImageCard(true)
                            }}
                        />
                    </div>
                    <div style={{ display: (getPointerFirstImageCard <= 0) ? "none" : "block", position: "absolute", left: "100%", top: "50%", transform: "translate(0px,-20px)", background: "red" }}>
                        <ButtonArrow width={"46px"} direction={enumArrow.right}
                            onClick={() => {
                                onClickScrollImageCard(false)
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* tomorboard comments */}
            <div style={{
                display: "flex", width: "100%", background: colors.blue.darkbackground, borderRadius: "calc(1vw + 24px)",
                flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                marginTop: "calc(24px + 4vh)",
                paddingBlock: "calc(2vh + 24px)",
            }}
            >
                <h5 style={{ color: "white" }}>{"نظر توموربورد"}</h5>
                <p style={{ color: colors.white.lightDark }}>{`${"نظراتی که توموربورد‌ها برای شما داده‌اند"} (${componenetParams.allCommentFromServer?.data.data.length})`}</p>
                <div ref={refDivForCard} style={{
                    width: "100%",
                    display: "flex",
                    boxSizing: "border-box",
                    marginTop: "calc(2vh + 40px)",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative"
                }}>

                    <GridFromMUI container
                        justifyContent="center" alignItems="center"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>


                        {
                            getGroupByIdTomorBoardComment.map((uniqTomorboard, index, array) => {
                                if (index < getPointerFirstComments || index >= (getPointerFirstComments + getCountCommentsInRow)) {
                                    return
                                }
                                var groupOfTomor = componenetParams.allCommentFromServer?.data.data.filter(com => com.meeting.tumorboard.id == uniqTomorboard.meeting.tumorboard.id)
                                return (
                                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}
                                        style={{
                                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"
                                        }}
                                    >
                                        <CardSuggestion
                                            key={index}
                                            imgUrl={links.logo_hospital}
                                            name={uniqTomorboard.meeting.tumorboard.name}
                                            index={index}
                                            suggestion={uniqTomorboard.comment}
                                            countSuggestion={groupOfTomor?.length ?? 0} />
                                    </Grid>
                                )
                            })
                        }
                    </GridFromMUI>


                    <div style={{
                        display: (getPointerFirstComments + getCountCommentsInRow + 1 < getGroupByIdTomorBoardComment.length) ? "block" : "none"
                        , position: "absolute", left: "0%", top: "50%", transform: "translate(50px,-20px)", background: "red"
                    }}>
                        <ButtonArrow width={"46px"} direction={enumArrow.left}
                            onClick={() => {
                                onClickScrollComment(true)
                            }}
                        />
                    </div>
                    <div style={{ display: (getPointerFirstComments <= 0) ? "none" : "block", position: "absolute", left: "100%", top: "50%", transform: "translate(0px,-20px)", background: "red" }}>
                        <ButtonArrow width={"46px"} direction={enumArrow.right}
                            onClick={() => {
                                onClickScrollComment(false)
                            }}
                        />
                    </div>
                </div>
            </div>
            {/* tomorboard comments */}
            <div style={{
                display: "flex", width: "100%", background: colors.blue.darkbackground, borderRadius: "calc(1vw + 24px)",
                flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                marginTop: "calc(24px + 4vh)",
                paddingBlock: "calc(2vh + 24px)",
            }}
            >
                <h5 style={{ color: "white" }}>{"روند درمان‌ها"}</h5>
                <p style={{ color: colors.white.lightDark }}>{`${"روند درمان‌های شما"} (${componenetParams.allTreatmentProcessesFromServer?.data.data.length})`}</p>
                <div ref={refDivForCard} style={{
                    width: "100%",
                    display: "flex",
                    boxSizing: "border-box",
                    marginTop: "calc(2vh + 40px)",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "relative"
                }}>

                    <GridFromMUI container
                        justifyContent="center" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>


                        {
                            componenetParams.allTreatmentProcessesFromServer?.data.data.map((treatmentProcess, index, array) => {

                                return (
                                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}
                                        style={{
                                            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"
                                        }}
                                    >
                                        <CardSuggestion
                                            key={index}
                                            imgUrl={links.logo_hospital}
                                            name={treatmentProcess.title}
                                            index={index}
                                            suggestion={treatmentProcess.description}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </GridFromMUI>


                    <div style={{
                        display: (getPointerFirstComments + getCountCommentsInRow + 1 < getGroupByIdTomorBoardComment.length) ? "block" : "none"
                        , position: "absolute", left: "0%", top: "50%", transform: "translate(50px,-20px)", background: "red"
                    }}>
                        <ButtonArrow width={"46px"} direction={enumArrow.left}
                            onClick={() => {
                                onClickScrollComment(true)
                            }}
                        />
                    </div>
                    <div style={{ display: (getPointerFirstComments <= 0) ? "none" : "block", position: "absolute", left: "100%", top: "50%", transform: "translate(0px,-20px)", background: "red" }}>
                        <ButtonArrow width={"46px"} direction={enumArrow.right}
                            onClick={() => {
                                onClickScrollComment(false)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AllCategoryOfMyFile;