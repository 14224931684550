import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../config/ConfigCOMP";
import colors from "../../../config/colors";
import DoctorAccountStep1 from "./DoctorAccountStep1";
import { InterfaceDoctorAccount, interfaceServerGetInformationDocotor } from "./interfaceDoctorAccount";
import { InterfaceDoctorAccountStep1 } from "./interfaceDoctorAccountStep1";
import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { EnumGender } from "../../../utils/Gender";
import { SelectedProfile } from "../../../store/profileStateSlice";
import DoctorAccountStep2 from "./DoctorAccountStep2";
import DoctorAccountStep3 from "./DoctorAccountStep3";
import DoctorAccountStep4 from "./DoctorAccountStep4";
enum EnumStateOfTitle {
    deactive = "de",
    active = "ac",
    waiting = "wa",
}

const DivLevel = styledMUI("div", { shouldForwardProp: (prop) => prop !== 'active', })<{ active?: EnumStateOfTitle; }>(({
    theme,
    active
}) => ({
    cursor: active != EnumStateOfTitle.deactive ? "pointer" : undefined,
    width: "33.34%",
    textAlign: "center",
    borderBottom: `3px solid ${active == EnumStateOfTitle.active ? colors.panel.doctor.account.title.active : active == EnumStateOfTitle.deactive ? colors.panel.doctor.account.title.deactive : colors.panel.doctor.account.title.waiting}`,
    color: `${active == EnumStateOfTitle.active ? colors.panel.doctor.account.title.active : active == EnumStateOfTitle.deactive ? colors.panel.doctor.account.title.deactive : colors.panel.doctor.account.title.waiting}`
}));
function DoctorAccount(paramsPage: InterfaceDoctorAccount) {

    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAccountFirstTime, setAccountFirstTime] = useState<interfaceServerGetInformationDocotor>();
    const [getStepDisplay, setStepDisplay] = useState<number>(-1);
    const getInformationOfAccountAtFirstTime = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.account.getInformation;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceServerGetInformationDocotor;
                if (t.data.status == 0) {
                    setStepDisplay(0);
                }
                if (t.data.status == 1) {
                    setStepDisplay(1);
                }
                if (t.data.status >= 2) {
                    setStepDisplay(2);
                }
                setAccountFirstTime(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safajooon")
        getInformationOfAccountAtFirstTime();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
                <DivLevel active={((getAccountFirstTime?.data.status ?? -1) >= 0) ? EnumStateOfTitle.active : EnumStateOfTitle.deactive}
                    onClick={() => {
                        if ((getAccountFirstTime?.data.status ?? -1) >= 0) {
                            setStepDisplay(0)
                        }
                    }}>
                    {confComps.panel.doctor.account.title.step1}
                </DivLevel>
                <DivLevel active={((getAccountFirstTime?.data.status ?? -1) >= 1) ? EnumStateOfTitle.active : EnumStateOfTitle.deactive}
                    onClick={() => {
                        if ((getAccountFirstTime?.data.status ?? -1) >= 1) {
                            setStepDisplay(1)
                        }
                    }}>
                    {confComps.panel.doctor.account.title.step2}
                </DivLevel>
                <DivLevel active={((getAccountFirstTime?.data.status ?? -1) == 2) ? EnumStateOfTitle.waiting : ((getAccountFirstTime?.data.status ?? -1) > 2) ? EnumStateOfTitle.active : EnumStateOfTitle.deactive}
                    onClick={() => {
                        if ((getAccountFirstTime?.data.status ?? -1) >= 2) {
                            setStepDisplay(2)
                        }
                    }}>
                    {confComps.panel.doctor.account.title.step3}
                </DivLevel>
            </div>

            <DoctorAccountStep1
                typeOfDisplay={paramsPage.typeOfDisplay}
                isDisplay={getStepDisplay == 0}
                ServerGetInfoAtFirstTime={getAccountFirstTime}
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
            />
            <DoctorAccountStep2
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
                typeOfDisplay={paramsPage.typeOfDisplay}
                isDisplay={getStepDisplay == 1}
                ServerGetInfoAtFirstTime={getAccountFirstTime}
            />
            <DoctorAccountStep3
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
                typeOfDisplay={paramsPage.typeOfDisplay} isDisplay={(getStepDisplay == 2) && (getAccountFirstTime?.data.status ?? -1) == 2} ServerGetInfoAtFirstTime={getAccountFirstTime}
            />
            <DoctorAccountStep4
                callbackGetInfoAgain={getInformationOfAccountAtFirstTime}
                typeOfDisplay={paramsPage.typeOfDisplay} isDisplay={(getStepDisplay == 2) && (getAccountFirstTime?.data.status ?? -1) > 2} ServerGetInfoAtFirstTime={getAccountFirstTime}
            />

        </div>
    )
}
export default DoctorAccount;