import { useNavigate } from "react-router-dom";
import ButtonArrow from "../../../../../components/buttonArrow/ButtonArrow";
import { enumArrow } from "../../../../../components/buttonArrow/interfaceButtonArrow";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import {ImageSquare} from "../../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import colors from "../../../../../config/colors";
import links from "../../../../../config/links";
import { interfaceCardSuggestion } from "./interfaceCardSuggestion";


function CardSuggestion(componenetParams: interfaceCardSuggestion) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    return (
        <div style={{
            background: colors.blue.dark, borderRadius: "24px", height: "calc(80px + 3vh)",
            display: "flex", flexDirection: "row",
            justifyContent: componenetParams.countSuggestion ? "space-between" : "start",
            alignItems: "center",
            width: "calc(230px + 12vw)",
            gap: componenetParams.countSuggestion ? undefined : "2vw",
            padding: "calc(1vh + 4px) calc(1vw + 4px)",
            boxSizing: "border-box"
        }}>
            <ImageSquare img={componenetParams.imgUrl} width={"calc(50px + 3vw)"} borderRadius="8px" border="1px solid white" />
            <div style={{
                display: "flex",
                flexDirection: "column", width: componenetParams.countSuggestion ? "150px" : "100%", justifyContent: "center", alignItems: "start"
            }}>
                <p style={{ color: "white" }}>{componenetParams.name}</p>
                {/* <p style={{ color: colors.yellowHeader }}>{`${componenetParams.suggestion.substring(0, 12)}${componenetParams.suggestion.length > 12 ? "..." : ""}`}</p> */}
                <p style={{ color: colors.yellowHeader, paddingBlock: "2px", textOverflow: "ellipsis", overflow: "hidden", height: "1.5em" }}>
                    {`${componenetParams.suggestion}`}
                </p>

            </div>
            {
                componenetParams.countSuggestion ?
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", alignItems: "center" }}>
                        <ImageSquare width={`calc(${2 * `${componenetParams.countSuggestion}`.length}rem + 12px)`}
                            element={componenetParams.countSuggestion}
                            color="#000"
                            borderRadius="800px"
                            border={`1px solid "#e2c004"`}
                            background={colors.yellowHeader} />
                        {/* <ButtonArrow width={"1.5em"} direction={enumArrow.none} text={`${componenetParams.countSuggestion}`} styleMainDiv={{ position: "unset" }} /> */}
                    </div>
                    :
                    undefined
            }
        </div>
    )
}
export default CardSuggestion;