import { useEffect, useState } from "react";
import { interfaceCardText } from "./interfaceCardText";
import colors from "../../../../../../config/colors";

function CardText(paramsComponent: interfaceCardText) {
    const [getIsBoxVisible, setIsBoxVisible] = useState<Boolean>(true);
    // const [getEdittextUser, setEdittextUser] = useState<string>("");
    useEffect(() => {
        if (paramsComponent.findText.length > 0) {
            if (paramsComponent.text.indexOf(paramsComponent.findText) >= 0) {
                setIsBoxVisible(true)

            }
            else {
                setIsBoxVisible(false)
            }
        }
        else {
            setIsBoxVisible(true)
        }
    }, [paramsComponent.findText]);
    return (
        <div style={{
            // margin: "8px calc(1vw + 8px)",
            marginInline:"40px",
            width: "-webkit-fill-available",
            background: colors.blue.light,
            boxSizing: "border-box",
            color: "white", border: "1px solid white", borderRadius: "24px",
            display: getIsBoxVisible ? "flex" : "none",
            flexDirection: "column", alignItems: "start", justifyContent: "start",
            padding: "calc(1vh + 12px) calc(1vw + 12px)"
        }}>
            {paramsComponent.text}
        </div>
    )
}
export default CardText;