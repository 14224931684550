import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Route } from "react-router-dom";
import { SelectedProfile } from "../store/profileStateSlice";
import links from "../config/links";
import { useAsyncEffect } from 'use-async-effect';
import { enumTypeOfAlert } from "../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../store/AlertStateModeSlice";
const PrivateRedirectToLogin = (props: any) => {
  const token = localStorage.getItem("auth");
  const ProfileInStore = useSelector(SelectedProfile);
  // const ws = useRef<WebSocket | null>(null);
  // const dispatch = useDispatch();

  if ((ProfileInStore.lastUpdatePosixTime < 0 || (ProfileInStore.token == undefined))) {
    return (<Navigate to={links.loginAndRegister.login} />)
  }

  
  return <Outlet />;


};

export default PrivateRedirectToLogin;