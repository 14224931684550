// import { useTheme } from "@emotion/react";
import { AddRounded, ArrowBackRounded, ArrowForwardRounded, CancelOutlined, CancelRounded, Person2Rounded } from "@mui/icons-material";
import { Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceServerGetDocotorsList } from "../../../doctors/doctorsList/interfaceDoctorsList";
import { interfaceRowOfTomorboardsMember } from "./interfaceRowOfTomorboardsMember";
import colors from "../../../../../config/colors";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import EditText from "../../../../../components/editText/EditText";

function RowOfTomorboardsMember(componentsParams: interfaceRowOfTomorboardsMember) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const deleteDoctorFromTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.deleteDoctorFromTomorboard;
        const obj = {
            "doctor_ids": [componentsParams.id],
            "doctor_id": componentsParams.id
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + componentsParams.idTomorBoard + "/members/", mtheme),
            urlObj.method, urlObj.isTokenNecessary, obj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                componentsParams.callbackRefresh();

            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const AddDoctorToTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.addDoctorToTomorboard;
        const obj = {
            "doctor_ids": [componentsParams.id],
            "doctor_id": componentsParams.id
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + componentsParams.idTomorBoard + "/members/", mtheme),
            urlObj.method, urlObj.isTokenNecessary, obj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                componentsParams.callbackRefresh();

            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%", display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            paddingInline: "20px",
            paddingBlock: "8px",
            alignItems: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
            justifyContent: "start",
            borderRadius: "24px",

            background: colors.blue.darkbackground
        }}>

            {componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ?

                <div style={{
                    width: "calc(10vw + 32px)",
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        width: "100%", height: "100%", position: "absolute",
                        boxSizing: "border-box",
                        overflow: "hidden",
                    }}>

                        {componentsParams.profile_pic ?
                            <ImageSquare img={`${componentsParams.profile_pic}`} width={"100%"}
                                borderRadius="8px" border="1px solid white"
                                isScaleable={{}}
                            /> :
                            <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                color={colors.panel.doctor.patientsList.selector.color}
                                fontsizeIcon="27px"
                                borderRadius="8px" border="1px solid white" />
                        }
                    </div>
                    <div style={{ paddingBottom: "100%" }} />
                </div>
                : undefined}
            <h5 style={{
                color: "white", fontSize: "1.2rem", fontWeight: "200",
                margin: "12px 4px"
            }}>
                {`${componentsParams.first_name},${componentsParams.last_name}`}
            </h5>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "start", gap: "calc(1vw + 8px)"
            }}>
                {componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? undefined :
                    <div style={{
                        width: "calc(13rem + 2vh)",
                        position: "relative",
                        overflow: "hidden",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            width: "100%", height: "100%", position: "absolute",
                            overflow: "hidden"

                        }}>
                            <div style={{
                                height: "fit-content",
                                boxSizing: "border-box",

                            }}>
                                {componentsParams.profile_pic ?
                                    <ImageSquare img={`${componentsParams.profile_pic}`}
                                        isScaleable={{}}
                                        width={"100%"} borderRadius="8px" border="1px solid white"
                                    /> :
                                    <ImageSquare iconSvg={Person2Rounded}
                                        borderRadius="8px" border="1px solid white"
                                        color={colors.panel.doctor.patientsList.selector.color} width={"100%"}
                                        fontsizeIcon="calc(16px + 10rem + 1vh)" />

                                }
                            </div>
                        </div>
                        <div style={{ paddingBottom: "100%" }} />
                    </div>
                }
                <div style={{ width: "-webkit-fill-available", alignSelf: "stretch", display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "center" }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.national_id}:
                                {componentsParams.national_id}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.department}:
                                {componentsParams.department}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.province}:
                                {componentsParams.province},{componentsParams.city}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.phone}:
                                {componentsParams.phone}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.landline}:
                                {componentsParams.landline}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.address}:
                                {componentsParams.address}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", boxSizing: "border-box",
                                // paddingInlineStart: "calc(15vh + calc(1vw + 8px))" 
                                paddingInline: "8px",
                            }}>
                                <div style={{
                                    width: "100%", boxSizing: "border-box", height: "1px"
                                    , background: colors.panel.doctor.patientsList.selector.color
                                }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%", gap: "calc(1vw + 8px)", alignItems: "center",
                                justifyContent: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ?
                                    "center" :
                                    "end"
                            }}>
                                <ButtonEffectShadow
                                    text={componentsParams.isDoctorMember ? "حذف" : "اضافه"}
                                    onclick={componentsParams.isDoctorMember ? deleteDoctorFromTomorboard : AddDoctorToTomorboard}
                                    colors={componentsParams.isDoctorMember ? colors.panel.buttonBackgroundRed : colors.panel.buttonBackgroundGreen}
                                    iconEnded={componentsParams.isDoctorMember ? <CancelOutlined /> : <AddRounded />}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
                    </GridFromMUI>
                </div>

            </div>



        </div>
    );
}
export default RowOfTomorboardsMember;