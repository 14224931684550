import { styled as styledMUI } from "@mui/material/styles";
import colors from "../../../../config/colors";
import { InterfacePatientRequestRow } from "./interfacePatientRequestRow";
import links from "../../../../config/links";
import { ArrowBackRounded, Person2Rounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { Grid, useTheme } from "@mui/material";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import {ImageSquare} from "../../../../components/imageSquare/ImageSquare";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
// import moment from "moment";
import moment from "moment-jalaali";

function PatientRequestRow(paramsComponent: InterfacePatientRequestRow) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const removeThePatientRequest = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.patient.removePatientRequest;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.idPatient}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfacePatientRequestRow;

                paramsComponent.callbackForReload()
                dispatch(ShowAlert({ text: "درخواست حذف شد.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const approveThePatientRequest = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.patient.approvePatientRequest;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.idReuqest}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfacePatientRequestRow;

                paramsComponent.callbackForReload()
                dispatch(ShowAlert({ text: "اضافه شد.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%", display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "8px calc(1vw + 12px)",
            alignItems: paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
            justifyContent: "start",
            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
            borderRadius: "32px",

            background: colors.panel.doctor.account.step.background
        }}>
            {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ?

                <div style={{
                    width: "calc(10vw + 32px)",
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        width: "100%", height: "100%", position: "absolute",
                        boxSizing: "border-box",
                        borderRadius: "12px",
                        overflow: "hidden",
                        border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                    }}>
                        {/* <div style={{
                            width: "fit-content", height: "fit-content",
                            borderRadius: "12px",
                            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                        }}> */}
                        {paramsComponent.img ?
                            <img src={`${paramsComponent.img}`} style={{
                                width: "100%",
                            }} /> :
                            <Person2Rounded
                                style={{
                                    color: colors.panel.doctor.patientsList.selector.color,
                                    cursor: "pointer",
                                    width: "100%", height: "100%",
                                    fontSize: "27px",
                                    transition: ".2s all ease"
                                }} />
                        }
                        {/* </div> */}
                    </div>
                    <div style={{ paddingBottom: "100%" }} />
                </div>
                : undefined}
            <h5 style={{
                color: "white", fontSize: "1.2rem", fontWeight: "200",
                margin: "calc(1vh + 8px) 4px"
            }}>
                {`${paramsComponent.name},${paramsComponent.family}`}
            </h5>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "start", gap: "calc(1vw + 8px)"
            }}>
                {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? undefined :
                    <div style={{
                        width: "calc(32px + 13rem + 2vh)",
                        position: "relative",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        paddingBottom: "calc(12px + 3vw)",
                    }}>
                        <div style={{
                            width: "100%", height: "100%", position: "absolute",
                            overflow: "hidden"

                        }}>
                            <div style={{
                                height: "fit-content",
                            }}>
                                {paramsComponent.img ?
                                    <ImageSquare img={`${paramsComponent.img}`} width={"100%"} borderRadius="8px" border="1px solid white" /> :
                                    <ImageSquare iconSvg={Person2Rounded}
                                        borderRadius="8px" border="1px solid white"
                                        color={colors.panel.doctor.patientsList.selector.color} width={"100%"}
                                        fontsizeIcon="calc(16px + 10rem + 1vh)" />
                                }
                            </div>
                        </div>
                        <div style={{ paddingBottom: "100%" }} />
                    </div>
                }
                <div style={{ flexGrow: 1, alignSelf: "stretch", display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "center" }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 4 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRequestRow.timeAccepted}{": "}
                                {`${moment.unix(paramsComponent.timeAccepted).format("jYYYY/jM/jD")}`}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"کدملی"}{": "}
                                {paramsComponent.national_id}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"شماره موبایل"}{": "}
                                {paramsComponent.phone}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"شماره تماس"}{": "}
                                {paramsComponent.landline}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", boxSizing: "border-box",
                                // paddingInlineStart: "calc(15vh + calc(1vw + 8px))" 
                                paddingInline: "8px",
                            }}>
                                <div style={{
                                    width: "100%", boxSizing: "border-box", height: "1px"
                                    , background: colors.panel.doctor.patientsList.selector.color
                                }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%", gap: "calc(1vw + 8px)", alignItems: "center",
                                justifyContent: paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ?
                                    "center" :
                                    "end"
                            }}>
                                <ButtonEffectShadow
                                    size="small"
                                    colors={colors.panel.buttonBackgroundGreen}
                                    text={confComps.panel.doctor.patientsList.patientRequestRow.acceptRequest.text}
                                    onclick={approveThePatientRequest} />
                                <ButtonEffectShadow
                                    size="small"
                                    colors={colors.panel.buttonBackgroundRed}
                                    iconEnded={<ArrowBackRounded />}
                                    text={confComps.panel.doctor.patientsList.patientRequestRow.rejectRequest.text}
                                    onclick={removeThePatientRequest} />
                            </div>
                        </Grid>
                    </GridFromMUI>
                </div>

            </div>



        </div>
    );
}
export default PatientRequestRow;