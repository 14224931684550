import { Theme, useTheme } from "@mui/material/styles";;

// Define SupportedMethods type as filter type of methods axios use case.
export enum SupportedMethods {
    get = "get",
    post = "post",
    put = "put",
    delete = "delete",
    patch = "patch"
};
export const baseUrl = "https://tumorboard-api.niktech.org"
export const wsBaseUrl = "wss://tumorboard-api.niktech.org/ws"
// export const baseUrl = "https://tb-api.niktech.org"
// export const wsBaseUrl = "wss://tb-api.niktech.org/ws"
// Put your new endpoint like here.
export const HandlerLangUrl = (url: string, mtheme: any) => {
    return (`${baseUrl}${mtheme.direction == "rtl" ? "/fa" : "/en"}${url}`)
}
export interface interfaceUrl {
    url: string;
    method: SupportedMethods;
    isTokenNecessary: boolean;
    isFileInclude: boolean;

}
export const urlsList = {

    auth: {
        register: {
            generateOTP: { url: `/users/auth/generate-otp/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        },
        refreshToken: { url: `/accounts/users/auth/refresh/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        setPassword: { url: `/users/auth/set-password/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },

    },
    panel: {
        doctor: {
            register: {
                validateOTP: { url: `/users/doctors/auth/validate-otp/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            },
            login: { url: `/users/doctors/auth/login/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            account: {
                getInformation: { url: `/users/doctors/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                modifyAccount: { url: `/users/doctors/info/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
                ChangePassword: { url: `/users/auth/change-password/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },

            },
            patient: {
                getMedicalRecordOfMyPatient: { url: `/users/doctors/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getAllConversationRecordOfMyPatient: { url: `/users/doctors/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                postConversationRecordForMyPatient: { url: `/users/doctors/patients/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                getCommentRecordOfMyPatient: { url: `/tumorboard/doctors/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getTreatmentProcessesRecordOfMyPatient: { url: `/tumorboard/doctors/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },

                getListAllPatient: { url: `/users/doctors/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getListMyRequestToTomorboard: { url: `/tumorboard/doctors/boards/requests/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getListAllPatientsRequest: { url: `/users/doctors/patients/requests/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                removePatientRequest: { url: `/users/doctors/patients/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                approvePatientRequest: { url: `/users/doctors/patients/requests/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            },
            tomorboard: {
                getActiveTomorboard: { url: `/tumorboard/doctors/boards/all/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                postMakeRequestToTomorboard: { url: `/tumorboard/doctors/boards/requests/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                getMyTomorboard: { url: `/tumorboard/doctors/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                meeting: {
                    getlistAllMeetingOfMyDoctor: { url: `/tumorboard/doctors/boards/meetings/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                }
            }
        },
        patient: {
            register: {
                validateOTP: { url: `/users/patients/auth/validate-otp/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            },
            login: { url: `/users/patients/auth/login/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            account: {
                getInformation: { url: `/users/patients/info/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                modifyAccount: { url: `/users/patients/info/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
                ChangePassword: { url: `/users/auth/change-password/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },

            },
            doctorList: {
                getAllDoctor: { url: `/users/patients/doctors/all/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                postRequestToDoctor: { url: `/users/patients/doctors/requests/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                // getMyDoctor:{ url: `/users/patients/doctors/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getMyRequest: { url: `/users/patients/doctors/requests/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                removeMyRequest: { url: `/users/patients/doctors/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
            },
            myFile: {
                createMedicalRecordAsFile: { url: `/users/patients/medical-records/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: true },
                getMedicalRecord: { url: `/users/patients/medical-records/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getCommentRecord: { url: `/tumorboard/patients/boards/comments/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getTreatmentProcessesRecord: { url: `/tumorboard/patients/treatment-processes/?page=1&page_size=1000`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                deleteMedicalRecord: { url: `/users/patients/medical-records/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },

            }

        },
        secretary: {
            login: { url: `/users/secretaries/auth/login/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            doctor: {
                getListAllDoctor: { url: `/users/secretaries/doctors/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
            },
            patient: {
                getListAllPatients: { url: `/users/secretaries/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getPatientsMedicalRecord: { url: `/users/secretaries/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getPatientsCommentRecord: { url: `/tumorboard/secretaries/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getPatientsTreatmentProcesses: { url: `/tumorboard/secretaries/patients/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                postCreatePatient: { url: `/users/secretaries/patients/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
            },
            tomorboard: {
                getListAllTomorboard: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getListAllRequestOfPatient: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                getDataOfOneTomorboard: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                patchDataOfOneTomorboard: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
                postDoctorForAdd: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                deleteDoctorFromTomorboard: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                deleteRequestFromTomorBoard: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                addDoctorToTomorboard: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                meeting: {
                    getlistOfMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                    addPatientToMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    getOneMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                    postCreateMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    PatchOneMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.patch, isTokenNecessary: true, isFileInclude: false },
                    postPatientToTheMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    deleteRequestFromMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                    postProcessForPatient: { url: `/tumorboard/secretaries/patients/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    postCommentForPatient: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    getTheCommentForPatient: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.get, isTokenNecessary: true, isFileInclude: false },
                    addDoctorToTheMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.post, isTokenNecessary: true, isFileInclude: false },
                    deleteDoctorFromTheMeeting: { url: `/tumorboard/secretaries/boards/`, method: SupportedMethods.delete, isTokenNecessary: true, isFileInclude: false },
                }
            }
        },
        admin: {
            login: { url: `/users/admins/auth/login/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        }
    }
};