import { useState, useEffect } from "react";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import colors from "../../../../config/colors";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { interfaceListOfMemberTomorboard } from "./interfaceListOfMemberTomorboard";
import { interfaceServerGetDocotorsList } from "./interfaceSettingTomorboard";
import RowOfTomorboardsMember from "./rowOfTomorboardsMember/RowOfTomorboardsMember";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import EditText from "../../../../components/editText/EditText";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";

function ListOfMemberTomorboard(componentsParams: interfaceListOfMemberTomorboard) {
    const [getAllDoctorsList, setAllDoctorsList] = useState<interfaceServerGetDocotorsList>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const [getSearch, setSearch] = useState<string>("");
    const profileInStore = useSelector(SelectedProfile);
    const getDoctorListFromServer = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.doctor.getListAllDoctor;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceServerGetDocotorsList;

                setAllDoctorsList(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getDoctorListFromServer();
    }, []);
    return (
        <div style={{
            display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "space-between",
            gap: "calc(1vh + 12px)", background: "#21325E",
            height: "100%",
        }}>
            <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                width: "100%",
                justifyContent: "center",
                background: "#21325E",
            }}>

                <EditText
                    title={""}
                    hintText="جستجو"
                    onChangeValue={setSearch}
                    value={getSearch}
                    styledTextField={{
                        border: "1px solid #fff",
                        borderRadius: "555px",
                        boxSizing: "border-box",
                        alignSelf: componentsParams.typeOfDisplay < enumListSizeDevices.tablet ? "center" : "start",
                        color: colors.panel.doctor.account.step.edittext.color,
                        background: colors.panel.doctor.account.step.edittext.background,

                    }}
                    sizeWidth="small"
                    isNecessary={false}
                    isMultiLine={false}
                    isSmallTitle={true}
                    isColorDark={false}
                    isSmallHintShow={false} />


            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "8px",

            }}>
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start",
                    gap: "calc(1vh + 16px)"
                }}>
                    {
                        componentsParams.data.members.filter(doc =>
                            getSearch.length == 0 || doc.last_name.includes(getSearch) || doc.first_name.includes(getSearch) || (doc.phone ?? "").includes(getSearch)
                        ).map((doc, index, arr) => {
                            return (
                                <RowOfTomorboardsMember
                                    typeOfDisplay={componentsParams.typeOfDisplay}
                                    national_id={"???"}
                                    phone={doc.phone}
                                    address={doc.address}
                                    email={doc.email}
                                    landline={doc.landline}
                                    province={doc.province}
                                    city={doc.city}
                                    id={doc.id}
                                    first_name={doc.first_name}
                                    last_name={doc.last_name}
                                    gender={doc.gender}

                                    idTomorBoard={componentsParams.data.id}
                                    callbackRefresh={componentsParams.callbackRefresh}
                                    isDoctorMember={true} />
                            )
                        })
                    }
                    {
                        getAllDoctorsList?.data.data.filter(doc =>
                            getSearch.length == 0 || doc.last_name.includes(getSearch) || doc.first_name.includes(getSearch) || (doc.phone ?? "").includes(getSearch)
                        ).
                            filter((emptyDoc, index, arr) => {
                                if (componentsParams.data.members.filter((member) => member.id == emptyDoc.id).length >= 1) {
                                    return undefined;
                                }
                                return emptyDoc;
                            }).map((doc, index, arr) => {
                                return (
                                    <RowOfTomorboardsMember
                                        typeOfDisplay={componentsParams.typeOfDisplay}
                                        national_id={doc.national_id}
                                        phone={doc.phone}
                                        address={doc.address}
                                        email={doc.email}
                                        landline={doc.landline}
                                        province={doc.province}
                                        city={doc.city}
                                        id={doc.id}
                                        first_name={doc.first_name}
                                        last_name={doc.last_name}
                                        gender={doc.gender}

                                        idTomorBoard={componentsParams.data.id}
                                        callbackRefresh={componentsParams.callbackRefresh}
                                        isDoctorMember={false} />
                                )
                            })
                    }
                </div>
            </div>
        </div>
    )
}
export default ListOfMemberTomorboard;

