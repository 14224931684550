import { Button, IconButton } from "@mui/material";
import colors from "../../config/colors";
import { enumArrow, interfaceButtonArrow } from "./interfaceButtonArrow";
import { styled as styledMUI } from "@mui/material/styles";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";

function ButtonArrow(componenetParams: interfaceButtonArrow) {
    const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

        pointerEvents: "none",
        width: "120%",
        height: "101%",
        zIndex: "-1",
        transformOrigin: "0% 100%",
        transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
        transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
    }));
    const ButtonMain = styledMUI(IconButton)(({ theme }) => ({
        color: componenetParams.colors ? componenetParams.colors.text : colors.panel.buttonYellowBackground.text,
        background: componenetParams.colors ? componenetParams.colors.background : colors.panel.buttonYellowBackground.background,
        pointerEvents: "auto",
        borderRadius: "50px",
        textAlign: "center",
        border: "1px solid",
        overflow: "hidden",
        // position: "relative",
        position: componenetParams.styleMainDiv?.position?"relative":"absolute",
        zIndex: "2",
        '&:hover': {
            color: componenetParams.colors ? componenetParams.colors.hoverText : colors.panel.buttonYellowBackground.hoverText,
            'div': {
                position:componenetParams.styleMainDiv?.position?"absolute":"unset",
                // position: "absolute",
                top: "0",
                left: "0",
                background: componenetParams.colors ? componenetParams.colors.hoverBackground : colors.panel.buttonYellowBackground.hoverBackground,
                zIndex: "-1",
                transform: "rotate(0deg) translate3d(0, 0em, 0)",
            }
        }
    }));

    return (
        <ButtonMain style={{ width: componenetParams.width }} onClick={componenetParams.onClick} >
            <ShadowButtonProducts />
            <div style={{ paddingBottom: "100%" }} />
            <div style={{
                position: "absolute", width: "100%", height: "100%", display: "flex", flexDirection: "column",
                top: 0, left: 0, alignItems: "center", justifyContent: "center"
            }}>
                {
                    componenetParams.direction == enumArrow.left ?
                        <ArrowBackRounded /> :
                        componenetParams.direction == enumArrow.right ?
                            <ArrowForwardRounded /> :
                            componenetParams.text

                }
            </div>

        </ButtonMain>
    );
};
export default ButtonArrow;