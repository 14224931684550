import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../../../components/MUIConfig/GridFromMUI";
import ButtonEffectShadow from "../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import EditText from "../../../../../../components/editText/EditText";
import colors from "../../../../../../config/colors";
import { interfaceResSettingMeetings, interfaceSettingMeetings } from "./interfaceSettingMeetings";
import { useEffect, useState } from "react";
// import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from '@mui/x-date-pickers/AdapterDateFnsJalali';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment";
import { DateTimePickerFromMUI } from "../../../../../../components/MUIConfig/DateTimePickerFromMUI";
import DateTimePickMe from "../../../../../../components/dateTimePick/DateTimePickMe";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { interfaceApiEntityMeeting } from "../../meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";

function ConfigParamsMeetings(componentsParams: interfaceResSettingMeetings) {
    // const location = useLocation().state as interfaceRowListMeetingsOfTomorBoard;
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);

    const [getTitleOfMeeting, setTitleOfMeeting] = useState<string>(componentsParams.serverData.title);
    const [getDesciptionOfMeeting, setDesciptionOfMeeting] = useState<string>(componentsParams.serverData.description);
    const [getStartDate, setStartDate] = useState<number | undefined>(componentsParams.serverData.start_datetime);
    const [getDurationInSecound, setDurationInSecound] = useState<number>(componentsParams.serverData.end_datetime - componentsParams.serverData.start_datetime);
    // const [getEndDate, setEndDate] = useState<number>(componentsParams.serverData.end_datetime);
    const patchApiUpdateThisMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.PatchOneMeeting;
        if (!getStartDate) {
            return
        }
        const sendObj = {
            "title": getTitleOfMeeting,
            "description": getDesciptionOfMeeting,
            "start_datetime": getStartDate,
            "end_datetime": (getStartDate + getDurationInSecound)
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${componentsParams.idTomorBoard}/meetings/${componentsParams.serverData.id}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, sendObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                componentsParams.callbackRefresh();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setTitleOfMeeting(componentsParams.serverData.title);
        setDesciptionOfMeeting(componentsParams.serverData.description);
        setStartDate(componentsParams.serverData.start_datetime);
        setDurationInSecound(componentsParams.serverData.end_datetime - componentsParams.serverData.start_datetime);
        console.log(componentsParams)
    }, [componentsParams.serverData])
    return (
        <div style={{
            width: "-webkit-fill-available",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>

            <div style={{
                width: "100%",
                boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>

                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <h6 style={{ color: colors.panel.doctor.account.step.header }} />
                    </Grid>

                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditText
                            title={"عنوان"}
                            onChangeValue={setTitleOfMeeting}
                            value={getTitleOfMeeting}
                            // type="password"
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing:"border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditText
                            title={"مدت زمان جلسه (ساعت)"}
                            onChangeValue={(strVar) => { setDurationInSecound(Number(strVar) * 3600) }}
                            value={`${getDurationInSecound / 3600.0}`}
                            type="number"
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing:"border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        {/* <LocalizationProvider dateAdapter={AdapterDate}> */}
                        {/* <DateTimePicker label="Date Picker" defaultValue={new Date(componentsParams.serverData.start_datetime)} /> */}
                        {/* </LocalizationProvider> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDateFnsJalali}> */}

                        <DateTimePickMe
                            onChange={(newDate: any) => {
                                console.log(newDate);
                                if (newDate) {
                                    const posixTimestamp = newDate.getTime(); // Get milliseconds since Unix epoch
                                    setStartDate(posixTimestamp / 1000);
                                } else {
                                    setStartDate(undefined);
                                }
                            }}
                            // value={moment.unix(componentsParams.serverData.start_datetime).format("jYYYY/jM/jD HH:mm")}
                            valueSecound={moment.unix(componentsParams.serverData.start_datetime).unix() * 1000}
                            title={"زمان شروع جلسه"}
                            isNecessary={false}
                            backGroundColor=""
                            isSmallTitle={true}
                            styleMain={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing:"border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            isColorDark={false}
                            isSmallHintShow={false}
                        />
                        {/* </LocalizationProvider> */}
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <EditText
                            title={"توضیحات"}
                            onChangeValue={setDesciptionOfMeeting}
                            value={getDesciptionOfMeeting}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing:"border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                            <ButtonEffectShadow text={"به روزرسانی"}
                                onclick={patchApiUpdateThisMeeting} />
                        </div>
                    </Grid>
                </GridFromMUI>
            </div>

        </div>
    )
};
export default ConfigParamsMeetings;