import { useLocation } from "react-router-dom";
import { interfaceApiGetListOfMeetingsTheTomorboardForAddPatient, interfaceListMeetingsOfTomorboardForAddPatient } from "./interfaceListMeetingsOfTomorboardForAddPatient";
import { interfaceRowRequestOfPatient } from "../requestOfPatient/interfaceRowRequestOfPatient";

import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import EditText from "../../../../../components/editText/EditText";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import colors from "../../../../../config/colors";
import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceGetTomorboardsListFromServer } from "../interfaceTomorboardsList";

import { interfaceGetOneTomorboardFromServer } from "../../settingTomorboard/interfaceSettingTomorboard";
import { Person2Rounded } from "@mui/icons-material";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import RowListMeetingsOfTomorboardForAddPatient from "./RowListMeetingsOfTomorboardForAddPatient";

function ListMeetingsOfTomorboardForAddPatient(pagesParams: interfaceListMeetingsOfTomorboardForAddPatient) {
    const location = useLocation().state as interfaceRowRequestOfPatient;
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getRequestOfListMeeting, setRequestOfListMeeting] = useState<interfaceApiGetListOfMeetingsTheTomorboardForAddPatient>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const getApiListMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getlistOfMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${location.idTomorBoard}/` + "meetings/?page=1&page_size=1000", mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiGetListOfMeetingsTheTomorboardForAddPatient;

                setRequestOfListMeeting(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getApiListMeeting();
        // getApiDataOfOneTomorboard();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            border: "1px solid #ffffffff",
            background: colors.blue.darkbackground,
            borderRadius: "min(10vw , 32px)",
            padding: "min(5vw , 24px)",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            overflowY: "auto",
            overflowX: "hidden",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap', rowGap: "calc(8px + 1vh)", width: "100%", flexDirection: "row", alignItems: "center",
                justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between"
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", lineHeight: "2.5rem", fontWeight: "300" }}>
                    {" لیست جلسات توموربورد "}"{location.nameTomorBoard}"
                </h5>
            </div>
            <div style={{ width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)" }}>
                <div style={{
                    width: "100%", background: colors.blue.darkbackground, borderRadius: "32px", border: "1px solid #10107e1f",
                    display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
                    padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
                }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <div style={{
                                width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                                , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                            }}>
                                {
                                    location.serverData.doctor.profile_pic ?
                                        <ImageSquare width={"4rem"} borderRadius="8px"
                                            border="1px solid #ccc"
                                            img={`${baseUrl}/` + location.serverData.doctor.profile_pic}
                                            isScaleable={{}}
                                        />
                                        :
                                        <ImageSquare iconSvg={Person2Rounded}
                                            width={"4rem"}
                                            color={colors.panel.doctor.patientsList.selector.color}
                                            fontsizeIcon="2rem"
                                            borderRadius="8px" border="1px solid white" />
                                }

                                <div style={{
                                    display: "flex", flexDirection: "column", alignItems: "start",
                                    justifyContent: "space-between",
                                    color: "white",
                                }}>
                                    <p>پزشک:{location.serverData.doctor.first_name},{location.serverData.doctor.last_name}</p>
                                    <p style={{ color: colors.yellowHeader }}>شماره تماس:{location.serverData.doctor.phone}</p>

                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <div style={{
                                width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                                , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                            }}>
                                {
                                    location.serverData.patient.profile_pic ?
                                        <ImageSquare width={"4rem"} borderRadius="8px"
                                            border="1px solid #ccc"
                                            img={`${baseUrl}/` + location.serverData.patient.profile_pic}
                                            isScaleable={{}}
                                        />
                                        :
                                        <ImageSquare iconSvg={Person2Rounded}
                                            width={"4rem"}
                                            color={colors.panel.doctor.patientsList.selector.color}
                                            fontsizeIcon="2rem"
                                            borderRadius="8px" border="1px solid white" />
                                }

                                <div style={{
                                    display: "flex", flexDirection: "column", alignItems: "start",
                                    justifyContent: "space-between",
                                    color: "white",
                                }}>
                                    <p>بیمار:{location.serverData.patient.first_name},{location.serverData.patient.last_name}</p>
                                    <p style={{ color: colors.yellowHeader }}>شماره تماس:{location.serverData.patient.phone}</p>

                                </div>

                            </div>
                        </Grid>

                        {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}:
                        {componentsParams.description}
                    </p>
                </Grid> */}
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", height: "2px", boxSizing: "border-box",
                                paddingInline: "calc(1vw + 8px)"
                            }} >
                                <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%",
                                display: "flex", flexDirection: "column",
                                alignItems: "start",
                                boxSizing: "border-box"
                            }}>
                                <GridFromMUI container
                                    justifyContent="space-between" alignItems="flex-start"
                                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                                    {getRequestOfListMeeting?.data.data
                                        // .filter((Tm, index, arra) => {
                                        //     if (getSearchStr.length > 0 && Tm.doctor.first_name.indexOf(getSearchStr) && Tm.doctor.last_name.indexOf(getSearchStr) && Tm.patient.first_name.indexOf(getSearchStr) && Tm.patient.last_name.indexOf(getSearchStr)) {
                                        //         return undefined
                                        //     }
                                        //     return Tm;
                                        // })
                                        .map((meeting, index, array) => {
                                            return (
                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                                                    <RowListMeetingsOfTomorboardForAddPatient
                                                        isactive={(meeting.end_datetime * 1000) > Date.now()}
                                                        serverData={meeting}
                                                        idTomorboard={location.idTomorBoard}
                                                        request_id={location.serverData.id} />

                                                </Grid>
                                            )
                                        })
                                    }
                                </GridFromMUI>

                            </div>
                        </Grid>

                    </GridFromMUI>

                </div >

            </div>
        </div>
    )
}
export default ListMeetingsOfTomorboardForAddPatient;