import { styled as styledMUI } from "@mui/material/styles";
import colors from "../../../../config/colors";
import { InterfacePatientRow } from "./interfacePatientRow";
import links from "../../../../config/links";
import { ArrowBackRounded, CancelRounded, Person2Rounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { Grid } from "@mui/material";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { useNavigate } from "react-router-dom";
import { DialogSendRequestToTomorBoard } from "./dialogSendRequestToTomorBoard.tsx/DialogSendRequestToTomorBoard";
import { useState } from "react";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";

function PatientRow(paramsComponent: InterfacePatientRow) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const [getShowDialogChooseTomorBoard, setShowDialogChooseTomorBoard] = useState<number>(-1);
    const [getIsListOfRequestShow, setIsListOfRequestShow] = useState<boolean>(false);
    return (
        <div style={{
            width: "100%", display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "8px calc(1vw + 12px)",
            alignItems: paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
            justifyContent: "start",
            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
            borderRadius: "32px",

            background: colors.panel.doctor.account.step.background
        }}>
            {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ?

                <div style={{
                    width: "calc(10vw + 32px)",
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        width: "100%", height: "100%", position: "absolute",
                        boxSizing: "border-box",
                        borderRadius: "12px",
                        overflow: "hidden",
                        border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                    }}>
                        {/* <div style={{
                            width: "fit-content", height: "fit-content",
                            borderRadius: "12px",
                            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                        }}> */}
                        {paramsComponent.img ?
                            <ImageSquare img={`${paramsComponent.img}`} width={"100%"}
                                borderRadius="8px" border="1px solid white"
                                isScaleable={{}}
                            /> :
                            <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                color={colors.panel.doctor.patientsList.selector.color}
                                fontsizeIcon="27px"
                                borderRadius="8px" border="1px solid white"
                            />
                        }
                        {/* </div> */}
                    </div>
                    <div style={{ paddingBottom: "100%" }} />
                </div>
                : undefined}
            <h5 style={{
                color: "white", fontSize: "1.2rem", fontWeight: "200",
                margin: "calc(1vh + 8px) 4px"
            }}>
                {`${paramsComponent.name},${paramsComponent.family}`}
            </h5>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "start", gap: "calc(1vw + 8px)"
            }}>
                {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? undefined :
                    <div style={{
                        width: "calc(32px + 13rem + 2vh)",
                        position: "relative",
                        overflow: "hidden",
                        boxSizing: "border-box",
                        paddingBottom: "calc(12px + 3vw)",
                    }}>
                        <div style={{
                            width: "100%", height: "100%", position: "absolute",
                            overflow: "hidden"

                        }}>
                            <div style={{
                                height: "fit-content",
                                borderRadius: "12px",
                            }}>
                                {paramsComponent.img ?
                                    <ImageSquare img={`${paramsComponent.img}`} width={"100%"}
                                        borderRadius="8px" border="1px solid white"
                                        isScaleable={{}} /> :
                                    <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                        color={colors.panel.doctor.patientsList.selector.color}
                                        fontsizeIcon="270px"
                                        borderRadius="8px" border="1px solid white" />
                                }
                            </div>
                        </div>
                        <div style={{ paddingBottom: "100%" }} />
                    </div>
                }
                <div style={{ flexGrow: 1, alignSelf: "stretch", display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "center" }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 4 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"شماره موبایل"}{": "}
                                {paramsComponent.phone}
                            </p>
                        </Grid>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"شماره تماس"}{": "}
                                {paramsComponent.landline}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.address}:
                                {paramsComponent.address}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"کد ملی"}{": "}
                                {paramsComponent.national_id}
                            </p>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", boxSizing: "border-box",
                                // paddingInlineStart: "calc(15vh + calc(1vw + 8px))" 
                                paddingInline: "8px",
                            }}>
                                <div style={{
                                    width: "100%", boxSizing: "border-box", height: "1px"
                                    , background: colors.panel.doctor.patientsList.selector.color
                                }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <ButtonEffectShadow
                                size="small"
                                styleMainDiv={{ width: "100%" }}
                                iconEnded={<ArrowBackRounded />}
                                text={confComps.panel.doctor.patientsList.patientRow.buttomMedicalRecord.text}
                                onclick={() => {
                                    navigate(links.doctor.fileOfmyPatient.comeFromListMyPetients + paramsComponent.id + "/")
                                }} />
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>

                            {/* <ButtonEffectShadow
                                styleMainDiv={{ width: "100%" }}
                                size="small"
                                colors={colors.panel.buttonYellowText}
                                text={confComps.panel.doctor.patientsList.patientRow.buttomRequest.text}
                                onclick={() => {
                                    setShowDialogChooseTomorBoard(paramsComponent.id)
                                }} /> */}
                            <ButtonEffectShadow
                                styleMainDiv={{ width: "100%" }}
                                size="small"
                                colors={getIsListOfRequestShow ? colors.panel.buttonOrangeText : colors.panel.buttonYellowText}
                                iconEnded={getIsListOfRequestShow ? <CancelRounded /> : undefined}
                                text={getIsListOfRequestShow ? "بستن لیست" : "مشاهده درخواست‌ها"}
                                onclick={() => {
                                    setIsListOfRequestShow(!getIsListOfRequestShow)
                                }} />
                        </Grid>
                        {getIsListOfRequestShow ?
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <div
                                    style={{
                                        flexGrow: 1, alignSelf: "stretch",
                                        display: "flex", flexDirection: "column",
                                        justifyContent: "end", alignItems: "center"
                                    }}
                                >
                                    <GridFromMUI container
                                        justifyContent="space-between" alignItems="flex-start"
                                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 4 }}
                                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <div style={{
                                                width: "100%", boxSizing: "border-box",
                                                // paddingInlineStart: "calc(15vh + calc(1vw + 8px))" 
                                                paddingInline: "8px",
                                            }}>
                                                <div style={{
                                                    width: "100%", boxSizing: "border-box", height: "1px"
                                                    , background: colors.panel.doctor.patientsList.selector.color
                                                }}>

                                                </div>
                                            </div>
                                        </Grid>
                                        {paramsComponent.listRequestOfThisPatient.flatMap((request, index, array) => {
                                            return [
                                                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                                                    <div style={{
                                                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                                        color: "white"
                                                    }}>
                                                        <p>{"توموربورد : "}{request.tumorboard.name}</p>
                                                    </div>
                                                </Grid>,
                                                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                                                    <div style={{
                                                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                                        color: "white"
                                                    }}>
                                                        <p>{"وضعیت : "}{confComps.unit.categoryRequestDoctorToTomorboard.filter(item => item.value.toUpperCase().includes(request.status.toUpperCase()))[0].text}</p>
                                                    </div>
                                                </Grid>,
                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                    <div style={{
                                                        width: "100%", boxSizing: "border-box",
                                                        // paddingInlineStart: "calc(15vh + calc(1vw + 8px))" 
                                                        paddingInline: "8px",
                                                    }}>
                                                        <div style={{
                                                            width: "100%", boxSizing: "border-box", height: "1px"
                                                            , borderBlockEnd:"1px dashed #e0e0e0"
                                                        }}>

                                                        </div>
                                                    </div>
                                                </Grid>
                                            ]
                                        })

                                        }
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                                            <ButtonEffectShadow
                                                styleMainDiv={{ width: "100%" }}
                                                size="small"
                                                colors={colors.panel.buttonYellowText}
                                                text={"درخواست توموربورد جدید"}
                                                onclick={() => {
                                                    setShowDialogChooseTomorBoard(paramsComponent.id)
                                                }} />
                                        </Grid>
                                    </GridFromMUI>
                                </div>
                            </Grid> : undefined
                        }
                    </GridFromMUI>

                </div>

            </div>


            <DialogSendRequestToTomorBoard idPatientOfSelected={getShowDialogChooseTomorBoard}
                typeOfDisplay={paramsComponent.typeOfDisplay}
                callbackOnCloseDialog={() => {
                    setShowDialogChooseTomorBoard(-1);
                    paramsComponent.callbackRefreshPage();
                }}
            />
        </div >
    );
}
export default PatientRow;