import { Button, FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import links from "../../../config/links";
import { interfacePatientAccountPage, interfaceServerGetInformation } from "./interfacePatientAccountPage";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import colors from "../../../config/colors";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import EditText, { DivListAllItem, PText } from "../../../components/editText/EditText";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ReactNode, useEffect, useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, baseUrl, urlsList } from "../../../api/UrlsList";
import { RequestHandler } from "../../../api/API";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { SelectedProfile, SetTokenAndRefreshToken } from "../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { useDispatch, useSelector } from "react-redux";
import { EnumGender } from "../../../utils/Gender";
import { LabelInputFromMUI } from "../../../components/MUIConfig/LabelInputFromMUI";
import { SelectFromMUI } from "../../../components/MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../../components/MUIConfig/MenuItemFromMUI";
import { getValue } from "@testing-library/user-event/dist/utils";
import FilesDragAndDrop from "../../../components/FileDragAndDrop/FileDragAndDrop";
import { ImageSquare } from "../../../components/imageSquare/ImageSquare";
import { Person2Rounded } from "@mui/icons-material";

function PatientAccountPage(pageParams: interfacePatientAccountPage) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAccountFirstName, setAccountFirstName] = useState<string>("");
    const [getAccountLastName, setAccountLastName] = useState<string>("");
    const [getAccountFatherName, setAccountFatherName] = useState<string>("");
    const [getAccountnationalId, setAccountnationalId] = useState<string>("");
    const [getAccountCity, setAccountCity] = useState<string>("");
    const [getAccountLandline, setAccountLandline] = useState<string>("");
    const [getAccountGender, setAccountGender] = useState<string>();
    const [getAccountAddress, setAccountAddress] = useState<string>("");
    const [getAccountPhone, setAccountPhone] = useState<string>("");
    const [getOldPassword, setOldPassword] = useState<string>("");
    const [getPassword, setPassword] = useState<string>("");
    const [getRePassword, setRePassword] = useState<string>("");
    const [getProfileImage, setProfileImage] = useState<(File)>();
    const [getProfileImageUrl, setProfileImageUrl] = useState<(string)>();
    const handleUpdateInformationOfAccount = async () => {
        dispatch(isVisibilityProgressBar(true));
        const formData = new FormData();

        formData.append("first_name", getAccountFirstName)
        formData.append("last_name", getAccountLastName)
        formData.append("email", "")
        formData.append("national_id", getAccountnationalId)
        if (getProfileImage != null) {
            formData.append("profile_pic", getProfileImage)
        }
        formData.append("landline", getAccountLandline)
        formData.append("gender", getAccountGender?.toString().toUpperCase().includes("F") ? "F" : getAccountGender?.toString().toUpperCase().includes("M") ? "M" : "O")
        formData.append("father_name", getAccountFatherName)
        formData.append("province", "")
        formData.append("city", getAccountCity)
        formData.append("address", getAccountAddress)

        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.patient.account.modifyAccount.url, mtheme),
            urlsList.panel.patient.account.modifyAccount.method, urlsList.panel.patient.account.modifyAccount.isTokenNecessary, formData, profileInStore, true)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: "اطلاعات ثبت شد.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const handleChangePasswordOfAccount = async () => {
        if ((getOldPassword ?? "".length > 0) && (getPassword ?? "".length > 0) && (getPassword?.toString() == getRePassword?.toString())) {

        }
        else {
            dispatch(ShowAlert({ text: "لطفا پسورد خود را به درستی وارد کنید!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        const dataObj = {
            "old_password": getOldPassword,
            "new_password": getPassword,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.patient.account.ChangePassword.url, mtheme),
            urlsList.panel.patient.account.ChangePassword.method, urlsList.panel.patient.account.ChangePassword.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getInformationOfAccountAtFirstTime = async () => {
        console.log("safajoon omad to function", profileInStore)
        dispatch(isVisibilityProgressBar(true));
        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.patient.account.getInformation.url, mtheme),
            urlsList.panel.patient.account.getInformation.method, urlsList.panel.patient.account.getInformation.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceServerGetInformation
                setAccountFirstName(t.data.first_name);
                setAccountLastName(t.data.last_name);
                setAccountFatherName(t.data.father_name ?? "");
                setAccountnationalId(t.data.national_id);
                setAccountCity(t.data.city ?? "");
                setAccountLandline(t.data.landline ?? "");
                setProfileImageUrl(t.data.profile_pic ? (baseUrl + "/" + t.data.profile_pic) : undefined);

                if (t.data.gender.toLocaleUpperCase().includes("F")) {
                    setAccountGender(EnumGender.female);
                }
                else if (t.data.gender.toLocaleUpperCase().includes("M")) {
                    setAccountGender(EnumGender.male);
                }
                else if (t.data.gender.toLocaleUpperCase().includes("O")) {
                    setAccountGender(EnumGender.other);
                }
                else {
                    setAccountGender(undefined)
                }
                setAccountAddress(t.data.address ?? "");
                setAccountPhone(t.data.phone ?? "")
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safajooon")
        getInformationOfAccountAtFirstTime();
    }, []);
    useEffect(() => {
        if (getProfileImage) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setProfileImageUrl(event.target.result as string);
            };

            reader.readAsDataURL(getProfileImage);
        }
    }, [getProfileImage]);
    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        setAccountGender(event.target.value as EnumGender)
    }

    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            overflowY: "auto",
            overflowX: "hidden",
            paddingBlock: "calc(1vh + 16px)"

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap', rowGap: "calc(8px + 1vh)", width: "100%", flexDirection: "row", alignItems: "center",
                justifyContent: pageParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start"
            }}>
                <h5 style={{ color: colors.yellowHeader, fontSize: "2.2rem", fontWeight: "300" }}>
                    {confComps.panel.patient.account.header}
                </h5>
            </div>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: colors.panel.doctor.account.step.background,
                boxSizing: "border-box", padding: "calc(3vh + 12px) 2vw", margin: "calc(3vh + 12px) 0",
                borderRadius: "32px",

            }}>
                <GridFromMUI container
                    justifyContent="center" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <FilesDragAndDrop
                            onUpload={(files) => {
                                console.log(files)
                                files.forEach((value) => {
                                    setProfileImage(value);
                                })
                            }}
                            count={2}
                            formats={['jpg', 'png']}
                            containerStyles={{
                                borderRadius: "4px",
                                boxSizing: "border-box",
                                width: "min(70% , 300px)",
                            }}
                            openDialogOnClick
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                gap: "calc(3vh + 12px)",
                                padding: "calc(3vh + 12px) 8px",
                                alignItems: "center",
                                border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                                borderRadius: "25px",
                                background: colors.panel.doctor.patientsList.selector.background,
                            }}>
                                <p style={{ color: "white", fontSize: "1.5rem", fontWeight: "50", }}>
                                    {`${getAccountFirstName} ${getAccountLastName}`}
                                </p>

                                {getProfileImageUrl ?
                                    <div style={{ width: "min(60% , 200px)" }}>
                                        <ImageSquare img={`${getProfileImageUrl}`}
                                            width={"100%"}
                                            borderRadius="24px" border="1px solid white" />
                                        <div style={{
                                            color: "black", background: colors.yellowHeader
                                            , textAlign: "center", border: "1px solid yellow", borderRadius: "500px", marginTop: "8px",
                                        }}>
                                            تغییر عکس
                                        </div>

                                    </div>
                                    :
                                    <div style={{ width: "min(60% , 200px)" }}>
                                        <ImageSquare iconSvg={Person2Rounded}
                                            borderRadius="24px" border="1px solid white"
                                            color={colors.panel.doctor.patientsList.selector.color}
                                            width="100%"
                                            fontsizeIcon="calc(16px + 10rem + 1vh)" />
                                        <div style={{
                                            color: "black", background: colors.yellowHeader
                                            , textAlign: "center", border: "1px solid yellow", borderRadius: "500px", marginTop: "8px",
                                        }}>
                                            اضافه کردن عکس
                                        </div>
                                    </div>
                                }


                            </div>
                        </FilesDragAndDrop>

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.name}
                            key={confComps.panel.patient.account.name}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}

                            sizeWidth="small"
                            onChangeValue={setAccountFirstName}
                            value={getAccountFirstName}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={"نام خانوادگی"}
                            key={confComps.panel.patient.account.family}
                            value={getAccountLastName}
                            onChangeValue={setAccountLastName}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.fatherName}
                            key={confComps.panel.patient.account.fatherName}
                            value={getAccountFatherName}
                            onChangeValue={setAccountFatherName}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.nationalCode}
                            key={confComps.panel.patient.account.nationalCode}
                            value={getAccountnationalId}
                            onChangeValue={setAccountnationalId}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.phone}
                            key={confComps.panel.patient.account.phone}
                            value={getAccountPhone}
                            onChangeValue={setAccountPhone}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <div style={{
                            flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start",
                            justifyContent: "space-between", width: "100%",
                        }}>
                            <DivListAllItem>
                                <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"جنسیت خود را انتخاب کنید"}</PText>
                            </DivListAllItem>


                            <FormControl
                                style={{
                                    margin: "8px 0px 0px 0px",
                                    color: "white",
                                    width: "100%"
                                }}
                                sx={{ m: 1, minWidth: "150px" }} >
                                <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.patient.account.gender}</LabelInputFromMUI>
                                <SelectFromMUI
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    style={{
                                        color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                                        borderRadius: "555px",

                                    }}
                                    value={`${getAccountGender}`}

                                    label={confComps.panel.patient.doctorsList.category}
                                    onChange={handleChangeDropDown}
                                >

                                    {confComps.unit.enum.gender.map((value, index, array) => {
                                        return (
                                            <MenuItemFromMUI value={value.key}
                                                key={index}>{value.value}</MenuItemFromMUI>
                                        )
                                    })}

                                </SelectFromMUI>
                            </FormControl>
                        </div>

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.city}
                            key={confComps.panel.patient.account.city}
                            value={getAccountCity}
                            onChangeValue={setAccountCity}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.landline}
                            key={confComps.panel.patient.account.landline}
                            value={getAccountLandline}
                            onChangeValue={setAccountLandline}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <EditText
                            title={confComps.panel.patient.account.address}
                            key={confComps.panel.patient.account.address}
                            value={getAccountAddress}
                            onChangeValue={setAccountAddress}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "14px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={true}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                            <ButtonEffectShadow
                                text={confComps.panel.patient.account.buttonSave}
                                onclick={handleUpdateInformationOfAccount}
                            />
                        </div>
                    </Grid>
                </GridFromMUI>

            </div>

            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: colors.panel.doctor.account.step.background,
                boxSizing: "border-box", padding: "2vh 2vw", margin: "2vh 0",
                borderRadius: "32px",

            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.password.oldPassword}
                            key={confComps.panel.patient.account.password.oldPassword}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            onChangeValue={setOldPassword}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2} />
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.password.newPassword}
                            key={confComps.panel.patient.account.password.newPassword}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            onChangeValue={setPassword}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.password.confirmPassword}
                            key={confComps.panel.patient.account.password.confirmPassword}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            onChangeValue={setRePassword}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                            <ButtonEffectShadow
                                text={confComps.panel.patient.account.password.buttonSave}
                                onclick={handleChangePasswordOfAccount}
                            />
                        </div>
                    </Grid>
                </GridFromMUI>
            </div>

        </div>
    )
}
export default PatientAccountPage;