import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, useTheme, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { styled as styledMUI } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AddRounded, CancelRounded, CheckRounded, CompressRounded, DownloadRounded, PlusOneRounded } from "@mui/icons-material";
import { margin } from "@mui/system";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { ButtonFromMUI } from "../../../../components/MUIConfig/ButtonFromMUI";
import { TextFieldFromMUI } from "../../../../components/MUIConfig/TextFieldFromMUI";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import colors from "../../../../config/colors";
import EditText from "../../../../components/editText/EditText";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { DialogFromMUI } from "../../../../components/MUIConfig/DialogFromMUI";
import FilesDragAndDrop from "../../../../components/FileDragAndDrop/FileDragAndDrop";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { EnumSupportRoles } from "../../../../utils/Role";
import { interfaceDialogAddConversationInMyFile } from "./interfaceDialogAddConversationInMyFile";

export const ButtonPay = styledMUI(ButtonFromMUI)(({ theme }) => ({
    color: "red",
    borderColor: "red",
    backgroundColor: "blue",
    boxShadow: `2px 4px 4px gray`,
    ':hover': {
        // boxShadow: `1px 2px 4px ${colors.screens.panel.transactionsList.hoverShadowButton}`,
        // color: colors.screens.panel.transactionsList.hoverColorTextInButton,
        // backgroundColor: colors.screens.panel.transactionsList.hoverBackgroundButtonInCard,

    }
}));

export const ButtonPrice = styledMUI(ButtonFromMUI)(({ theme }) => ({
    // color: colors.screens.panel.transactionsList.colorTextInButton,
    // borderColor: colors.screens.panel.transactionsList.borderColorButtonInCard,
    // backgroundColor: colors.screens.panel.transactionsList.backgroundButtonInCard,
    // boxShadow: `2px 4px 4px ${colors.screens.panel.transactionsList.shadowButton}`,
    ':hover': {
        // boxShadow: `1px 2px 4px ${colors.screens.panel.transactionsList.hoverShadowButton}`,
        // color: colors.screens.panel.transactionsList.hoverColorTextInButton,
        // backgroundColor: colors.screens.panel.transactionsList.hoverBackgroundButtonInCard,

    }
}));



export const DialogAddConversationInMyFile = (paramsDialog: interfaceDialogAddConversationInMyFile) => {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getOpen, setOpen] = useState<boolean>(paramsDialog.isOpen);
    const [getTitle, setTitle] = useState<(string)>("");
    const [getDescription, setDescription] = useState<(string)>("");
    useEffect(() => {
        setOpen(paramsDialog.isOpen)
    }, [paramsDialog.isOpen])
    const handleClose = () => {
        setOpen(false);
        paramsDialog.callbackOnCloseDialog();
    };
    const postConversationRecordForMyPatient = async () => {
        dispatch(isVisibilityProgressBar(true));
        let jsonBody = {
            "title": getTitle,
            "description": getDescription,
        }
        let urlObj = urlsList.panel.doctor.patient.postConversationRecordForMyPatient;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsDialog.idMyPatient}/medical-discussions/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, jsonBody, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsDialog.callbackOnCloseDialog();
                dispatch(ShowAlert({ text: "نظر شما ثبت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const onclickBTNForSubmit = async () => {
        postConversationRecordForMyPatient();
    }

    const handleOnclickCancelDialog = () => {
        paramsDialog.callbackOnCloseDialog();
    }



    return (
        <DialogFromMUI onClose={handleClose} open={getOpen} dir={mTheme.direction} background="#3E497A"
        >
            <div style={{
                display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center",
                minWidth: "50vw"
            }}>
                <h3 style={{ margin: "1rem 16px", color: "white", padding: "0 0px" }}>
                    {"افزودن نظر پزشک"}
                </h3>
                <IconButton
                    onClick={handleOnclickCancelDialog}>
                    <CancelRounded />
                </IconButton>
            </div>
            <div style={{
                padding: "24px calc(16px + 1rem)", display: "flex", flexDirection: "column",
                gap: "calc(2vh + 12px)", justifyContent: "space-around", margin: "8px 12px", borderRadius: "12px", background: "#182546"
            }}>

                <div style={{
                    display: "flex", flexDirection: "column",
                    width: "100%", alignItems: "center", justifyContent: "space-between",
                    gap: "calc(1rem + 8px)",
                }}>
                    <EditText
                        title={"عنوان نظر"}
                        onChangeValue={setTitle}
                        value={getTitle}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                    <EditText
                        title={"توضیحات"}
                        onChangeValue={setDescription}
                        value={getDescription}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "12px",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={true}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />
                    <div style={{ padding: "0.5vh 8px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <ButtonEffectShadow
                            text="ارسال"
                            colors={colors.panel.buttonYellowBackground}
                            onclick={onclickBTNForSubmit}
                        />

                    </div>
                </div>
            </div>
        </DialogFromMUI>
    );
}
