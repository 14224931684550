import { Grid } from "@mui/material";
import { useState } from "react";
import { GridFromMUI } from "../../../../../../components/MUIConfig/GridFromMUI";
import EditText from "../../../../../../components/editText/EditText";
import { enumListSizeDevices } from "../../../../../../config/responsive/configResponsive";
import { interfacePatientsFileMeeting } from "./interfacePatientsFileMeeting";
import colors from "../../../../../../config/colors";
import RowPatientsFileMeeting from "./RowPatientsFileMeeting";
import EmptyList from "../../../../../../components/emptyList/EmptyList";

function PatientsFileMeeting(componentsParams: interfacePatientsFileMeeting) {
    const [getSearchStr, setSearchStr] = useState<(string)>("");
    return (
        <div style={{
            width: "100%", display: "flex",

            height: "100%",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "calc(1vw + 12px)",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap', rowGap: "calc(8px + 1vh)", width: "100%", flexDirection: "row", alignItems: "center",
                justifyContent: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between"
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", lineHeight: "2.5rem", fontWeight: "300" }}>
                    {" لیست پرونده‌های جلسه "}"{componentsParams.serverData.title}"
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {componentsParams.serverData.requests.filter((Tm, index, arra) => {
                        if (getSearchStr.length > 0 && Tm.doctor.first_name.indexOf(getSearchStr) && Tm.doctor.last_name.indexOf(getSearchStr) && Tm.patient.first_name.indexOf(getSearchStr) && Tm.patient.last_name.indexOf(getSearchStr)) {
                            return undefined
                        }
                        return Tm;
                    }).map((requestOfPatient, index, array) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                                <RowPatientsFileMeeting
                                    serverDataOfRequest={requestOfPatient}
                                    idTomorBoard={componentsParams.idTomorBoard}
                                    idMeeting={`${componentsParams.serverData.id}`}
                                    callbackRefresh={componentsParams.callbackRefresh} />

                            </Grid>
                        )
                    })
                    }
                    {componentsParams.serverData.requests.length == 0 ?
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                            <EmptyList text={"هیچ پرونده‌ای عضو این جلسه نیست!!"} />

                        </Grid> : undefined
                    }
                </GridFromMUI>
            </div>
        </div >
    )
}
export default PatientsFileMeeting;