import {styled as styledMUI} from "@mui/material/styles";
import {Button, Card} from "@mui/material";

export const CardFromMUI = styledMUI(Card)(({theme}) => ({
    border: `1px solid #f0f0f011`,
    boxShadow:"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    ':hover': {
        border: `1px solid #f0f0f0E1`,
        boxShadow:"inset 0px 0px 4px #f0f0f041 ,0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
    }
}));