import { styled as styledMUI } from "@mui/material/styles";
import colors from "../../../../config/colors";
import links from "../../../../config/links";
import { ArrowBackRounded, ArrowForwardRounded, Person2Rounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { Grid, useTheme } from "@mui/material";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { interfaceDoctorRow } from "./interfaceDoctorRow";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceServerGetDocotorsList } from "./interfaceDoctorsList";

function DoctorRow(paramsComponent: interfaceDoctorRow) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getDoctorList, setDoctorList] = useState<interfaceServerGetDocotorsList>();
    const [getSearchStr, setSearchStr] = useState<string | undefined>();
    const SendRequestToDoctor = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patient.doctorList.postRequestToDoctor;
        const obj = {
            "doctor": paramsComponent.id,
            "description": ""
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, obj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%", display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "20px calc(0.5vw + 12px)",
            alignItems: paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
            justifyContent: "start",
            borderRadius: "26px",

            background: colors.panel.doctor.account.step.background
        }}>
            {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ?

                <div style={{
                    width: "calc(10vw + 32px)",
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        width: "100%", height: "100%", position: "absolute",
                        boxSizing: "border-box",
                        overflow: "hidden",
                    }}>

                        {paramsComponent.profile_pic ?
                            <ImageSquare img={`${paramsComponent.profile_pic}`} width={"100%"}
                                borderRadius="8px" border="1px solid white"
                                isScaleable={{}} /> :
                            <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                color={colors.panel.doctor.patientsList.selector.color}
                                fontsizeIcon="27px"
                                borderRadius="8px" border="1px solid white" />
                        }
                    </div>
                    <div style={{ paddingBottom: "100%" }} />
                </div>
                : undefined}
            <h5 style={{
                color: "white", fontSize: "1.2rem", fontWeight: "200",
                marginInline: "4px",
                marginBlockEnd: "calc(0.5vh + 8px)"
            }}>
                {`${paramsComponent.first_name},${paramsComponent.last_name}`}
            </h5>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "start", gap: "calc(1vw + 8px)"
            }}>
                {paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ? undefined :
                    <div style={{
                        width: "min(calc(8vw + 32px) , 140px)",
                        position: "relative",
                        overflow: "hidden",
                        flexGrow: "0",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            width: "100%", height: "100%", position: "absolute",
                            overflow: "hidden"

                        }}>
                            <div style={{
                                height: "fit-content",
                                boxSizing: "border-box",

                            }}>
                                {paramsComponent.profile_pic ?
                                    <ImageSquare img={`${paramsComponent.profile_pic}`}
                                        isScaleable={{}}
                                        width={"100%"} borderRadius="8px" border="1px solid white"
                                    /> :
                                    <ImageSquare iconSvg={Person2Rounded}
                                        borderRadius="8px" border="1px solid white"
                                        color={colors.panel.doctor.patientsList.selector.color} width={"100%"}
                                        fontsizeIcon="calc(16px + 10rem + 1vh)"
                                    />

                                }
                            </div>
                        </div>
                        <div style={{ paddingBottom: "100%" }} />
                    </div>
                }
                <div style={{
                    flexGrow: "1", flexBasis: "0", alignSelf: "stretch", display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "center"
                }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 4 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.national_id}:
                                {paramsComponent.national_id}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.department}:
                                {paramsComponent.department}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.province}:
                                {paramsComponent.province},{paramsComponent.city}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.phone}:
                                {paramsComponent.phone}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.landline}:
                                {paramsComponent.landline}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem", overflow: "hidden", whiteSpace: "pre-wrap", hyphens: "auto" }}>
                                {confComps.panel.secretary.doctors.doctorsList.doctorRow.address}:
                                {paramsComponent.address}
                            </p>
                        </Grid>
                        {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", boxSizing: "border-box",
                                // paddingInlineStart: "calc(15vh + calc(1vw + 8px))" 
                                paddingInline: "8px",
                            }}>
                                <div style={{
                                    width: "100%", boxSizing: "border-box", height: "1px"
                                    , background: colors.panel.doctor.patientsList.selector.color
                                }}>

                                </div>
                            </div>
                        </Grid> */}
                        {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%", gap: "calc(1vw + 8px)", alignItems: "center",
                                justifyContent: paramsComponent.typeOfDisplay <= enumListSizeDevices.mobile ?
                                    "center" :
                                    "end"
                            }}>
                                <ButtonEffectShadow
                                    text={confComps.panel.secretary.doctors.doctorsList.doctorRow.buttomShowProfile.text}
                                    onclick={SendRequestToDoctor}
                                    iconEnded={mTheme.direction == "rtl" ? <ArrowBackRounded /> : <ArrowForwardRounded />}
                                />
                            </div>
                        </Grid> */}
                    </GridFromMUI>
                </div>

            </div>



        </div>
    );
}
export default DoctorRow;