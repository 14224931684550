const colors = {
    yellowHeader: "#F2D008",
    blue: {
        darkbackground: "#1C2544",
        dark: "#101522",
        blackBackground: "#161D2E",
        light: "#3E497A",
        dark3:"#21325E",
    },
    red: {
        light: "#ce1515",
        dark: "#711234",
    },
    green: {
        active: "#36be36",
    },
    white: {
        lightDark: "#989CAD",
    },
    Navbar: {
        backgroundColor: "#3F51B5",
        bottomBorder: "#606fc7",
        color: "#fff"
    },
    edittext: {
        darkTitleColor: "#22395e",
        lightTitleColor: "#fefefe",
    },
    appBar: {
        backgroundColor: "rgba(239,239,239,1)",
        buttonBackgroundColor: "#54C0EE",
        textBtn: "#FFFFFF",
    },
    titleOfMainPages: {
        selectedLinks: "#ECA712",
        unSelectedLinks: "#FFFFFF"
    },
    hamburgerMenu: {
        backgroundColor: "rgba(252,252,252,1)",
        textUnselected: "#14213D",
        textSelected: "#FFFFFF",
        ButtonSelected: "#09477B",
        iconColor: "#09477B",
        iconExit: "#D90429",
    },
    navBarMainPage: {
        textSelected: "#09477B",
        textUnselected: "#252525",
        buttonLoginAndLanguage: {
            shadowButton: "rgba(0,0,0,0.25)",
            backgroundButton: "#09477b",
            borderColorButtonInCard: "#09477b",
            colorTextInButton: "#FFF",
            hoverShadowButton: "rgba(21,23,62,0.25)",
            hoverBackgroundButtonInCard: "rgba(9,71,123,0.56)",
            hoverColorTextInButton: "#063155",
        }
    },
    panel: {
        buttonYellowBackground: {
            background: "#F3DB13",
            hoverBackground: "#00D800",
            text: "#080808",
            hoverText: "#181818"
        },
        buttonCardImage: {
            background: "#161D2E",
            hoverBackground: "#F2D008",
            text: "#FEFEFE",
            hoverText: "#FEFEFE"
        },
        buttonYellowText: {
            background: "transparent",
            hoverBackground: "#F3DB13",
            text: "#F3DB13",
            hoverText: "#080808"
        },
        buttonOrangeText: {
            background: "transparent",
            hoverBackground: "#F17D0A",
            text: "#F17D0A",
            hoverText: "#080808"
        },
        buttonBackgroundGreen: {
            background: "#13EB83",
            hoverBackground: "#00D800",
            text: "#080808",
            hoverText: "#181818"
        },
        buttonTextGreen: {
            background: "transparent",
            hoverBackground: "#00D800",
            text: "#13EB83",
            hoverText: "#181818"
        },
        buttonBackgroundRed: {
            background: "#EB1383",
            hoverBackground: "#D80000",
            text: "#080808",
            hoverText: "#181818"
        },
        buttonDarkBackground: {
            background: "#22283D",
            hoverBackground: "#F3DB13",
            text: "#FFF",
            hoverText: "#080808"
        },
        background: "#21325E",
        doctor: {
            account: {
                title: {
                    active: "#00D800",
                    deactive: "#979BAD",
                    waiting: "#F3DB13",
                },
                step: {
                    background: "#1B2543",
                    header: "#fff",
                    edittext: {
                        color: "#D4D9DD",
                        background: "#3E497A"
                    },
                },

            },
            patientsList: {
                selector: {
                    color: "#DFE2E6",
                    background: "#3E497A",
                }
            }
        }
    }


}
export default colors;