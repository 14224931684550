import colors from "../../../../config/colors";
import { styled as styledMUI } from "@mui/material/styles";
import { InterfacePatientRequestList, InterfacePatientsRequestListPage, interfacePatientRequestObj } from "./interfacePatientsRequestListPage";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectFromMUI } from "../../../../components/MUIConfig/SelectFromMUI";
import { FormControl, Grid, useTheme } from "@mui/material";
import { LabelInputFromMUI } from "../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../components/MUIConfig/MenuItemFromMUI";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { HandlerLangUrl, baseUrl, urlsList } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceServerGetInformationDocotor } from "../../account/interfaceDoctorAccount";
import PatientRequestRow from "./PatientRequestRow";
import EmptyList from "../../../../components/emptyList/EmptyList";
import EditText from "../../../../components/editText/EditText";

function PatientsRequestListPage(paramsPage: InterfacePatientsRequestListPage) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getPatientRequest, setPatientRequest] = useState<InterfacePatientRequestList>();
    const [getStepDisplay, setStepDisplay] = useState<number>(-1);
    const [getSearchStr, setSearchStr] = useState<(string)>("");
    const getListOfAllPatientRequest = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.doctor.patient.getListAllPatientsRequest;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfacePatientRequestList;

                setPatientRequest(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getListOfAllPatientRequest();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            paddingBlock: "calc(1vh + 16px)",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {"لیست درخواست‌ها"}
                </h5>
                <div style={{
                    flexGrow: "1", display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <div style={{
                        display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                        justifyContent: paramsPage.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                    }}>
                        <EditText
                            title={""}
                            hintText="جستجو"
                            onChangeValue={setSearchStr}
                            value={getSearchStr}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "auto",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,
                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </div>

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {getPatientRequest?.data.data
                        .filter(request => `${request.patient.first_name}${request.patient.last_name}${request.patient.landline}${request.patient.national_id}`.includes(getSearchStr))
                        .map((Patient, index, array) => {
                            return (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <PatientRequestRow
                                        img={Patient.patient.profile_pic ? baseUrl + '/' + Patient.patient.profile_pic : undefined}
                                        name={Patient.patient.first_name}
                                        family={Patient.patient.last_name}
                                        kindDisease={Patient.illness}
                                        isAccepted={Patient.status}
                                        phone={Patient.patient.phone}
                                        landline={Patient.patient.landline}
                                        location={Patient.patient.city}
                                        description={Patient.description}
                                        timeAccepted={Patient.created_at}
                                        typeOfDisplay={paramsPage.typeOfDisplay}
                                        callbackForReload={getListOfAllPatientRequest}
                                        idReuqest={Patient.id}
                                        idPatient={Patient.patient.id}
                                        city={Patient.patient.city}
                                        father_name={Patient.patient.father_name}
                                        national_id={Patient.patient.national_id} />
                                </Grid>
                            )
                        })
                    }
                </GridFromMUI>
                {
                    getPatientRequest?.data.data
                        .filter(request => `${request.patient.first_name}${request.patient.last_name}${request.patient.landline}${request.patient.national_id}`.includes(getSearchStr))
                        .length == 0 ?
                        <EmptyList text={"هیچ درخواستی یافت نشد"} /> : undefined
                }
            </div>
        </div>
    );
}
export default PatientsRequestListPage;