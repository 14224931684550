import { useTheme } from "@emotion/react";
import { Person2Rounded } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { interfaceRowPatientsOfDoctorsMeeting } from "./interfaceRowPatientsOfDoctorsMeeting";
import colors from "../../../../../config/colors";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import links from "../../../../../config/links";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";

function RowPatientsOfDoctorsMeeting(componentsParams: interfaceRowPatientsOfDoctorsMeeting) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    // const deleteApiRequestFromMeeting = async () => {
    //     dispatch(isVisibilityProgressBar(true));
    //     let urlObj = urlsList.panel.secretary.tomorboard.meeting.deleteRequestFromMeeting;

    //     await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${componentsParams.tumorboard.id}/meetings/${componentsParams.idMeeting}/requests/${componentsParams.serverDataOfRequest.id}/`, mtheme),
    //         urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
    //         .then((response: any) => {
    //             console.log("response:):)");
    //             console.log(response);
    //             dispatch(ShowAlert({ text: "درخواست از جلسه حذف شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             dispatch(isVisibilityProgressBar(false));
    //             componentsParams.callbackRefresh();


    //         })
    //         .catch((e: any) => {
    //             console.log("response:(:(");
    //             console.log(e);
    //             dispatch(isVisibilityProgressBar(false));
    //             if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
    //                 dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             }
    //             else {
    //                 dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             }
    //         });


    // }
    return (
        <div style={{
            width: "100%", background: colors.blue.darkbackground, borderRadius: "32px", border: "1px solid #ffffffff",
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
            padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
        }}>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>

                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                        , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                    }}>
                        {
                            componentsParams.serverDataOfRequest.doctor.profile_pic ?
                                <ImageSquare width={"4rem"} borderRadius="8px"
                                    border="1px solid #ccc"
                                    img={`${baseUrl}/` + componentsParams.serverDataOfRequest.doctor.profile_pic}
                                    isScaleable={{}}
                                />
                                :
                                <ImageSquare iconSvg={Person2Rounded}
                                    width={"4rem"}
                                    color={colors.panel.doctor.patientsList.selector.color}
                                    fontsizeIcon="2rem"
                                    borderRadius="8px" border="1px solid white" />
                        }

                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "start",
                            justifyContent: "space-between",
                            color: "white",
                        }}>
                            <p>پزشک:{componentsParams.serverDataOfRequest.doctor.first_name},{componentsParams.serverDataOfRequest.doctor.last_name}</p>
                            <p style={{ color: colors.yellowHeader }}>شماره تماس:{componentsParams.serverDataOfRequest.doctor.phone}</p>

                        </div>

                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        width: "100%", display: "flex", flexDirection: "row", alignItems: "start"
                        , justifyContent: "start", flexWrap: "wrap", gap: "8px"
                    }}>
                        {
                            componentsParams.serverDataOfRequest.patient.profile_pic ?
                                <ImageSquare width={"4rem"} borderRadius="8px"
                                    border="1px solid #ccc"
                                    isScaleable={{}}
                                    img={`${baseUrl}/` + componentsParams.serverDataOfRequest.patient.profile_pic}
                                />
                                :
                                <ImageSquare iconSvg={Person2Rounded}
                                    width={"4rem"}
                                    color={colors.panel.doctor.patientsList.selector.color}
                                    fontsizeIcon="2rem"
                                    borderRadius="8px" border="1px solid white" />
                        }

                        <div style={{
                            display: "flex", flexDirection: "column", alignItems: "start",
                            justifyContent: "space-between",
                            color: "white",
                        }}>
                            <p>بیمار:{componentsParams.serverDataOfRequest.patient.first_name},{componentsParams.serverDataOfRequest.patient.last_name}</p>
                            <p style={{ color: colors.yellowHeader }}>شماره تماس:{componentsParams.serverDataOfRequest.patient.phone}</p>

                        </div>

                    </div>
                </Grid>

                {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: colors.panel.doctor.account.step.header }}>
                        {confComps.panel.secretary.tomorboard.alltomorboard.rowTomorboard.description}:
                        {componentsParams.description}
                    </p>
                </Grid> */}
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>
                {/* <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            text={"مشاهده تاریخچه"}
                            onclick={() => {
                                navigate(links.doctor.fileOfmyPatient + `${componentsParams.serverDataOfRequest.patient.id}/`)
                            }} />
                    </div>
                </Grid> */}
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ height: "8px" }} />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "start" }}>
                        <h6 style={{ color: "white" }}>{"توموربورد"}:{componentsParams.tumorboard.name}</h6>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: "white" }}>بیمارستان:{componentsParams.tumorboard.hospital}</p>

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <p style={{ color: "white" }}>مکان:{componentsParams.tumorboard.meeting_place}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <p style={{ color: "white" }}>توضیحات:{componentsParams.tumorboard.description}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            onclick={() => {
                                navigate(links.doctor.tomorboard.meeting.appendProcessForPatient,
                                    {
                                        state: {
                                            "idMeeting": componentsParams.idMeeting,
                                            "idTomorBoard": componentsParams.tumorboard.id,
                                            "serverDataOfRequest": componentsParams.serverDataOfRequest,
                                        }
                                    })
                            }}
                            text={"ثبت روند درمان"}
                        />
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonYellowText}
                            text={"ثبت نظر توموربورد"}
                            onclick={() => {
                                navigate(links.doctor.tomorboard.meeting.appendCommentForPatient,
                                    {
                                        state: {
                                            "idMeeting": componentsParams.idMeeting,
                                            "idTomorBoard": componentsParams.tumorboard.id,
                                            "serverDataOfRequest": componentsParams.serverDataOfRequest,
                                        }
                                    }
                                )
                            }} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{ width: "100%" }}
                            size="small"
                            colors={colors.panel.buttonOrangeText}
                            text={"مشاهده پرونده"}
                            onclick={() => {
                                navigate(links.doctor.fileOfmyPatient.comeFromListPetientsOfMeeting + `${componentsParams.serverDataOfRequest.patient.id}/`)
                            }} />
                    </div>
                </Grid>

            </GridFromMUI>

        </div >
    )
}
export default RowPatientsOfDoctorsMeeting;