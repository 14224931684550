import { useEffect, useState } from "react";
import { DialogShowImage } from "./dialogShowImage/DialogShowImage";
import { interfaceImageSquare } from "./interfaceImageSquare";

export function ImageSquare(componenetParams: interfaceImageSquare) {
    const [getIsDialogShow, setIsDialogShow] = useState<boolean>(false);
    function onCancelDialog() {
        setIsDialogShow(false);
    }

    return (
        // <div>
        <div
            onClick={(getIsDialogShow) ? undefined :
                () => {
                    if (componenetParams.isScaleable) {
                        setIsDialogShow(true);
                    }
                    else if (componenetParams.callbackOnclick) {
                        componenetParams.callbackOnclick()
                    }
                }
            }
            style={{
                cursor: (componenetParams.callbackOnclick || componenetParams.isScaleable) ? "pointer" : undefined,
                boxSizing: "border-box",
                width: componenetParams.width,
                position: "relative",
                color: componenetParams.color,
                background: componenetParams.background,
                overflow: "hidden",
                border: componenetParams.border,
                borderRadius: componenetParams.borderRadius
            }}>
            <div style={{ paddingBottom: "100%" }} />
            {
                componenetParams.img ?
                    <img
                        src={`${componenetParams.img}`}
                        style={{ width: "100%", position: "absolute", top: "0", left: "0" }}
                    /> :
                    undefined
            }
            {
                componenetParams.iconSvg ?
                    <componenetParams.iconSvg
                        style={{
                            fontSize: componenetParams.fontsizeIcon,
                            width: "100%", position: "absolute", top: "50%", transform: "translate(0,-50%)", left: "0",
                            color: componenetParams.color,
                            background: componenetParams.background,
                        }} />
                    :
                    undefined
            }
            {
                componenetParams.element ?
                    < div style={{
                        fontSize: componenetParams.fontsizeIcon,
                        width: "100%", position: "absolute", top: "50%", transform: "translate(0,-50%)", left: "0",
                        color: componenetParams.color,
                        background: componenetParams.background,
                        display: "flex",

                        flexDirection: "row", alignItems: "center", justifyContent: "center"
                    }}
                        key={`${componenetParams.element}`}
                    >
                        {componenetParams.element}
                    </div> : undefined
            }

            {/* </div > */}
            <DialogShowImage
                isOpen={getIsDialogShow}
                imgUrl={(componenetParams.img ?? "")}
                callbackOnCloseDialog={onCancelDialog}
                appendData={componenetParams.isScaleable}
            />
        </div>

    )
}