import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
// import RowListMeetingsOfTomorboard from "../meetingManager/RowListMeetingsOfTomorboardForAddPatient";
import { interfaceListMeetingsOfTomorBoard } from "./interfaceListMeetingsOfTomorBoard";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceApiGetListOfMeetingsTheTomorboardForAddPatient } from "../meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";
import RowListMeetingsOfTomorBoard from "./RowListMeetingsOfTomorBoard";
import { interfaceGetOneTomorboardFromServer } from "../../settingTomorboard/interfaceSettingTomorboard";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import { AddRounded } from "@mui/icons-material";
import colors from "../../../../../config/colors";
import links from "../../../../../config/links";
import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import EmptyList from "../../../../../components/emptyList/EmptyList";

function ListMeetingsOfTomorBoard(pagesParams: interfaceListMeetingsOfTomorBoard) {
    const { idTomorboard } = useParams();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getRequestOfListMeeting, setRequestOfListMeeting] = useState<interfaceApiGetListOfMeetingsTheTomorboardForAddPatient>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const [getTomor, setTomorboard] = useState<interfaceGetOneTomorboardFromServer>();
    const getApiListMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getlistOfMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/` + "meetings/?page=1&page_size=1000", mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiGetListOfMeetingsTheTomorboardForAddPatient;
                setRequestOfListMeeting(t)

                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiThisTomorBoard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.getDataOfOneTomorboard;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetOneTomorboardFromServer;

                setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiThisTomorBoard();
        getApiListMeeting();
        // getApiDataOfOneTomorboard();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", marginBlock: "calc(1vh + 12px)" }}>{"لیست جلسات:"} {getTomor?.data.name}</h5>
                <ImageSquare iconSvg={AddRounded} width={"calc(1vh + 32px)"} borderRadius="100px"
                    callbackOnclick={() => {
                        navigate(links.secretary.tomorboard.meeting.addNewMeeting + `/${idTomorboard}/`)
                    }}
                    background={colors.yellowHeader}
                    color={colors.blue.darkbackground} />
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
                paddingBottom: "16px",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    {getRequestOfListMeeting?.data.data
                        // .filter((Tm, index, arra) => {
                        //     if (getSearchStr.length > 0 && Tm.doctor.first_name.indexOf(getSearchStr) && Tm.doctor.last_name.indexOf(getSearchStr) && Tm.patient.first_name.indexOf(getSearchStr) && Tm.patient.last_name.indexOf(getSearchStr)) {
                        //         return undefined
                        //     }
                        //     return Tm;
                        // })
                        .map((meeting, index, array) => {
                            return (
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

                                    {/* <RowListMeetingsOfTomorboard
                                    serverData={meeting}
                                    idTomorboard={location.idTomorBoard}
                                    request_id={location.serverData.id} /> */}
                                    {
                                        idTomorboard ?
                                            <RowListMeetingsOfTomorBoard
                                                isactive={(meeting.end_datetime * 1000) > Date.now()}
                                                serverData={meeting}
                                                idTomorboard={idTomorboard} /> : undefined
                                    }

                                </Grid>
                            )
                        })
                    }
                </GridFromMUI>
                {getRequestOfListMeeting?.data.data.length == 0 ?
                    <EmptyList text={"هیچ جلسه‌ای ساخته نشده است"} /> : undefined
                }
            </div>

        </div>
    )
}
export default ListMeetingsOfTomorBoard;
