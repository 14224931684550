import { useTheme } from "@emotion/react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceGetTomorboardsListFromServer } from "../tomorboardsList/interfaceTomorboardsList";
import { interfaceGetOneTomorboardFromServer, interfaceSettingTomorboard } from "./interfaceSettingTomorboard";
import { AddRounded } from "@mui/icons-material";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { EnumSupportRoles } from "../../../../utils/Role";
import { enumCategoryFilter } from "../../../patient/myFile/interfaceMyFilePage";
import colors from "../../../../config/colors";
import InformationOfTomorboard from "./InformationOfTomorboard";
import ListOfMemberTomorboard from "./ListOfMemberTomorboard";
import ManageTimeOfTomorboard from "./manageTime/ManageTimeOfTomorboard";

enum EnumSelected {
    information = "inf",
    date = "date",
    member = "mem",

}
function SettingTomorboard(pagesParams: interfaceSettingTomorboard) {
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const { idTomorboard } = useParams();
    const [getTomor, setTomorboard] = useState<interfaceGetOneTomorboardFromServer>();
    const [getWitchPartShow, setWitchPartShow] = useState<EnumSelected>(EnumSelected.information);

    const getApiDataOfOneTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.getDataOfOneTomorboard;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetOneTomorboardFromServer;

                setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getApiDataOfOneTomorboard();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", width: "100%", flexDirection: "row",
                marginBottom: "calc(3vh + 12px)",
                gap: "calc(1vw + 8px)", justifyContent: "start", alignItems: "center", flexWrap: "wrap"
            }}>
                {confComps.panel.secretary.tomorboard.oneTomorboard.category.list.map((cat, index, array) => {
                    return (
                        <ButtonEffectShadow text={cat} size="small"
                            colors={
                                (Object.values(EnumSelected).indexOf(getWitchPartShow) == index) ?
                                    colors.panel.buttonYellowBackground :
                                    colors.panel.buttonDarkBackground
                            }
                            onclick={() => {
                                setWitchPartShow(Object.values(EnumSelected)[index])
                                // setCategoryFilter(Object.keys(enumCategoryFilter)[0])
                            }}
                        />
                    )
                })}
            </div>
            <div style={{
                display: "flex", width: "100%", flexDirection: "column",
                alignItems: "start", justifyContent: "space-between",
                borderRadius: "30px",
                height: "100%",
                maxHeight: "100%",
                overflow: "hidden",
                paddingBlockEnd: "32px",
                paddingInline: "8px",
                boxSizing: "border-box",
            }}>
                {(getWitchPartShow == EnumSelected.information && getTomor) ?
                    <InformationOfTomorboard
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        success={true}
                        data={getTomor?.data}
                        callbackRefresh={getApiDataOfOneTomorboard} />
                    : undefined}
                {(getWitchPartShow == EnumSelected.date && getTomor) ?
                    <ManageTimeOfTomorboard
                        callbackRefresh={getApiDataOfOneTomorboard}
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        success={true}
                        data={getTomor.data} />
                    : undefined

                }
                {(getWitchPartShow == EnumSelected.member && getTomor) ?
                    <ListOfMemberTomorboard
                        callbackRefresh={getApiDataOfOneTomorboard}
                        typeOfDisplay={pagesParams.typeOfDisplay}
                        success={true}
                        data={getTomor.data} />
                    : undefined}
            </div>
        </div>
    )
}
export default SettingTomorboard;