import EmptyList from "../../../../components/emptyList/EmptyList";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import CardSuggestion from "../allCategoryOfMyFile/cardSuggestion/CardSuggestion";
import BigCardTreatmentProcesses from "./cardTreatmentProcesses/BigCardTreatmentProcesses";
import { interfaceAllTreatmentProcesses } from "./interfaceAllTreatmentProcesses";

function AllTreatmentProcesses(componenetParams: interfaceAllTreatmentProcesses) {
    var confComps = ConfigComps();
    return (
        <div style={{
            width: "100%", maxHeight: "80vh",
            overflowY: "auto",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: "calc(3vh + 8px)"
        }}>
            {componenetParams.allTreatmentProcessesFromServer.map((treatProcess, index, array) => {
                return (
                    <BigCardTreatmentProcesses
                        treatmentProcessRow={treatProcess}
                    />

                )
            })}
            {componenetParams.allTreatmentProcessesFromServer.length == 0 ?
                <EmptyList text={"هیچ روند درمانی برای شما ثبت نشده است."} /> : undefined

            }
        </div>
    )
}
export default AllTreatmentProcesses;