import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, useTheme, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { styled as styledMUI } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { AddRounded, CancelRounded, CheckRounded, CompressRounded, DownloadRounded, PlusOneRounded } from "@mui/icons-material";
import { margin } from "@mui/system";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { ButtonFromMUI } from "../../../../components/MUIConfig/ButtonFromMUI";
import { TextFieldFromMUI } from "../../../../components/MUIConfig/TextFieldFromMUI";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceDialogAddFileInMyFile } from "./interfaceDialogAddFileInMyFile";
import colors from "../../../../config/colors";
import EditText from "../../../../components/editText/EditText";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { DialogFromMUI } from "../../../../components/MUIConfig/DialogFromMUI";
import FilesDragAndDrop from "../../../../components/FileDragAndDrop/FileDragAndDrop";
import { ImageSquare } from "../../../../components/imageSquare/ImageSquare";
import { EnumSupportRoles } from "../../../../utils/Role";

export const ButtonPay = styledMUI(ButtonFromMUI)(({ theme }) => ({
    color: "red",
    borderColor: "red",
    backgroundColor: "blue",
    boxShadow: `2px 4px 4px gray`,
    ':hover': {
        // boxShadow: `1px 2px 4px ${colors.screens.panel.transactionsList.hoverShadowButton}`,
        // color: colors.screens.panel.transactionsList.hoverColorTextInButton,
        // backgroundColor: colors.screens.panel.transactionsList.hoverBackgroundButtonInCard,

    }
}));

export const ButtonPrice = styledMUI(ButtonFromMUI)(({ theme }) => ({
    // color: colors.screens.panel.transactionsList.colorTextInButton,
    // borderColor: colors.screens.panel.transactionsList.borderColorButtonInCard,
    // backgroundColor: colors.screens.panel.transactionsList.backgroundButtonInCard,
    // boxShadow: `2px 4px 4px ${colors.screens.panel.transactionsList.shadowButton}`,
    ':hover': {
        // boxShadow: `1px 2px 4px ${colors.screens.panel.transactionsList.hoverShadowButton}`,
        // color: colors.screens.panel.transactionsList.hoverColorTextInButton,
        // backgroundColor: colors.screens.panel.transactionsList.hoverBackgroundButtonInCard,

    }
}));



export const DialogAddFileInMyFile = (paramsDialog: interfaceDialogAddFileInMyFile) => {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getOpen, setOpen] = useState<boolean>(paramsDialog.isOpen);
    const [getListFiles, setListFiles] = useState<(File[])>([]);
    const [getTitle, setTitle] = useState<(string)>("");
    const [getDescription, setDescription] = useState<(string)>("");
    useEffect(() => {
        setOpen(paramsDialog.isOpen)
    }, [paramsDialog.isOpen])
    const handleClose = () => {
        setOpen(false);
        paramsDialog.callbackOnCloseDialog();
    };

    const onclickBTNForSubmit = async () => {
        if ((getListFiles.length) == 0) {
            dispatch(ShowAlert({ text: "هیچ فایلی بارگزاری نشده است!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        const formData = new FormData();
        formData.append("title", getTitle);
        formData.append("description", getDescription);
        getListFiles.forEach((file1) => {
            formData.append("files", file1);
        });

        var urlTarget = urlsList.panel.patient.myFile.createMedicalRecordAsFile
        var url = urlTarget.url;
        if (paramsDialog.role == EnumSupportRoles.doctor) {
            url = `/users/doctors/patients/${paramsDialog.idMyPatient}/medical-records/`;
        }

        await RequestHandler(dispatch, HandlerLangUrl(url, mTheme),
            urlTarget.method,
            urlTarget.isTokenNecessary, formData, profileInStore, urlTarget.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let res = response.data;
                console.log(res);

                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "پرونده شما به روز رسانی شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                paramsDialog.callbackOnCloseDialog();
            })
            .catch((e: Error) => {
                console.log("response:(");

                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr ?? (e as interfaceResponseErrorFromServer).data.errors.list[0].code} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }

    const handleOnclickCancelDialog = () => {
        paramsDialog.callbackOnCloseDialog();
    }



    return (
        <DialogFromMUI onClose={handleClose} open={getOpen} dir={mTheme.direction} background="#3E497A">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <h3 style={{ margin: "10px calc(16px + 2vw) 10px calc(16px + 2vw)", color: "white", padding: "0 0px" }}>
                    {confComps.panel.patient.myFilePage.dialogAddFiles.header}
                </h3>
                <IconButton
                    onClick={handleOnclickCancelDialog}>
                    <CancelRounded />
                </IconButton>
            </div>
            <div style={{
                padding: "0 calc(16px + 2vw) 24px calc(16px + 2vw)", display: "flex", flexDirection: "column",
                gap: "calc(2vh + 12px)", justifyContent: "space-around", margin: "8px 12px", borderRadius: "12px", background: "#182546"
            }}>
                {getListFiles.length < 4 ?
                    <div>

                        <FilesDragAndDrop
                            onUpload={(files) => {
                                console.log(files)
                                files.forEach((value) => {
                                    setListFiles(oldArray => [value, ...oldArray]);
                                })
                            }}
                            count={4}
                            formats={['jpg', 'png']}
                            containerStyles={{
                                borderRadius: "4px",
                                boxSizing: "border-box",

                            }}
                            openDialogOnClick
                        >
                            <div style={{
                                display: 'flex',
                                flexDirection: "column",
                                justifyContent: "space-between",
                                gap: "calc(1vh + 12px)",
                                alignItems: "center",
                                padding: "8px",
                                background: "#3E497A",
                                border: "1px solid white",
                                marginTop: "12px",
                                borderRadius: "16px",
                            }}>
                                <ImageSquare iconSvg={AddRounded} color="white"
                                    background={colors.blue.blackBackground} width={"62px"} borderRadius="800px" border="1px solid white" />
                                <ButtonEffectShadow text={confComps.panel.patient.myFilePage.dialogAddFiles.file.title}
                                    colors={colors.panel.buttonYellowBackground} />

                            </div>
                        </FilesDragAndDrop>
                    </div>
                    : undefined}

                {getListFiles.map((file, indexMain, array) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                            <ImageSquare
                                width={"56px"}
                                iconSvg={DownloadRounded} color="white" background="#3E497A" borderRadius="400px"
                                callbackOnclick={() => {
                                    const url = URL.createObjectURL(file);
                                    const link = document.createElement("a");
                                    link.href = url;
                                    link.download = file.name;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                    URL.revokeObjectURL(url);
                                }} />
                            <p style={{ color: "white", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", }}>{file.name}</p>
                            <ImageSquare
                                width={"56px"}
                                borderRadius="400px"
                                border="1px solid red"
                                callbackOnclick={() => {

                                    setListFiles(getListFiles.filter((nFile, index) => index !== indexMain))
                                }}
                                iconSvg={CancelRounded} background={colors.red.dark} color={colors.red.light} />

                        </div>
                    )
                })}
                <div style={{
                    display: "flex", flexDirection: "column",
                    width: "100%", alignItems: "center", justifyContent: "space-between",
                    gap: "calc(1vw + 8px)"
                }}>
                    <EditText
                        title={confComps.panel.patient.myFilePage.dialogAddFiles.title}
                        onChangeValue={setTitle}
                        value={getTitle}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                    <EditText
                        title={confComps.panel.patient.myFilePage.dialogAddFiles.description}
                        onChangeValue={setDescription}
                        value={getDescription}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "12px",
                            width: "100%",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={true}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />
                    <div style={{ padding: "0.5vh 8px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <ButtonEffectShadow
                            text="ارسال"
                            colors={colors.panel.buttonYellowBackground}
                            onclick={onclickBTNForSubmit}
                        />

                    </div>
                </div>
            </div>
        </DialogFromMUI>
    );
}
