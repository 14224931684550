import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../../../../components/MUIConfig/GridFromMUI";
import colors from "../../../../../../../config/colors";

import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonEffectShadow from "../../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { HandlerLangUrl, baseUrl, urlsList } from "../../../../../../../api/UrlsList";
import { AddRounded, ArrowBack, ArrowBackRounded, Person2Rounded } from "@mui/icons-material";

import { RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { interfaceRowAddPatient } from "./interfaceRowAddPatient";
import { enumListSizeDevices } from "../../../../../../../config/responsive/configResponsive";
import { ImageSquare } from "../../../../../../../components/imageSquare/ImageSquare";

function RowAddPatient(componentsParams: interfaceRowAddPatient) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const postApiCreateRequestForThisTomorboard = async (idPatient: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.addPatientToMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${componentsParams.idTumorBoard}/meetings/${componentsParams.idMeeting}/manual-requests/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, { "patient": idPatient }, profileInStore)
            .then((response: any) => {
                componentsParams.callbackReload();
                dispatch(ShowAlert({ text: "بیمار با موفقیت اضافه شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));

            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            padding: "20px calc(0.5vw + 12px)",
            alignItems: componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start",
            justifyContent: "start",
            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
            borderRadius: "24px",

            background: colors.panel.doctor.account.step.background
        }}>
            {componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ?

                <div style={{
                    width: "calc(10vw + 32px)",
                    position: "relative",
                    overflow: "hidden",
                }}>
                    <div style={{
                        width: "100%", height: "100%", position: "absolute",
                        boxSizing: "border-box",
                        borderRadius: "12px",
                        overflow: "hidden",
                        border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                    }}>
                        {/* <div style={{
                            width: "fit-content", height: "fit-content",
                            borderRadius: "12px",
                            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                        }}> */}
                        {componentsParams.img ?
                            <ImageSquare img={`${baseUrl}/${componentsParams.img}`}
                                isScaleable={{}}
                                width={"100%"}
                                borderRadius="8px" border="1px solid white"
                            /> :
                            <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                color={colors.panel.doctor.patientsList.selector.color}
                                fontsizeIcon="27px"
                                borderRadius="8px" border="1px solid white"
                            />
                        }
                        {/* </div> */}
                    </div>
                    <div style={{ paddingBottom: "100%" }} />
                </div>
                : undefined}
            <h5 style={{
                color: "white", fontSize: "1.2rem", fontWeight: "200",
                marginInline: "4px",
                marginBlockEnd: "calc(0.5vh + 8px)"
            }}>
                {`${componentsParams.name} ${componentsParams.family}`}
            </h5>
            <div style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "start", gap: "calc(1vw + 8px)"
            }}>
                {componentsParams.typeOfDisplay <= enumListSizeDevices.mobile ? undefined :
                    <div style={{
                        width: "min(calc(6.5vw + 32px) , 130px)",
                        position: "relative",
                        overflow: "hidden",
                        flexGrow: "0",
                        boxSizing: "border-box",
                        // paddingBottom: "calc(12px + 3vw)",
                    }}>
                        <div style={{
                            width: "100%", height: "100%", position: "absolute",
                            overflow: "hidden"

                        }}>
                            <div style={{
                                height: "fit-content",
                            }}>
                                {componentsParams.img ?
                                    <ImageSquare img={`${baseUrl}/${componentsParams.img}`} width={"100%"}
                                        borderRadius="8px" border="1px solid white"
                                        isScaleable={{}}
                                    /> :
                                    <ImageSquare iconSvg={Person2Rounded} width={"100%"}
                                        color={colors.panel.doctor.patientsList.selector.color}
                                        fontsizeIcon="270px"
                                        borderRadius="8px" border="1px solid white" />
                                }
                            </div>
                        </div>
                        <div style={{ paddingBottom: "100%" }} />
                    </div>
                }
                <div style={{
                    flexGrow: 1,
                    flexBasis: "0",
                    alignSelf: "stretch", display: "flex", flexDirection: "column",
                    justifyContent: "center", alignItems: "center"
                }}>
                    <GridFromMUI container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                        rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.phone}{": "}
                                {componentsParams.phone}
                            </p>
                        </Grid>

                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"شماره تلفن"}{": "}
                                {componentsParams.landline}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.email}{": "}
                                {componentsParams.email}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {"کد ملی"}{": "}
                                {componentsParams.national_id}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <p style={{ color: "white", fontSize: "0.9rem" }}>
                                {confComps.panel.doctor.patientsList.patientRow.address}{": "}
                                {componentsParams.address}
                            </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{
                                width: "100%", height: "2px", boxSizing: "border-box",
                                paddingInline: "calc(1vw + 8px)"
                            }} >
                                <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                <ButtonEffectShadow
                                    styleMainDiv={{}}
                                    size="small"
                                    colors={colors.panel.buttonBackgroundGreen}
                                    onclick={() => {
                                        postApiCreateRequestForThisTomorboard(componentsParams.idPatient);
                                    }}
                                    text={"ایجاد درخواست"}
                                    iconEnded={<AddRounded />} />
                            </div>
                        </Grid>
                    </GridFromMUI>
                </div>

            </div>
        </div>
    );
}
export default RowAddPatient;