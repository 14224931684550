import { useTheme } from "@emotion/react";
import { FormControl, Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl, baseUrl } from "../../../../api/UrlsList";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { LabelInputFromMUI } from "../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../../components/MUIConfig/SelectFromMUI";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceServerGetDocotorsList } from "./interfaceDoctorsList";
import { interfaceDoctorsList } from "./interfaceDoctorsList";
import colors from "../../../../config/colors";
import DoctorRow from "./DoctorRow";
import EditText from "../../../../components/editText/EditText";
import EmptyList from "../../../../components/emptyList/EmptyList";

function DoctorsList(pagesParams: interfaceDoctorsList) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getDoctorList, setDoctorList] = useState<interfaceServerGetDocotorsList>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const getDoctorListFromServer = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.doctor.getListAllDoctor;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceServerGetDocotorsList;

                setDoctorList(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {

        getDoctorListFromServer();
    }, []);
    return (
        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap',
                rowGap: "calc(8px + 1vh)", width: "100%",
                boxSizing: "border-box", flexDirection: "row", alignItems: "center",
                justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "space-between",
                gap: "calc(1vw + 16px)",
                zIndex: "4",
                background: colors.blue.dark3,
            }}>
                <h5 style={{ color: "white", fontSize: "2.2rem", fontWeight: "300" }}>
                    {"لیست پزشکان"}
                </h5>
                <div style={{
                    display: "flex", flexWrap: "wrap", gap: "max(5%,8px)", flexDirection: "row", alignItems: "center",
                    justifyContent: pagesParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "end"
                }}>
                    <EditText
                        title={""}
                        hintText="جستجو"
                        onChangeValue={setSearchStr}
                        value={getSearchStr}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "auto",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,
                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </div>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 8 }}>
                    {getDoctorList?.data.data.filter((Dc, index, arra) => {
                        if (getSearchStr.length > 0 && Dc.national_id.indexOf(getSearchStr) && Dc.last_name.indexOf(getSearchStr) && Dc.first_name.indexOf(getSearchStr)) {
                            return undefined
                        }
                        return Dc;
                    }).map((Doctor, index, array) => {
                        return (
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <DoctorRow
                                    profile_pic={Doctor.profile_pic ? baseUrl + "/" + Doctor.profile_pic : undefined}
                                    first_name={Doctor.first_name}
                                    last_name={Doctor.last_name}
                                    province={Doctor.province}
                                    typeOfDisplay={pagesParams.typeOfDisplay}
                                    id={Doctor.id}
                                    // ={Doctor.field}
                                    phone={Doctor.landline}
                                    address={Doctor.address}
                                    father_name={Doctor.first_name}
                                    email={Doctor.email}
                                    national_id={Doctor.national_id}
                                    gender={Doctor.gender}
                                    landline={Doctor.landline}
                                    city={Doctor.city} />
                            </Grid>
                        )
                    })
                    }
                </GridFromMUI>
                {getDoctorList?.data.data.filter((Dc, index, arra) => {
                    if (getSearchStr.length > 0 && Dc.national_id.indexOf(getSearchStr) && Dc.last_name.indexOf(getSearchStr) && Dc.first_name.indexOf(getSearchStr)) {
                        return undefined
                    }
                    return Dc;
                }).length == 0 ?
                    <EmptyList text={"هیچ پزشکی یافت نشد"} /> : undefined
                }
            </div>
        </div>
    )
}
export default DoctorsList;