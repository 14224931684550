import { AddRounded, Person2Rounded } from "@mui/icons-material";
import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import colors from "../../../../../config/colors";
import links from "../../../../../config/links";
import { interfaceRowListMeetingsOfTomorboardForAddPatient } from "./interfaceRowListMeetingsOfTomorboardForAddPatient";
import moment from "moment-jalaali";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { interfaceApiGetListOfMeetingsTheTomorboardForAddPatient } from "./interfaceListMeetingsOfTomorboardForAddPatient";

function RowListMeetingsOfTomorboardForAddPatient(componentsParams: interfaceRowListMeetingsOfTomorboardForAddPatient) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    // const [getRequestOfPatient, setRequestOfPatient] = useState<interfaceApiRequestOfPatient>();
    const [getSearchStr, setSearchStr] = useState<string>("");
    const { idTomorboard } = useParams();
    // const [getTomor, setTomorboard] = useState<interfaceGetOneTomorboardFromServer>();

    const AddPatientToThisMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.postPatientToTheMeeting;
        const objSend = {
            "request_id": componentsParams.request_id,
        }
        await RequestHandler(dispatch,
            HandlerLangUrl(urlObj.url + `${componentsParams.idTomorboard}/meetings/${componentsParams.serverData.id}/requests/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, objSend, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiGetListOfMeetingsTheTomorboardForAddPatient;


                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <div style={{
            width: "100%", background: colors.blue.darkbackground, borderRadius: "8px",
            border: `1px solid ${componentsParams.isactive ? "#FFFFFFeF" : "gray"}`,
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "space-between",
            padding: "16px", boxSizing: "border-box", gap: "calc(3vh + 8px)"
        }}>
            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <h5 style={{
                            color: componentsParams.isactive ? "white" : colors.white.lightDark
                        }}>
                            {componentsParams.serverData.title}
                            {componentsParams.isactive ? undefined : ` (جلسه تمام شده است)`}
                        </h5>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>تعداد اعضا:{componentsParams.serverData.members.length}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"تعداد پرونده:"}{componentsParams.serverData.requests.length}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"شروع:"}{`${moment.unix(componentsParams.serverData.start_datetime).format("jYYYY/jM/jD HH:mm")}`}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"پایان:"}{`${moment.unix(componentsParams.serverData.end_datetime).format("jYYYY/jM/jD HH:mm")}`}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "100%" }}>
                        <p style={{ color: componentsParams.isactive ? "white" : "gray" }}>{"توضیحات:"}{componentsParams.serverData.requests.length}</p>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", height: "2px", boxSizing: "border-box",
                        paddingInline: "calc(1vw + 8px)"
                    }} >
                        <div style={{ background: "#8e8e9e", boxSizing: "border-box", width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <ButtonEffectShadow
                            styleMainDiv={{
                                boxSizing: "border-box"
                            }}
                            size="small"
                            iconEnded={<AddRounded />}
                            colors={colors.panel.buttonBackgroundGreen}
                            text={"افزودن به این جلسه"}
                            onclick={() => {
                                AddPatientToThisMeeting();
                            }} />
                    </div>
                </Grid>

            </GridFromMUI>

        </div >
    )
}
export default RowListMeetingsOfTomorboardForAddPatient;