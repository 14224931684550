import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";

import links from "./config/links";

import configResponsive, { enumListSizeDevices } from "./config/responsive/configResponsive";
import { createTheme, styled as styledMUI, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import interfaceResponsive from "./config/responsive/interfaceResponsive";
import { HelperResponsive } from "./helper/Helper";
import { ConfigComps } from "./config/ConfigCOMP";

import { useDispatch, useSelector } from "react-redux";
import { initialState, selectedTheme, SetDirectionOfTheme, ThemeStructure } from "./store/themeModeSlice";
import { AlertPopup } from './components/MUIConfig/alert_popup/AlertPopup';
import { enumTypeOfAlert } from './components/MUIConfig/alert_popup/interfaceAlertPopup';
import { AlertStructure, SelectedAlert, ShowAlert } from './store/AlertStateModeSlice';
import { ProgressBarInRoots } from './components/MUIConfig/progressbar_in_root/ProgressBarInRoot';
import { enumTypeOfProgressBarInRoot } from './components/MUIConfig/progressbar_in_root/interfaceProgressBarInRoot';
import { SelectedProgressbarInRoot } from './store/ProgressbarInRootSlice';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';
import { NavbarMainPage } from './components/AppBarMenu/navbarMain/NavbarMainPage';
import ErrorHandler from './screens/error/ErrorHandler';
import AccountStep1 from './screens/doctor/account/DoctorAccountStep1';
import DoctorAccountStep1 from './screens/doctor/account/DoctorAccountStep1';
import LoginPage from './screens/login_and_register/login/LoginPage';
import RegisterPage from './screens/login_and_register/register/RegisterPage';
import HomePage from './screens/HomePage';
import { InterfaceSection } from './components/AppBarMenu/navbarMain/InterfaceNavbarMainPage';
import colors from './config/colors';
import DoctorAccount from './screens/doctor/account/DoctorAccount';
import PatientsListPage from './screens/doctor/patients/patientsList/PatientsListPage';
import PatientListDoctorPage from './screens/patient/listDoctor/PatientListDoctorPage';
import MyRequestPage from './screens/patient/myRequest/MyRequestPage';
import MyFilePage from './screens/patient/myFile/MyFilePage';
import MyHistoryPage from './screens/patient/myHistory/MyHistoryPage';
import PatientAccountPage from './screens/patient/account/PatientAccountPage';
import TomorBoardPage from './screens/patient/tomorBoard/TomorBoardPage';
import SuggestionOneDoctor from './screens/patient/myFile/allCategoryOfMyFile/suggestionOneDoctor/SuggestionOneDoctor';
import PrivateRedirectToLogin from './api/PrivateRedirectToLogin';
import DoctorAccountChangePassword from './screens/doctor/account/DoctorAccountChangePassword';
import SecretaryChangePassword from './screens/secretary/account/SecretaryChangePassword';
import PatientsRequestListPage from './screens/doctor/patients/patientsRequest/PatientsRequestListPage';
import { EnumSupportRoles } from './utils/Role';
import DoctorsList from './screens/secretary/doctors/doctorsList/DoctorsList';
import PatientsList from './screens/secretary/patients/patientsList/PatientsList';
import TomorboardsList from './screens/secretary/tomorboard/tomorboardsList/TomorboardsList';
import SettingTomorboard from './screens/secretary/tomorboard/settingTomorboard/SettingTomorboard';
import CreatePatient from './screens/secretary/patients/createPatient/CreatePatient';
import RequestOfPatient from './screens/secretary/tomorboard/tomorboardsList/requestOfPatient/RequestOfPatient';
import ListMeetingsOfTomorboardForAddPatient from './screens/secretary/tomorboard/tomorboardsList/meetingManager/ListMeetingsOfTomorboardForAddPatient';
import ListMeetingsOfTomorBoard from './screens/secretary/tomorboard/tomorboardsList/listMeetings/ListMeetingsOfTomorBoard'
import { EnumSelectModePageListMeetingForAddPatient } from './screens/secretary/tomorboard/tomorboardsList/meetingManager/interfaceListMeetingsOfTomorboardForAddPatient';
import SettingMeetings from './screens/secretary/tomorboard/tomorboardsList/listMeetings/settingMeetings/SettingMeetings';
import AppendProcessForPatient from './screens/secretary/tomorboard/tomorboardsList/listMeetings/appendProcessForPatient/AppendProcessForPatient';
import AppendCommentForPatient from './screens/secretary/tomorboard/tomorboardsList/listMeetings/appendCommentForPatient/AppendCommentForPatient';
import InviteDoctorToMeeting from './screens/secretary/tomorboard/tomorboardsList/listMeetings/inviteDoctorToMeeting/InviteDoctorToMeeting';
import AddNewMeeting from './screens/secretary/tomorboard/tomorboardsList/listMeetings/addNewMeeting/AddNewMeeting';
import DoctorTomorboardsList from './screens/doctor/tomorboards/listTomorBoard/DoctorTomorboardsList';
import ListMeetingsOfDoctorsTomorBoard from './screens/doctor/tomorboards/meetingsList/ListMeetingsOfDoctorsTomorBoard';
import PatientsOfDoctorsMeeting from './screens/doctor/tomorboards/meetingsList/patientsOfDoctorsMeeting/PatientsOfDoctorsMeeting';
import { useAsyncEffect } from 'use-async-effect';
import { SelectedProfile } from "./store/profileStateSlice";
import { wsBaseUrl } from './api/UrlsList';
import AddPatientsInRequestList from './screens/secretary/tomorboard/tomorboardsList/listMeetings/patientsFileMeeting/addRequest/AddPatientsInRequestList';
const SpaceUnderMenuSide = styledMUI("div")(({ theme }) => ({
  boxSizing: "border-box",
  width: "330px",
  [theme.breakpoints.down('md')]: {
    display: "none",
  },
  [theme.breakpoints.up('md')]: {
    display: "block",
  }
}));
const RemindingSpace = styledMUI("div")(({ theme }) => ({
  background: colors.panel.background,
  display: "flex",
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "center",
  width: "100vw",

  boxSizing: "border-box",
  padding: "1vh 4vw 0 4vw",
  [theme.breakpoints.down('md')]: {
    marginTop: "calc(1vh + 64px)",
  },
  [theme.breakpoints.up('md')]: {
    padding: "32px 2vw 0 2vw",
    width: "calc(100% - 330px)"
  }
}));

function App() {
  const mTheme = useTheme();
  const dispatch = useDispatch();
  const ProfileInStore = useSelector(SelectedProfile);
  const themeInStore = useSelector(selectedTheme);
  const ws = useRef<WebSocket | null>(null);
  const alertStateInStore = useSelector(SelectedAlert);
  const progressbarInstore = useSelector(SelectedProgressbarInRoot);
  const { t, i18n } = useTranslation();

  const mainThem = createTheme({
    ...((themeInStore as any) as ThemeStructure).theme
  });
  const [displayType, SetDisplayType] = useState<enumListSizeDevices>(0);
  const setLangugeFromStore = async () => {
    i18n.changeLanguage(mainThem.direction == "rtl" ? "fa" : "en");
  }
  useAsyncEffect(async () => {
    if (ProfileInStore.token) {
      ws.current = new WebSocket(wsBaseUrl + '/doctors/',);
      ws.current.onerror = (ev) => {
        console.log("wwwwwwwwwwwwebsocket has error:", ev.target, ev)
      }
      ws.current.onmessage = (message) => {
        const data = JSON.parse(message.data); // Assume JSON format
        console.log('wwwwwwwwwwwwebsocket Received message:', data);
        // Handle received message (e.g., update state)
      };
      // Connection event handlers
      ws.current.onopen = () => {
        console.log('wwwwwwwwwwwwebsocket WebSocket connection opened');
        ws.current?.send(`{"token":"${(ProfileInStore).token}"}`);
        // Send initial message if needed
      };
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [ProfileInStore.token]);
  const AddSideMenu = (element: any, TypeOfDisplay: enumListSizeDevices, sections: InterfaceSection[]) => {
    return (
      <div style={{ height: "100%", boxSizing: "border-box", }}>
        <NavbarMainPage

          typeOfDisplay={TypeOfDisplay} sections={sections} />
        <div style={{
          width: "100%", height: "100%", display: "flex", flexDirection: "row",
          boxSizing: 'border-box',
          background: colors.panel.background,
        }}>
          <SpaceUnderMenuSide />
          <RemindingSpace>

            {element}

          </RemindingSpace>
        </div>
      </div >

    );

  }
  useEffect(() => {
    // i18n.changeLanguage(mTheme.direction == "rtl" ? "en" : "fa");
    // dispatch(SetDirectionOfTheme(mTheme.direction == "rtl" ? "ltr" : "rtl"));
    SetDisplayType(HelperResponsive());
    setLangugeFromStore();
  }, []);



  var confComps = ConfigComps();
  return (
    <ThemeProvider theme={mainThem}>
      <div dir={mainThem.direction} style={{ direction: mainThem.direction, height: "100vh" }}>
        <AlertPopup typeOfWarning={alertStateInStore.alerts.typeAlert ? Object.values(enumTypeOfAlert)[Object.values(enumTypeOfAlert).indexOf(alertStateInStore.alerts.typeAlert as unknown as enumTypeOfAlert)] : enumTypeOfAlert.error}
          text={alertStateInStore.alerts.text} lastTimeecondsUpdate={alertStateInStore.alerts.lastTimeecondsUpdate} />
        <ProgressBarInRoots isVisible={progressbarInstore.isVisibility} value={progressbarInstore.value} lastTimeMilisUpdate={progressbarInstore.lastTimeMilisUpdate} />
        <Router>
          <Routes>
            <Route path={links.loginAndRegister.login} element={<LoginPage typeOfDisplay={displayType} />} />
            <Route path={links.loginAndRegister.register} element={<RegisterPage typeOfDisplay={displayType} />} />
            <Route path={links.home} element={<HomePage />} />
            <Route path='/panel' element={<PrivateRedirectToLogin />}>
              <Route path={links.doctor.listPatient} element={AddSideMenu(<PatientsListPage typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.listPatientsRequest} element={AddSideMenu(<PatientsRequestListPage typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.fileOfmyPatient.comeFromListMyPetients + ":idMyPatient/"} element={AddSideMenu(<MyFilePage typeOfDisplay={displayType} role={EnumSupportRoles.doctor} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.fileOfmyPatient.comeFromListPetientsOfMeeting + ":idMyPatient/"} element={AddSideMenu(<MyFilePage typeOfDisplay={displayType} role={EnumSupportRoles.doctor} />, displayType, confComps.panel.doctor.sideBar.sections)} />

              <Route path={links.doctor.accountStep1} element={AddSideMenu(<DoctorAccount typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.changePassword} element={AddSideMenu(<DoctorAccountChangePassword typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.tomorboard.tomorboardsList} element={AddSideMenu(<DoctorTomorboardsList typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.tomorboard.meeting.meetingsList} element={AddSideMenu(<ListMeetingsOfDoctorsTomorBoard typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.tomorboard.meeting.listPatientOfThisMeeting + "/:idMeeting"} element={AddSideMenu(<PatientsOfDoctorsMeeting typeOfDisplay={displayType} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.tomorboard.meeting.appendProcessForPatient} element={AddSideMenu(<AppendProcessForPatient typeOfDisplay={displayType} role={EnumSupportRoles.doctor} />, displayType, confComps.panel.doctor.sideBar.sections)} />
              <Route path={links.doctor.tomorboard.meeting.appendCommentForPatient} element={AddSideMenu(<AppendCommentForPatient typeOfDisplay={displayType} role={EnumSupportRoles.doctor} />, displayType, confComps.panel.doctor.sideBar.sections)} />



              <Route path={links.patient.listDoctor} element={AddSideMenu(<PatientListDoctorPage typeOfDisplay={displayType} />, displayType, confComps.panel.patient.sideBar.sections)} />
              <Route path={links.patient.MyRequest} element={AddSideMenu(<MyRequestPage typeOfDisplay={displayType} />, displayType, confComps.panel.patient.sideBar.sections)} />
              <Route path={links.patient.suggestionOneDoctor} element={AddSideMenu(<SuggestionOneDoctor typeOfDisplay={displayType} />, displayType, confComps.panel.patient.sideBar.sections)} />
              <Route path={links.patient.myFile} element={AddSideMenu(<MyFilePage typeOfDisplay={displayType} role={EnumSupportRoles.patient} />, displayType, confComps.panel.patient.sideBar.sections)} />
              <Route path={links.patient.myHistory} element={AddSideMenu(<MyHistoryPage typeOfDisplay={displayType} role={EnumSupportRoles.patient} />, displayType, confComps.panel.patient.sideBar.sections)} />
              <Route path={links.patient.account} element={AddSideMenu(<PatientAccountPage typeOfDisplay={displayType} />, displayType, confComps.panel.patient.sideBar.sections)} />


              <Route path={links.secretary.account.changePassword} element={AddSideMenu(<SecretaryChangePassword typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.doctor.doctorsList} element={AddSideMenu(<DoctorsList typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.patient.patientList} element={AddSideMenu(<PatientsList typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.patient.create} element={AddSideMenu(<CreatePatient typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.tomorboardsList} element={AddSideMenu(<TomorboardsList typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.configOneTomorboard + "/:idTomorboard/"} element={AddSideMenu(<SettingTomorboard typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.listMeetingOfTomorboard + "/:idTomorboard/"} element={AddSideMenu(<ListMeetingsOfTomorBoard typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.tomorboardsList + "/:idTomorboard" + links.secretary.tomorboard.requestPatientList2th} element={AddSideMenu(<RequestOfPatient typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.appendRequestInTheTomorBoardMeeting} element={AddSideMenu(<AddPatientsInRequestList typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.listMeetingForAddPateint} element={AddSideMenu(<ListMeetingsOfTomorboardForAddPatient typeOfDisplay={displayType} selctedMode={EnumSelectModePageListMeetingForAddPatient.forAddPatient} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.addNewMeeting + "/:idTomorboard/"} element={AddSideMenu(<AddNewMeeting typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.configMeeting} element={AddSideMenu(<SettingMeetings typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.appendProcessForPatient} element={AddSideMenu(<AppendProcessForPatient typeOfDisplay={displayType} role={EnumSupportRoles.secretary} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.appendCommentForPatient} element={AddSideMenu(<AppendCommentForPatient typeOfDisplay={displayType} role={EnumSupportRoles.secretary} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.tomorboard.meeting.inviteDoctor} element={AddSideMenu(<InviteDoctorToMeeting typeOfDisplay={displayType} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.patient.persentsHistory + "/:idMyPatient/"} element={AddSideMenu(<MyHistoryPage typeOfDisplay={displayType} role={EnumSupportRoles.secretary} />, displayType, confComps.panel.secretary.sideBar.sections)} />
              <Route path={links.secretary.patient.persentsFile + "/:idMyPatient/"} element={AddSideMenu(<MyFilePage typeOfDisplay={displayType} role={EnumSupportRoles.secretary} />, displayType, confComps.panel.secretary.sideBar.sections)} />
            </Route>
          </Routes>
        </Router>
      </div>
    </ThemeProvider>

  );
}

export default App;


