import { useTheme } from "@emotion/react";
import { SelectChangeEvent, Grid, FormControl } from "@mui/material";
import { useState, useEffect, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import { LabelInputFromMUI } from "../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../../components/MUIConfig/SelectFromMUI";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";
import EditText, { DivListAllItem, PText } from "../../../../components/editText/EditText";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import colors from "../../../../config/colors";
import { enumListSizeDevices } from "../../../../config/responsive/configResponsive";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { EnumGender } from "../../../../utils/Gender";
import { interfaceServerGetInformation } from "../../../patient/account/interfacePatientAccountPage";
import { interfaceCreatePatient } from "./interfaceCreatePatient";

function CreatePatient(pageParams: interfaceCreatePatient) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const [getAccountFirstName, setAccountFirstName] = useState<string | undefined>("");
    const [getAccountLastName, setAccountLastName] = useState<string | undefined>("");
    const [getAccountFatherName, setAccountFatherName] = useState<string | undefined>("");
    const [getAccountnationalId, setAccountnationalId] = useState<string | undefined>("");
    const [getAccountCity, setAccountCity] = useState<string | undefined>("");
    const [getAccountLandline, setAccountLandline] = useState<string | undefined>("");
    const [getAccountGender, setAccountGender] = useState<EnumGender>();
    const [getAccountAddress, setAccountAddress] = useState<string | undefined>("");
    const [getAccountPhone, setAccountPhone] = useState<string | undefined>("");
    // const [getOldPassword, setOldPassword] = useState<string | undefined>("");
    const [getPassword, setPassword] = useState<string | undefined>("");
    const [getRePassword, setRePassword] = useState<string | undefined>("");
    const profileInStore = useSelector(SelectedProfile);
    const handleUpdateInformationOfAccount = async () => {
        if (getPassword?.toString() != getRePassword?.toString()) {
            dispatch(ShowAlert({ text: "تکرار پسورد اشتباه است!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        const dataObj = {
            "first_name": getAccountFirstName,
            "last_name": getAccountLastName,
            "email": "",
            "national_id": getAccountnationalId,
            "profile_pic": undefined,
            "landline": getAccountLandline,
            "gender": getAccountGender?.toString().includes("f") ? "F" : getAccountGender?.toString().includes("m") ? "M" : "O",
            "father_name": getAccountFatherName,
            "province": "",
            "phone": getAccountPhone,
            "city": getAccountCity,
            "address": getAccountAddress,
            "password": getPassword,
        }
        const urlObj = urlsList.panel.secretary.patient.postCreatePatient
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    const getInformationOfAccountAtFirstTime = async () => {
        console.log("safajoon omad to function", profileInStore)
        dispatch(isVisibilityProgressBar(true));
        await RequestHandler(dispatch, HandlerLangUrl(urlsList.panel.patient.account.getInformation.url, mtheme),
            urlsList.panel.patient.account.getInformation.method, urlsList.panel.patient.account.getInformation.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceServerGetInformation
                setAccountFirstName(t.data.first_name);
                setAccountLastName(t.data.last_name);
                setAccountFatherName(t.data.father_name);
                setAccountnationalId(t.data.national_id);
                setAccountCity(t.data.city);
                setAccountLandline(t.data.landline);

                if (t.data.gender.includes("f")) {
                    setAccountGender(EnumGender.female);
                }
                else if (t.data.gender.includes("m")) {
                    setAccountGender(EnumGender.male);
                }
                else if (t.data.gender.length > 0) {
                    setAccountGender(EnumGender.other);
                }
                else {
                    setAccountGender(undefined)
                }
                setAccountAddress(t.data.address);
                setAccountPhone(t.data.phone)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        // console.log("safajooon")
        // getInformationOfAccountAtFirstTime();
    }, []);
    function handleChangeDropDown(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        setAccountGender(EnumGender[event.target.value as keyof typeof EnumGender])
    }

    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",

        }}>
            <div style={{
                display: "flex", flexWrap: 'wrap', rowGap: "calc(8px + 1vh)", width: "100%", flexDirection: "row", alignItems: "center",
                justifyContent: pageParams.typeOfDisplay <= enumListSizeDevices.mobile ? "center" : "start"
            }}>
                <h5 style={{ color: colors.yellowHeader, fontSize: "2.2rem", fontWeight: "300" }}>
                    {"ایجاد بیمار جدید"}
                </h5>
            </div>
            <div style={{
                width: "100%", boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
                background: colors.panel.doctor.account.step.background,
                borderRadius: "16px",
                maxHeight: "100%",
                overflowY: "auto",
                overflowX: "hidden",
                paddingBlock: "16px",
                paddingInline: "8px",

            }}>
                <GridFromMUI container
                    justifyContent="center" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <div style={{
                            width: "min(70% , 300px)",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            gap: "calc(3vh + 12px)",
                            padding: "calc(3vh + 12px) 8px",
                            alignItems: "center",
                            border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                            borderRadius: "25px",
                            background: colors.panel.doctor.patientsList.selector.background,
                        }}>
                            <p style={{ color: "white", fontSize: "1.5rem", fontWeight: "50", }}>
                                {`${getAccountFirstName} ${getAccountLastName}`}
                            </p>
                            <div style={{ width: "min(60% , 200px)", position: "relative" }}>
                                <div style={{ paddingTop: "100%" }} />
                                <div style={{
                                    top: 0,
                                    boxSizing: "border-box",
                                    border: `1px solid ${colors.panel.doctor.patientsList.selector.color}`,
                                    borderRadius: "24px",
                                    overflow: "hidden",
                                    position: "absolute",
                                    zIndex: "1", width: "100%", height: "100%",
                                }}>
                                    <img src={`${confComps.panel.patient.account.tempImage}`} style={{
                                        width: "100%",
                                    }} />


                                </div>

                            </div>

                        </div>

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.name}
                            key={confComps.panel.patient.account.name}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}

                            sizeWidth="small"
                            onChangeValue={setAccountFirstName}
                            value={getAccountFirstName}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.family}
                            key={confComps.panel.patient.account.family}
                            value={getAccountLastName}
                            onChangeValue={setAccountLastName}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.fatherName}
                            key={confComps.panel.patient.account.fatherName}
                            value={getAccountFatherName}
                            onChangeValue={setAccountFatherName}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.nationalCode}
                            key={confComps.panel.patient.account.nationalCode}
                            value={getAccountnationalId}
                            onChangeValue={setAccountnationalId}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.phone}
                            key={confComps.panel.patient.account.phone}
                            value={getAccountPhone}
                            onChangeValue={setAccountPhone}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <div style={{
                            flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start",
                            justifyContent: "space-between", width: "100%",
                        }}>
                            <DivListAllItem>
                                <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"جنسیت خود را انتخاب کنید"}</PText>
                            </DivListAllItem>


                            <FormControl
                                style={{
                                    margin: "8px 0px 0px 0px",
                                    color: "white",
                                    width: "100%"
                                }}
                                sx={{ m: 1, minWidth: "150px" }} >
                                <LabelInputFromMUI id="demo-simple-select-label">{confComps.panel.patient.account.gender}</LabelInputFromMUI>
                                <SelectFromMUI
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    size="small"
                                    style={{
                                        color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                                        borderRadius: "555px",
                                        boxSizing: "border-box",

                                    }}
                                    value={getAccountGender}
                                    label={confComps.panel.patient.doctorsList.category}
                                    onChange={handleChangeDropDown}
                                >
                                    {confComps.unit.enum.gender.map((value, index, array) => {
                                        return (
                                            <MenuItemFromMUI value={value.key}
                                                key={index}>{value.value}</MenuItemFromMUI>
                                        )
                                    })}
                                </SelectFromMUI>
                            </FormControl>
                        </div>

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.city}
                            key={confComps.panel.patient.account.city}
                            value={getAccountCity}
                            onChangeValue={setAccountCity}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.landline}
                            key={confComps.panel.patient.account.landline}
                            value={getAccountLandline}
                            onChangeValue={setAccountLandline}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <EditText
                            title={confComps.panel.patient.account.address}
                            key={confComps.panel.patient.account.address}
                            value={getAccountAddress}
                            onChangeValue={setAccountAddress}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "14px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={true}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.password.newPassword}
                            key={confComps.panel.patient.account.password.newPassword}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            type="password"
                            sizeWidth="small"
                            onChangeValue={setPassword}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={2} md={4} lg={2} xl={2}>
                        <EditText
                            title={confComps.panel.patient.account.password.confirmPassword}
                            key={confComps.panel.patient.account.password.confirmPassword}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                boxSizing: "border-box",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            type="password"
                            onChangeValue={setRePassword}
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                            <ButtonEffectShadow
                                text={confComps.panel.patient.account.buttonSave}
                                onclick={handleUpdateInformationOfAccount}
                            />
                        </div>
                    </Grid>
                </GridFromMUI>

            </div>

            {/* <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: colors.panel.doctor.account.step.background,
                boxSizing: "border-box", padding: "2vh 2vw", margin: "2vh 0",
                borderRadius: "32px",

            }}>
                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    
                    
                    
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                            <ButtonEffectShadow
                                text={confComps.panel.patient.account.password.buttonSave}
                                onclick={handleChangePasswordOfAccount}
                            />
                        </div>
                    </Grid>
                </GridFromMUI>
            </div> */}

        </div>
    )
}
export default CreatePatient;