import EmptyList from "../../../../components/emptyList/EmptyList";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import BigCardComments from "./cardComments/BigCardComments";
import { interfaceAllComments } from "./interfaceAllComments";

function AllComments(componenetParams: interfaceAllComments) {
    var confComps = ConfigComps();
    return (
        <div style={{
            width: "100%", maxHeight: "80vh",
            overflowY: "auto",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: "calc(3vh + 8px)"
        }}>
            {componenetParams.allCommentFromServer?.data.data.map((comment, index, array) => {
                return (
                    <BigCardComments
                        commentRow={comment} />
                )
            })
            }
            {componenetParams.allCommentFromServer?.data.data.length == 0 ?
                <EmptyList text={"هیچ نظری برای شما ثبت نشده است."} /> : undefined

            }
        </div>
    )
}
export default AllComments;