import { TextField, Theme } from "@mui/material";
import colors from "../../config/colors";
import { useTheme } from "@mui/material/styles";
import { InterfaceEditText } from "./interfaceEditText";
import { styled as styledMUI } from "@mui/material/styles";
import { TextFieldFromMUI } from "../MUIConfig/TextFieldFromMUI";
import { useState } from "react";
import { SearchRounded } from "@mui/icons-material";


const DivEditTextWithTitle = styledMUI("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    width: "100%",
}));

export const PText = styledMUI("p", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            fontSize: "1.3rem",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});
export const H6Text = styledMUI("h6", { shouldForwardProp: (prop) => prop !== 'isColorDark' })<{ isColorDark: boolean; }>(({
    theme,
    isColorDark
}) => {
    return (
        {
            fontSize: "1.3rem",
            whiteSpace: "nowrap",
            alignSelf: "center",
            marginBlockEnd: "2%",
            marginBlockStart: "5%",
            color: isColorDark ? colors.edittext.darkTitleColor : colors.edittext.lightTitleColor,

        }
    )
});

export const DivListAllItem = styledMUI("div")(({ theme }) => ({
    display: "flex",
    boxSizing: "border-box",
    paddingInlineStart: "20px",
    flexDirection: "row",
}));
function EditText(paramsComponent: InterfaceEditText) {
    const mainTheme = useTheme();
    return (
        <DivEditTextWithTitle>
            <DivListAllItem>
                {paramsComponent.isSmallTitle ? <PText isColorDark={paramsComponent.isColorDark ?? true}>{paramsComponent.title}</PText> : <H6Text isColorDark={paramsComponent.isColorDark ?? true}>{paramsComponent.title}</H6Text>

                }
                {paramsComponent.isNecessary ? paramsComponent.isSmallTitle ? <PText isColorDark={paramsComponent.isColorDark ?? true}>*</PText> : <H6Text isColorDark={paramsComponent.isColorDark ?? true}>*</H6Text>
                    : undefined}

            </DivListAllItem>
            <TextFieldFromMUI
                InputProps={paramsComponent.InputProps}
                size={paramsComponent.sizeWidth}
                isHintTextCenter={false}
                id={paramsComponent.setIdForElement ?? `outlined-basic${Math.random()}`}
                isValueMustLtr={paramsComponent.isDataMustLtr ?? false}
                label={paramsComponent.hintText}
                variant="outlined"
                type={paramsComponent.type}
                onChange={(e: any) => paramsComponent.onChangeValue(e.target.value)}
                value={paramsComponent.value}
                multiline={paramsComponent.isMultiLine}
                disabled={paramsComponent.disable}
                style={{ ...paramsComponent.styledTextField }}
                backgroundColor={paramsComponent.backGroundColor}
                isSmallHintShow={paramsComponent.isSmallHintShow} />
        </DivEditTextWithTitle>
    )
}

export default EditText;
