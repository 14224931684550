import { baseUrl } from "../../../../../api/UrlsList";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ImageSquare } from "../../../../../components/imageSquare/ImageSquare";
import colors from "../../../../../config/colors";
import { ConvertNumberToPersian } from "../../../../../helper/ConvertNumberToPersian";
import { interfaceCardImage } from "./interfaceCardImage";
import moment from "moment-jalaali";
function CardImage(componenetParams: interfaceCardImage) {
    return (


        <div style={{
            width: "calc(6rem + 6vw)",
            minWidth: "140px",
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
            borderRadius: "12px",
            color: colors.panel.buttonCardImage.text,
            background: colors.panel.buttonCardImage.background,
            boxSizing: "border-box",
            padding: "8px",
        }} >
            <div style={{
                width: "100%", display: "flex",
                flexDirection: "row",
                gap: "8px",
                flexWrap: "wrap", justifyContent: "space-between", alignItems: "center"
            }}>
                {componenetParams.squareImagesLinks.map((imgVal, index, array) => {
                    if (index < 4) {
                        return (
                            <ImageSquare
                                isScaleable={{ title: componenetParams.title, description: componenetParams.description }}
                                img={baseUrl + "/" + imgVal.file}
                                width={"calc(50% - 4px)"}
                                borderRadius="8px"
                                border="1px solid white"
                            />
                        )
                    }
                })}
                {
                    [1, 2, 3, 4].filter(x => x > componenetParams.squareImagesLinks.length).map((val, index, array) => {
                        return (
                            <ImageSquare
                                img={undefined} width={"calc(50% - 4px)"} />
                        )
                    })
                }
            </div>
            <div style={{ width: "100%", background: "#00000044" }}>
                <p style={{ color: "inherit", textAlign: "center" }}>{componenetParams.title}</p>
                <p style={{ color: "inherit", textAlign: "center" }}>{ConvertNumberToPersian(`${moment.unix(componenetParams.date).format("jYYYY/jM/jD")}`)}</p>
            </div>
        </div>


    )
}
export default CardImage;