const links = {
    logo_hospital: "/img/logo_hospital.png",
    manager: {

    },
    secretary: {
        doctor: {
            doctorsList: "/panel/secretary/doctor/doctors_list",
            create: "/panel/secretary/doctor/create",
            createGroup: "/panel/secretary/doctor/create_group",

        },
        patient: {
            patientList: "/panel/secretary/patient/patients_list",
            create: "/panel/secretary/patient/create",
            persentsFile: "/panel/secretary/patient/patients_list/persents_File",
            persentsHistory: "/panel/secretary/patient/patients_list/persents_History",
        },
        tomorboard: {
            tomorboardsList: "/panel/secretary/manager/tomorboards_list",
            requestPatientList2th: "/listRequests",
            appendRequestInTheTomorBoardMeeting: "/panel/secretary/manager/tomorboards_list/add_request_to_meeting",
            configTomorboard: "/panel/secretary/tomorboard/config_tomorboard",
            configOneTomorboard: "/panel/secretary/manager/tomorboards_list",
            calendar: "/panel/secretary/manager/calendar",
            meeting: {
                listMeetingForAddPateint: "/panel/secretary/manager/tomorboards_list/meetings",
                listMeetingOfTomorboard: "/panel/secretary/manager/tomorboards_list/listmeeting",
                addNewMeeting: "/panel/secretary/manager/tomorboards_list/addNewMeeting",
                SelectLastMeeting: "/panel/secretary/manager/tomorboards_list/Select_last_meeting",
                configMeeting: "/panel/secretary/manager/tomorboards_list/config_meeting",
                inviteDoctor: "/panel/secretary/manager/tomorboards_list/meeting/invite_meeting",
                appendProcessForPatient: "/panel/secretary/manager/tomorboards_list/meeting/append_process_for_patient",
                appendCommentForPatient: "/panel/secretary/manager/tomorboards_list/meeting/append_comment_for_patient",
            }
        },
        account: {
            changePassword: "/panel/secretary/change_password",
            account: "/panel/secretary/account",
        }
    },
    doctor: {
        accountStep1: "/panel/doctor/account",
        listPatient: "/panel/doctor/list_patient",
        fileOfmyPatient: {
            comeFromListMyPetients: "/panel/doctor/list_patient/file_of_my_patient/",
            comeFromListPetientsOfMeeting: "/panel/doctor/meetings/listPatient_of_this_meeting/file_of_my_patient/"
        },
        listPatientsRequest: "/panel/doctor/list__request",
        changePassword: "/panel/doctor/change_password",
        tomorboard: {
            tomorboardsList: "/panel/doctor/tomorboards/",
            meeting: {
                meetingsList: "/panel/doctor/meetings/",
                listPatientOfThisMeeting: "/panel/doctor/meetings/listPatient_of_this_meeting",
                appendProcessForPatient: "/panel/doctor/meetings/listPatient_of_this_meeting/append_process_for_patient",
                appendCommentForPatient: "/panel/doctor/meetings/listPatient_of_this_meeting/append_comment_for_patient",
            }
        }

    },
    patient: {
        MyRequest: "/panel/patient/my_request",
        listDoctor: "/panel/patient/list_doctor",
        myFile: "/panel/patient/my_file",
        suggestionOneDoctor: "/panel/patient/my_file/suggestionOneDoctor",
        myHistory: "/panel/patient/my_history",
        account: "/panel/patient/account",

    },
    loginAndRegister: {
        login: "/panel/login",
        register: "/panel/register",
    },

    home: "/",



}
export default links;