import { enumListSizeDevices } from "../../../../../config/responsive/configResponsive";
import { EnumGender } from "../../../../../utils/Gender";
export enum EnumSelectModePageListMeetingForAddPatient {
    forAddPatient = "FAP",
    forList = "FL_",
}
export interface interfaceListMeetingsOfTomorboardForAddPatient {
    typeOfDisplay: enumListSizeDevices;
    selctedMode: EnumSelectModePageListMeetingForAddPatient;
}
export interface interfaceApiGetListOfMeetingsTheTomorboardForAddPatient {
    success: boolean,
    data: {
        total_count: number,
        data: interfaceApiEntityMeeting[]
    }

}
export interface interfaceApiEntityMeeting {

    id: number;
    title: string;
    description: string;
    start_datetime: number;
    end_datetime: number;
    // status: "NS";
    requests: interfaceApiEntityRequestList[];
    members: interfaceApiEntityMember[];


}
export interface interfaceApiEntityRequestList {
    id: number;
    created_at: number;
    title: string;
    description: string;
    patient: {
        id: number;
        phone: string;
        first_name: string;
        last_name: string;
        profile_pic?: string;
        landline: string;
        email: string;
        father_name: string;
        national_id: string;
        province: string;
        city: string;
        address: string;
        gender: EnumGender;
    },
    doctor: {
        field: string;
        id: number;
        phone: string;
        first_name: string;
        last_name: string;
        email: string;
        national_id: string;
        profile_pic?: string;
        gender: EnumGender;
        landline: string;
        province: string;
        city: string;
        address: string;
    },
    // status: "AP"
}
export interface interfaceApiEntityMember {

    id: number,
    doctor: {
        field:string; 
        id:number;
        phone:string;
        first_name:string;
        last_name:string;
        email:string;
        national_id:string;
        profile_pic?:string; 
        gender:EnumGender;
        landline:string; 
        province:string; 
        city:string; 
        address:string;
    },
    is_guest: boolean;

}