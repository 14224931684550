import { FormControl, Grid, SelectChangeEvent, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../../components/MUIConfig/GridFromMUI";
import { interfaceManageTimeOfTomorboard } from "./interfaceManageTimeOfTomorboard";
import { LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import TimePickMe, { DivListAllItem, PText } from "../../../../../components/timePickMe/TimePickMe";
import dayjs, { Dayjs } from "dayjs";
import React, { ReactNode, useEffect } from "react";
import { EnumWeek } from "../../../../../utils/Week";
import { LabelInputFromMUI } from "../../../../../components/MUIConfig/LabelInputFromMUI";
import { MenuItemFromMUI } from "../../../../../components/MUIConfig/MenuItemFromMUI";
import { SelectFromMUI } from "../../../../../components/MUIConfig/SelectFromMUI";
import colors from "../../../../../config/colors";
import { EnumGender } from "../../../../../utils/Gender";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import ButtonEffectShadow from "../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SelectedProfile } from "../../../../../store/profileStateSlice";

function ManageTimeOfTomorboard(componentsParams: interfaceManageTimeOfTomorboard) {
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const { idTomorboard } = useParams();
    const [getStartTime, setStartTime] = React.useState<Date>();
    const [getEndTime, setEndTime] = React.useState<Date>();
    const [getMeetingWeekday, setMeetingWeekday] = React.useState<EnumWeek>();
    function handleChangeDropDownChooseWeekDay(event: SelectChangeEvent<unknown>, child: ReactNode): void {
        console.log("event.target.value", event.target.value)
        console.log((Object.values(EnumWeek)[event.target.value as number]))
        setMeetingWeekday((Object.values(EnumWeek)[event.target.value as number]))
    }
    const patchConfigOfOneTomorboard = async () => {
        console.log("getStartTime")
        console.log(getStartTime)

        if (((getEndTime ? getEndTime.getTime() : 0) - (getStartTime ? getStartTime.getTime() : 0)) > 0) {

        }
        else {
            dispatch(ShowAlert({ text: "تایم پایان جلسه اشتباه است", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            console.log("getStartTime")
            console.log(getStartTime)
            return
        }
        if (!getMeetingWeekday) {
            dispatch(ShowAlert({ text: "فیلد روز هفته خالی است!", typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.patchDataOfOneTomorboard;
        const obj = {
            "meeting_weekday": getMeetingWeekday,
            "meeting_start_time": `${getStartTime?.getHours()}:${getStartTime?.getMinutes()}`,
            "meeting_end_time": `${getEndTime?.getHours()}:${getEndTime?.getMinutes()}`,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, obj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // let t = response as interfaceGetOneTomorboardFromServer;

                // setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                componentsParams.callbackRefresh();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("update pagesssss", componentsParams.data.meeting_start_time)
        const currentStartDate = new Date();
        currentStartDate.setHours(Number((componentsParams.data.meeting_start_time ?? "0:0").split(":")[0]))
        currentStartDate.setMinutes(Number((componentsParams.data.meeting_start_time ?? "0:0").split(":")[1]))
        setStartTime(currentStartDate);
        const currentEndDate = new Date();
        currentEndDate.setHours(Number((componentsParams.data.meeting_end_time ?? "0:0").split(":")[0]))
        currentEndDate.setMinutes(Number((componentsParams.data.meeting_end_time ?? "0:0").split(":")[1]))
        setEndTime(currentEndDate);
        if (componentsParams.data.meeting_weekday) {
            setMeetingWeekday((Object.values(EnumWeek)[componentsParams.data.meeting_weekday - 1]));
        }
    }, [])
    return (
        <div style={{
            width: "100%",
            display: componentsParams.typeOfDisplay ? "flex" : "none",
            boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
            maxHeight: "100%",
            overflowY: "auto",
            overflowX: "hidden",
        }}>

            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>


                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePickMe
                                title="زمان شروع"
                                onChange={(value) => {
                                    console.log("val")
                                    console.log(value)
                                    setStartTime(value)
                                }}
                                // value={"11"}
                                defaultValue={getStartTime?.getTime()}
                                isNecessary={false}
                                isSmallTitle={true}
                                backGroundColor=""
                                styleMain={{
                                    border: "1px solid #fff",
                                    borderRadius: "555px",
                                    width: "100%",
                                    color: colors.panel.doctor.account.step.edittext.color,
                                    background: colors.panel.doctor.account.step.edittext.background,

                                }}
                                isColorDark={false}
                                isSmallHintShow={false}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePickMe
                                title="زمان پایان"
                                onChange={setEndTime}
                                defaultValue={getEndTime?.getTime()}
                                isNecessary={false}
                                isSmallTitle={true}
                                backGroundColor=""
                                styleMain={{
                                    border: "1px solid #fff",
                                    borderRadius: "555px",
                                    width: "100%",
                                    color: colors.panel.doctor.account.step.edittext.color,
                                    background: colors.panel.doctor.account.step.edittext.background,

                                }}
                                isColorDark={false}
                                isSmallHintShow={false}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <div style={{
                        flexGrow: "1", display: "flex", flexDirection: "column", alignItems: "start",
                        justifyContent: "space-between", width: "100%",
                    }}>
                        <DivListAllItem>
                            <PText isColorDark={false} style={{ marginBlockEnd: "0px" }}>{"روز جلسه"}</PText>
                        </DivListAllItem>


                        <FormControl
                            style={{
                                margin: "8px 0px 0px 0px",
                                color: "white",
                                width: "100%"
                            }}
                            sx={{ m: 1, minWidth: "150px" }} >
                            <LabelInputFromMUI id="demo-simple-select-label">{"انتخاب روز"}</LabelInputFromMUI>
                            <SelectFromMUI
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                style={{
                                    color: "inherit", background: colors.panel.doctor.patientsList.selector.background,
                                    borderRadius: "555px",

                                }}
                                value={Number(getMeetingWeekday) - 1}
                                label={"انتخاب روز"}
                                onChange={handleChangeDropDownChooseWeekDay}
                            >
                                {confComps.unit.enum.week.map((value, index, array) => {
                                    return (
                                        <MenuItemFromMUI value={index}
                                            key={index}>{value.value}
                                        </MenuItemFromMUI>
                                    )
                                })}
                            </SelectFromMUI>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", display: "flex", flexDirection: "row",
                        alignItems: "center", justifyContent: "end"
                    }}>
                        <ButtonEffectShadow text={"به روزرسانی"}
                            onclick={patchConfigOfOneTomorboard} />

                    </div>
                </Grid>
            </GridFromMUI>

        </div>
    )

}
export default ManageTimeOfTomorboard;

