import { useTheme } from "@emotion/react";
import { AddRounded } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../api/UrlsList";
import { GridFromMUI } from "../../../../../../components/MUIConfig/GridFromMUI";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ImageSquare } from "../../../../../../components/imageSquare/ImageSquare";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import colors from "../../../../../../config/colors";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { interfaceGetOneTomorboardFromServer } from "../../../settingTomorboard/interfaceSettingTomorboard";
import { interfaceApiGetListOfMeetingsTheTomorboardForAddPatient } from "../../meetingManager/interfaceListMeetingsOfTomorboardForAddPatient";
import RowListMeetingsOfTomorBoard from "../RowListMeetingsOfTomorBoard";
import { interfaceAddNewMeeting } from "./interfaceAddNewMeeting";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ButtonEffectShadow from "../../../../../../components/buttonEffectShadow/ButtonEffectShadow";
import TimePickMe from "../../../../../../components/timePickMe/TimePickMe";
import EditText from "../../../../../../components/editText/EditText";
import { setHours } from "date-fns-jalali";
import moment from "moment";
import DateTimePickMe from "../../../../../../components/dateTimePick/DateTimePickMe";


function AddNewMeeting(pagesParams: interfaceAddNewMeeting) {
    const { idTomorboard } = useParams();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getRequestOfListMeeting, setRequestOfListMeeting] = useState<interfaceApiGetListOfMeetingsTheTomorboardForAddPatient>();
    const [getTomor, setTomorboard] = useState<interfaceGetOneTomorboardFromServer>();
    const [getStartTimeSecoundDefult, setStartTimeSecoundDefult] = useState<number>();
    const [getEndTimeSecoundDefult, setEndTimeSecoundDefult] = useState<number>();
    const [getTitleOfMeeting, setTitleOfMeeting] = useState<string>();
    const [getDescriptionOfMeeting, setDescriptionOfMeeting] = useState<string>();
    const getApiListMeeting = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.getlistOfMeeting;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/` + "meetings/?page=1&page_size=1000", mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceApiGetListOfMeetingsTheTomorboardForAddPatient;
                setRequestOfListMeeting(t)

                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiThisTomorBoard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.getDataOfOneTomorboard;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetOneTomorboardFromServer;

                setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const postApiCreateMeeting = async () => {

        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.meeting.postCreateMeeting;
        const sendObj = {
            "title": getTitleOfMeeting,
            "description": getDescriptionOfMeeting,
            "start_datetime": getStartTimeSecoundDefult,
            "end_datetime": getEndTimeSecoundDefult,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/meetings/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, sendObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);

                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                navigate(-1)


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const updateDefultTimeMeeting = () => {
        var lastMeeting = getRequestOfListMeeting?.data.data.sort((a, b) => {
            return (-(a.start_datetime - b.start_datetime))
        })[0]
        if (lastMeeting && getTomor) {
            // lastMeeting.start_datetime += ;
            var startNewMeeting = new Date();
            if (lastMeeting.start_datetime < Date.now()) {
                var difWeek = Math.floor((Date.now() - (lastMeeting.start_datetime * 1000)) / (7 * 24 * 60 * 60 * 1000));
                console.log("jooonam", (Date.now() - (lastMeeting.start_datetime * 1000)), difWeek, (lastMeeting.start_datetime * 1000) + difWeek * 7 * 24 * 60 * 60 * 1000)
                startNewMeeting = new Date((lastMeeting.start_datetime * 1000) + (difWeek + 1) * (7 * 24 * 60 * 60 * 1000));
            }
            else {
                startNewMeeting = new Date((lastMeeting.start_datetime * 1000) + (7 * 24 * 60 * 60 * 1000));
            }


            startNewMeeting.setHours(Number((getTomor.data.meeting_start_time ?? "0:0").split(":")[0]))
            startNewMeeting.setMinutes(Number((getTomor.data.meeting_start_time ?? "0:0").split(":")[1]))
            setStartTimeSecoundDefult(startNewMeeting.getTime() / 1000);
            setEndTimeSecoundDefult((startNewMeeting.getTime() / 1000) + ((Number((getTomor.data.meeting_end_time ?? "0:0").split(":")[0]) * 3600) + (Number((getTomor.data.meeting_end_time ?? "0:0").split(":")[1]) * 60)) - (Number((getTomor.data.meeting_start_time ?? "0:0").split(":")[0]) * 3600 + Number((getTomor.data.meeting_start_time ?? "0:0").split(":")[1]) * 60))
            console.log("safafafaaaaaaaaaaaa", ((Number((getTomor.data.meeting_end_time ?? "0:0").split(":")[0]) * 3600) + (Number((getTomor.data.meeting_end_time ?? "0:0").split(":")[1]) * 60)) - (Number((getTomor.data.meeting_start_time ?? "0:0").split(":")[0]) * 3600 + Number((getTomor.data.meeting_start_time ?? "0:0").split(":")[1]) * 60))
        }
    }
    useEffect(() => {
        getApiThisTomorBoard().then(() => {
            getApiListMeeting();
        });

        // getApiDataOfOneTomorboard();
    }, []);
    useEffect(() => {
        if (getRequestOfListMeeting && getTomor)
            updateDefultTimeMeeting();
    }, [getRequestOfListMeeting, getTomor])
    return (
        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            boxSizing: "border-box",
            paddingInline: "8px",
            paddingBlock: "8px",
            background: colors.blue.darkbackground,
            borderRadius: "12px",
            border: `1px solid ${colors.blue.dark}`,

        }}>
            <div style={{ width: "100%" }}>

                <GridFromMUI container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 1, sm: 3, md: 3, lg: 3, xl: 4 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <EditText
                            title={"عنوان"}
                            onChangeValue={setTitleOfMeeting}
                            value={getTitleOfMeeting}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>
                    <Grid item xs={0} sm={2} md={2} lg={2} xl={2}></Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <DateTimePickMe
                            onChange={(newDate: any) => {
                                // console.log(newDate);
                                // if (newDate) {
                                //     const posixTimestamp = newDate.getTime(); // Get milliseconds since Unix epoch
                                //     setStartTime(posixTimestamp / 1000);
                                // } else {
                                //     setStartTime(undefined);
                                // }
                            }}
                            // value={moment.unix(componentsParams.serverData.start_datetime).format("jYYYY/jM/jD HH:mm")}
                            valueSecound={getStartTimeSecoundDefult ? (moment.unix(getStartTimeSecoundDefult).unix() * 1000) : undefined}
                            // defaultValueSecound={1704295225}
                            title={"زمان شروع جلسه"}
                            isNecessary={false}
                            backGroundColor=""
                            isSmallTitle={true}
                            styleMain={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            isColorDark={false}
                            isSmallHintShow={false}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                        <DateTimePickMe
                            onChange={(newDate: any) => {
                                // console.log(newDate);
                                // if (newDate) {
                                //     const posixTimestamp = newDate.getTime(); // Get milliseconds since Unix epoch
                                //     setStartTime(posixTimestamp / 1000);
                                // } else {
                                //     setStartTime(undefined);
                                // }
                            }}

                            valueSecound={getEndTimeSecoundDefult ? (moment.unix(getEndTimeSecoundDefult).unix() * 1000) : undefined}
                            // defaultValueSecound={1704295225}
                            title={"زمان پایان جلسه"}
                            isNecessary={false}
                            backGroundColor=""
                            isSmallTitle={true}
                            styleMain={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            isColorDark={false}
                            isSmallHintShow={false}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <EditText
                            title={"توضیحات"}
                            onChangeValue={setDescriptionOfMeeting}
                            value={getDescriptionOfMeeting}
                            styledTextField={{
                                border: "1px solid #fff",
                                borderRadius: "555px",
                                width: "100%",
                                color: colors.panel.doctor.account.step.edittext.color,
                                background: colors.panel.doctor.account.step.edittext.background,

                            }}
                            sizeWidth="small"
                            isNecessary={false}
                            isMultiLine={false}
                            isSmallTitle={true}
                            isColorDark={false}
                            isSmallHintShow={false} />

                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{
                            width: "100%", display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "end"
                        }}>
                            <ButtonEffectShadow text={"به روزرسانی"}
                                onclick={postApiCreateMeeting} />

                        </div>
                    </Grid>

                </GridFromMUI>
            </div>

        </div>
    )
}
export default AddNewMeeting;
