import { Grid, useTheme } from "@mui/material";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import EditText from "../../../../components/editText/EditText";
import colors from "../../../../config/colors";
import { interfaceInformationOfTomorboard } from "./interfaceInformationOfTomorboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceGetOneTomorboardFromServer } from "./interfaceSettingTomorboard";
import { ReactNode, useEffect, useState } from "react";
import ButtonEffectShadow from "../../../../components/buttonEffectShadow/ButtonEffectShadow";

function InformationOfTomorboard(componentsParams: interfaceInformationOfTomorboard) {
    const mtheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const { idTomorboard } = useParams();
    const [getName, setName] = useState<string | undefined>("");
    const [getField, setField] = useState<string>("");
    const [getDescription, setDescription] = useState<string>("");
    const [getStatus, setStatus] = useState<string>("AC");
    const [getHospital, setHospital] = useState<string>("");
    const [getMeetingPlace, setMeetingPlace] = useState<string>("");

    const patchConfigOfOneTomorboard = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.secretary.tomorboard.patchDataOfOneTomorboard;
        const obj = {
            "name": getName,
            "field": getField,
            "description": getDescription,
            "hospital": getHospital,
            "meeting_place": getMeetingPlace
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idTomorboard}/`, mtheme),
            urlObj.method, urlObj.isTokenNecessary, obj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // let t = response as interfaceGetOneTomorboardFromServer;

                // setTomorboard(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
                componentsParams.callbackRefresh();


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setName(componentsParams.data.name);
        setField(componentsParams.data.field);
        setDescription(componentsParams.data.description);
        setStatus(componentsParams.data.status);
        setHospital(componentsParams.data.hospital);
        setMeetingPlace(componentsParams.data.meeting_place);
    }, [componentsParams]);
    return (
        <div style={{
            width: "100%",
            display: componentsParams.typeOfDisplay ? "flex" : "none",
            boxSizing: "border-box", marginTop: "calc(1vh + 12px)",
            maxHeight: "100%",
            overflowY: "auto",
            overflowX: "hidden",
        }}>

            <GridFromMUI container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>


                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={"عنوان"}
                        onChangeValue={setName}
                        value={getName}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={"حوزه"}
                        onChangeValue={setField}
                        value={getField}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.secretary.tomorboard.oneTomorboard.information.hospital}
                        onChangeValue={setHospital}
                        value={getHospital}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} xl={2}>
                    <EditText
                        title={confComps.panel.secretary.tomorboard.oneTomorboard.information.meeting_place}
                        onChangeValue={setMeetingPlace}
                        value={getMeetingPlace}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <EditText
                        title={confComps.panel.secretary.tomorboard.oneTomorboard.information.description}
                        onChangeValue={setDescription}
                        value={getDescription}
                        styledTextField={{
                            border: "1px solid #fff",
                            borderRadius: "555px",
                            width: "100%",
                            boxSizing:"border-box",
                            color: colors.panel.doctor.account.step.edittext.color,
                            background: colors.panel.doctor.account.step.edittext.background,

                        }}
                        sizeWidth="small"
                        isNecessary={false}
                        isMultiLine={false}
                        isSmallTitle={true}
                        isColorDark={false}
                        isSmallHintShow={false} />

                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end" }}>
                        <ButtonEffectShadow text={"ذخیره"}
                            onclick={patchConfigOfOneTomorboard} />
                    </div>
                </Grid>
            </GridFromMUI>
        </div>
    )
}
export default InformationOfTomorboard;