import { Button, useTheme } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import links from "../../../config/links";
import { enumCategoryFilter, interfaceGetAllCommentFromServer, interfaceGetAllMediaFromServer, interfaceGetAllTreatmentProcessesFromServer, interfaceMyFilePage } from "./interfaceMyFilePage";
import { styled as styledMUI } from "@mui/material/styles";
import ButtonEffectShadow from "../../../components/buttonEffectShadow/ButtonEffectShadow";
import { ConfigComps } from "../../../config/ConfigCOMP";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import colors from "../../../config/colors";
import CardImage from "./allCategoryOfMyFile/cardImage/CardImage";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { AddRounded, ArrowBackRounded } from "@mui/icons-material";
import ButtonArrow from "../../../components/buttonArrow/ButtonArrow";
import { enumArrow } from "../../../components/buttonArrow/interfaceButtonArrow";
import CardSuggestion from "./allCategoryOfMyFile/cardSuggestion/CardSuggestion";
import AllCategoryOfMyFile from "./allCategoryOfMyFile/AllCategoryOfMyFile";
import AllImages from "./allImages/AllImages";
import { ImageSquare } from "../../../components/imageSquare/ImageSquare";
import { DialogAddFileInMyFile } from "./dialogAddFileInMyFile/DialogAddFileInMyFile";
import { useDispatch, useSelector } from "react-redux";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { interfaceServerGetInformationDocotor } from "../../doctor/account/interfaceDoctorAccount";
import { EnumSupportRoles } from "../../../utils/Role";
import AllComments from "./allComments/AllComments";
import AllTreatmentProcesses from "./allTreatmentProcesses/AllTreatmentProcesses";
import AllConversation from "./conversation/AllConversation";
import { DialogAddConversationInMyFile } from "./dialogAddConversationInMyFile/DialogAddConversationInMyFile";

function MyFilePage(pageParams: interfaceMyFilePage) {
    var confComps = ConfigComps();
    const mtheme = useTheme();
    const navigate = useNavigate();
    const { idMyPatient } = useParams();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getCategoryFilter, setCategoryFilter] = useState<enumCategoryFilter>(enumCategoryFilter.all);
    const [getShowDialogAddFile, setShowDialogAddFile] = useState<boolean>(false);
    const [getShowDialogAddConversationForPatient, setShowDialogAddConversationForPatient] = useState<boolean>(false);
    const [getLastPosixTimeForUpdateConversation, setLastPosixTimeForUpdateConversation] = useState<number>(Date.now());
    const [getListOfMedicalRecordMedia, setListOfMedicalRecordMedia] = useState<interfaceGetAllMediaFromServer>();
    const [getListAllComment, setListAllComment] = useState<interfaceGetAllCommentFromServer>();
    const [getListAllTreatmentProcesses, setListAllTreatmentProcesses] = useState<interfaceGetAllTreatmentProcessesFromServer>();
    const getApiListOfAllFileOfMedicalRecord = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patient.myFile.getMedicalRecord;
        let url = urlObj.url
        if (pageParams.role == EnumSupportRoles.doctor) {
            urlObj = urlsList.panel.doctor.patient.getMedicalRecordOfMyPatient
            url = urlObj.url + idMyPatient + "/medical-records/?page=1&page_size=1000"
            console.log("you are doctor:)")
        }
        else if (pageParams.role == EnumSupportRoles.secretary) {
            urlObj = urlsList.panel.secretary.patient.getPatientsMedicalRecord
            url = urlObj.url + idMyPatient + "/medical-records/?page=1&page_size=1000"
            console.log("you are secratery:)")
        }
        else {
            console.log("you are patient:)")
        }
        await RequestHandler(dispatch, HandlerLangUrl(url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetAllMediaFromServer;
                setListOfMedicalRecordMedia(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiListAllCommentOfPatient = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patient.myFile.getCommentRecord;
        let url = urlObj.url
        if (pageParams.role == EnumSupportRoles.doctor) {
            urlObj = urlsList.panel.doctor.patient.getCommentRecordOfMyPatient
            url = urlObj.url + idMyPatient + "/comments/?page=1&page_size=1000"
            console.log("you are doctor:)")
        }
        else if (pageParams.role == EnumSupportRoles.secretary) {
            urlObj = urlsList.panel.secretary.patient.getPatientsCommentRecord
            url = urlObj.url + idMyPatient + "/comments/?page=1&page_size=1000"
            console.log("you are secratery:)")
        }
        else {
            console.log("you are patient:)")
        }
        await RequestHandler(dispatch, HandlerLangUrl(url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetAllCommentFromServer;
                setListAllComment(t)
                dispatch(ShowAlert({ text: "اطلاعات دریافت شد", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiListAllTreatmentProcess = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.patient.myFile.getTreatmentProcessesRecord;
        let url = urlObj.url
        if (pageParams.role == EnumSupportRoles.doctor) {
            urlObj = urlsList.panel.doctor.patient.getTreatmentProcessesRecordOfMyPatient
            url = urlObj.url + idMyPatient + "/treatment-processes/?page=1&page_size=1000"
            console.log("you are doctor:)")
        }
        else if (pageParams.role == EnumSupportRoles.secretary) {
            urlObj = urlsList.panel.secretary.patient.getPatientsTreatmentProcesses
            url = urlObj.url + idMyPatient + "/treatment-processes/?page=1&page_size=1000"
            console.log("you are secratery:)")
        }
        else {
            console.log("you are patient:)")
        }
        await RequestHandler(dispatch, HandlerLangUrl(url, mtheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as interfaceGetAllTreatmentProcessesFromServer;
                setListAllTreatmentProcesses(t)
                dispatch(ShowAlert({ text: confComps.panel.loginAndRegister.login.notification.success.userAndPassTrue, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        console.log("safajooon")
        getApiListOfAllFileOfMedicalRecord();
        getApiListAllCommentOfPatient();
        getApiListAllTreatmentProcess();
    }, []);




    return (

        <div style={{
            width: "-webkit-fill-available",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            alignItems: "start", justifyContent: "start",
            overflowY: "auto",
            overflowX: "hidden",

        }}>
            <div style={{
                display: "flex",
                width: "100%", flexDirection: "row",
                marginBottom: "calc(3vh + 12px)", gap: "calc(1vw + 8px)",
                justifyContent: "start", alignItems: "center", flexWrap: "wrap"
            }}
            >
                {confComps.panel.patient.myFilePage.category.list.map((cat, index, array) => {
                    if (Object.values(enumCategoryFilter)[index] == enumCategoryFilter.conversation
                        && pageParams.role == EnumSupportRoles.patient) {
                        return (undefined)
                    }
                    return (

                        <ButtonEffectShadow text={cat} size="small"
                            colors={
                                (Object.keys(enumCategoryFilter).indexOf(getCategoryFilter) == index) ?
                                    colors.panel.buttonYellowBackground :
                                    colors.panel.buttonDarkBackground
                            }
                            onclick={() => {
                                setCategoryFilter(Object.values(enumCategoryFilter)[index])
                                // setCategoryFilter(Object.keys(enumCategoryFilter)[0])
                            }}
                        />
                    )
                })}


                {(getCategoryFilter == enumCategoryFilter.all || getCategoryFilter == enumCategoryFilter.picture || getCategoryFilter == enumCategoryFilter.conversation) ?
                    < ImageSquare width={"calc(1rem + 22px)"}

                        callbackOnclick={() => {
                            if (getCategoryFilter == enumCategoryFilter.all || getCategoryFilter == enumCategoryFilter.picture
                            ) {
                                setShowDialogAddFile(true)
                            }
                            else if (getCategoryFilter == enumCategoryFilter.conversation) {
                                setShowDialogAddConversationForPatient(true)
                            }
                        }}
                        iconSvg={AddRounded} background={colors.yellowHeader} borderRadius="100px" />
                    : undefined

                }
            </div>
            {
                getCategoryFilter == enumCategoryFilter.all ?
                    <AllCategoryOfMyFile
                        typeOfDisplay={pageParams.typeOfDisplay}
                        allImageFromServer={getListOfMedicalRecordMedia}
                        allCommentFromServer={getListAllComment}
                        allTreatmentProcessesFromServer={getListAllTreatmentProcesses}
                    /> :
                    getCategoryFilter == enumCategoryFilter.comment ?
                        <AllComments typeOfDisplay={pageParams.typeOfDisplay}
                            allCommentFromServer={getListAllComment}
                        /> :
                        getCategoryFilter == enumCategoryFilter.treatmentProcess ?
                            <AllTreatmentProcesses
                                typeOfDisplay={pageParams.typeOfDisplay}
                                allTreatmentProcessesFromServer={getListAllTreatmentProcesses?.data.data ?? []}
                            /> :
                            getCategoryFilter == enumCategoryFilter.conversation ?
                                <AllConversation
                                    typeOfDisplay={pageParams.typeOfDisplay}
                                    idPatient={`${idMyPatient}`}
                                    lastPosixTimeForUpdate={getLastPosixTimeForUpdateConversation}
                                />
                                :
                                <AllImages typeOfDisplay={pageParams.typeOfDisplay}
                                    allImageFromServer={getListOfMedicalRecordMedia}
                                />

            }

            <DialogAddFileInMyFile
                idMyPatient={pageParams.role == EnumSupportRoles.patient ? undefined : idMyPatient}
                isOpen={getShowDialogAddFile}
                typeOfDisplay={pageParams.typeOfDisplay}
                callbackOnCloseDialog={() => {
                    setShowDialogAddFile(false);
                    getApiListOfAllFileOfMedicalRecord();
                }}
                role={pageParams.role}
            />
            <DialogAddConversationInMyFile
                idMyPatient={idMyPatient}
                isOpen={getShowDialogAddConversationForPatient}
                typeOfDisplay={pageParams.typeOfDisplay}
                callbackOnCloseDialog={() => {
                    setShowDialogAddConversationForPatient(false);
                    setLastPosixTimeForUpdateConversation(Date.now());
                }}
                role={pageParams.role}
            />

        </div>
    )
}
export default MyFilePage;